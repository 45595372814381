import React from 'react';
import './ModalBackdrop.scss'

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
}

const ModalBackdrop: React.FC<Props> = ({ children, onClick }: Props) => {

  return (
    <div
      data-testid="interests-modal"
      id="interests-modal"
      className="modal-open"
      onClick={onClick}
    >
      <div
        className="modal-background +z-layer-five +display-flex +align-items-center +justify-content-center"
      >
        { children }
      </div>
    </div>
  )
};

export default ModalBackdrop;
