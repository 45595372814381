import React from 'react';
import { NavLink } from 'react-router-dom';
import { NotificationPage, IllustrationConfig } from "../../Types";
import FocusTrap from 'focus-trap-react';
import ModalBackDrop from "../../../Common/ModalBackdrop";
import './CompletionPage.scss';

type Props = {
  className?: string;
  completionPage: NotificationPage;
  buttonLink: string;
  handleClick: () => void;
  illustrationConfig?: IllustrationConfig;
}

const CompletionPage: React.FC<Props> = ({ className, buttonLink, completionPage, handleClick, illustrationConfig }: Props) => {
  
  const styles = {
    backgroundColor: `${completionPage.image?.filename ? '#FFFFFF' : illustrationConfig?.illustration_config.background_color}`,
    borderRadius: `${completionPage.image?.filename ? '0' : '50%'}`
  };

  const filename = completionPage.image?.filename ? completionPage.image.filename : illustrationConfig?.images.tray.filename;

  const image = <img style={styles} className="completion-page__image +mg-bottom-md" src={filename} alt="" />;

  return (
    <ModalBackDrop>
      <FocusTrap>
        <div className={`completion-page +pd-all-lg ${className || ""}`} role="dialog" aria-modal="true" aria-describedby="journey-completion-page-heading">
          <div className="completion-page__content">
            {image}
            <h1 className="completion-page__heading +mg-bottom-md" id="journey-completion-page-heading">{completionPage.heading} </h1>
            <p className="completion-page__description">
              {completionPage.description}
            </p>
            <NavLink onClick={handleClick} className="button --primary completion-page__button" to={buttonLink}>{completionPage.button_text || "Continue"}</NavLink>
          </div>
        </div>
      </FocusTrap>
    </ModalBackDrop>
  )
};

export default CompletionPage;