import * as yup from 'yup';
import { ComponentType, Journey } from './Types';
import { logger } from '../../RollbarErrorBoundary';

export const imageValidator = yup.object().shape({
  alt: yup.string().notRequired().nullable(),
  copyright: yup.string().notRequired().nullable(),
  filename: yup.string().required(),
  name: yup.string().notRequired(),
});

export const notificationValidator = yup.object().shape({
  button_text: yup.string().notRequired(),
  heading: yup.string().required(),
  description: yup.string().required(),
  type: yup.string().required()
});

export const subtasksValidator = yup.array().of(yup.object().shape({
  storyblok_id:  yup.string().uuid().required(),
  title: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string(),
  componentType: yup.string().oneOf([ComponentType.subtask]).required(),
  isSkippable: yup.boolean().required(),
  skipCtaText: yup.string(),
  continueCtaText: yup.string(),
}));

export const tasksValidator = yup.array().of(yup.object().shape({
  storyblok_id:  yup.string().uuid().required(),
  title: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string().required(),
  componentType: yup.string().oneOf([ComponentType.task]).required(),
  subtasks: subtasksValidator.min(1).required(),
}));

export const stepsValidator = yup.array().of(yup.object().shape({
  storyblok_id:  yup.string().uuid().required(),
  title: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string().required(),
  componentType: yup.string().oneOf([ComponentType.step]).required(),
  tasks: tasksValidator.min(1).required(),
  completionPage: notificationValidator.required(),
}));

export const journeyValidator = yup.object().shape({
  storyblok_id:  yup.string().uuid().required(),
  title: yup.string().required(),
  slug: yup.string().required(),
  componentType: yup.string().oneOf([ComponentType.journey]).required(),
  steps: stepsValidator.min(1).required(),
  introTray: notificationValidator.required(),
  completionPage: notificationValidator.required(),
});

export async function isValidJourney(journey: Journey): Promise<Journey | null> {
  try {
    await journeyValidator.validate(journey);
  } catch(e) {
    logger.warn(e as Error);
    return null;
  }

  return journey;
} 
