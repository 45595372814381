import React from 'react';
import AngleBracket from '../../../assets/AngleBracket';
import CloseSvg from '../../CloseSvg';
import ProgressBar from '../ProgressBar';
import { CommonBaseProps } from '../Types';
import './ProgressControls.scss';

type Props = {
  percentage?: number;
  title: string;
  onBack: () => void;
  onClose: () => void; 
} & CommonBaseProps

const ProgressControls: React.FC<Props> = (props : Props) => {
  const { percentage = 0, title, className = '', dataTestId = 'progress-controls' } = props;
  const normalizedPercentage = percentage > 1 ? 100 : percentage * 100;
  return (
    <div className={`progress-controls +mg-bottom-md ${className}`} data-testid={dataTestId}>
      <section className="progress-controls__heading +display-flex +justify-content-space-between +align-items-center">
        <AngleBracket direction='left' fill="black" onClick={props.onBack} className="+display-flex +mg-left-xs progress-controls__button"
          width={8.41} height={14}
        />
          <span className="progress-controls__title +type-body-one">{ title }</span>
        <CloseSvg onClick={props.onClose} className='progress-controls__button +mg-right-xs' width="18.25" height="18.25"/>
      </section>
      <ProgressBar percentage={normalizedPercentage} className="+mg-top" />
    </div>
  );
};

export default ProgressControls;
