
import React from 'react';
import SubtaskComponent from '../../Components/Subtask';
import { IntegrationProps } from '../Types';
import MyMoveWidget from '../../../Widgets/MyMoveWidget';

const MyMoveQuoteWidget: React.FC<IntegrationProps> = (props: IntegrationProps) => {
  const { onComplete, title, description } = props;

  return (
    <SubtaskComponent
      title={title}
      description={description}
      isSkippable={false}
      onComplete={onComplete}
    >
      <MyMoveWidget />
    </SubtaskComponent>
  )
}

export default MyMoveQuoteWidget;
