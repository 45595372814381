import React from 'react';
import './InputTile.scss';
import { InputFieldResponse, SelectFieldResponse } from '../Types';
import Select from './Select';
import InputField from '../InputField';
import Button from '../../../Common/Button';

export enum InputFormat {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  CURRENCY = "CURRENCY",
  ZIP = "ZIP",
  DECIMAL_PERCENTAGE = "DECIMAL_PERCENTAGE",
  DECIMAL = "DECIMAL"
}

interface InputOptions {
  [key: string]: {
    format: Record<string, unknown>
  }
}

const InputOptions: InputOptions = {
  [InputFormat.CURRENCY]: {
    format: {
      numeral: true,
      prefix: "$ ",
      rawValueTrimPrefix: true,
    }
  },
  [InputFormat.NUMBER]: {
    format: {
      numericOnly: true
    }
  },
  [InputFormat.TEXT]: {
    format: {
      delimiter: ""
    }
  },
  [InputFormat.ZIP]: {
    format: {
      blocks: [5],
      numericOnly: true
    }
  },
  [InputFormat.DECIMAL]: {
    format: {
      numeral: true,
      numeralDecimalMark: '.',
      numeralDecimalScale: 4
    }
  },
  [InputFormat.DECIMAL_PERCENTAGE]: {
    format: {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalMark: ".", 
      noImmediatePrefix: true,   
      rawValueTrimPrefix: true,
      prefix: '%',
      tailPrefix: true,
      stripLeadingZeroes: true
    }
  }
}

type Props = {
  headline: string;
  subcopy: string;
  inputContainerClassname?: string;
  inputs: (InputFieldResponse | SelectFieldResponse)[];
  changeHandler: (fieldName: string, value: string) => void | null;
  skippable?: boolean;
  skipText?: string;
  onSkip?: () => void;
};

/**
 * Input Tile componenet
 */
const InputTile: React.FC<Props> = (props: Props) => {
  const {
    headline,
    subcopy,
    inputs,
    inputContainerClassname = '',
    changeHandler,
    skippable,
    skipText = "I don't know",
    onSkip
  } = props;
   
  const createInputs = (inputs: (InputFieldResponse | SelectFieldResponse)[]) => {
    return inputs.map((input, index) => {
      if (input.component == "input-field") {
        const { field_name, label = '', type = InputFormat.TEXT, placeholder, inputValue, errorCopy } = input as InputFieldResponse;
        return (
          <InputField
            key={`input-${index}`}
            inputLabel={label}
            inputName={field_name}
            inputId={field_name}
            inputFormat={type}
            placeholder={placeholder}
            errorCopy={errorCopy}
            inputValue={inputValue}
            changeHandler={ changeHandler }
          />
        );
      }

      if (input.component == "dropdown-input") {
        const { options, label = '', field_name, inputValue } = input as unknown as SelectFieldResponse;
        return (
          <Select
            key={`select-${index}`}
            inputName={field_name}
            options={options}
            inputLabel={label}
            changeHandler={ changeHandler }
            inputValue={inputValue}
          />
        );
      }
    });
  }

  return (
    <div className={`input-tile +mg-bottom-ml ${inputContainerClassname}`}>
      <div className="input-tile__container">
        <h4 className="+type-heading-four +mg-bottom">{headline}</h4>
        <p className="+type-body-one +fg-slate">{subcopy}</p>
        {createInputs(inputs)}
        { skippable && <Button className="+fg-slate +type-body-one input-tile__skip +mg-top-xm" onClick={onSkip}>{skipText}</Button> }
      </div>
    </div>
  );
}

export default InputTile;