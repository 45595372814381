import React, {useState} from 'react';
import EmailVerification from './EmailVerification';
import { ProfileFieldInterface } from '../Profile/ProfileInterface';

interface Props {
  personalProfile: ProfileFieldInterface[];
}

const EmailVerificationPage: React.FC <Props> = (props: Props) => {

  const [state] = useState(props.personalProfile);

  const email = state[0].value;

  return (
    <div
      data-testid="email-verification-page"
      style={{ borderBottom: '4px solid #0157ff', borderRadius: '4px', boxShadow: '0 0 1px 0 rgba(21, 21, 21, 0.1),  0 2px 8px -1px rgba(21, 21, 21, 0.2)' }}
      className="page-wrapper size-10of12 +mg-top-lg +mg-bottom-xl +bg-white"
    >
      <EmailVerification email={email}/>
    </div>
  );
}

export default EmailVerificationPage;
