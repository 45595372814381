import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import SubtaskComponent from '../Subtask';
import { IllustrationConfig, JourneySlugs, Subtask, SubtaskRequiredUpdate, UserDataRequest } from '../../Types';
import TrayContainer from '../../../Common/TrayContainer';
import ProgressControls from '../../../Common/ProgressControls';
import { completeSubtaskStatus, createSubtaskStatus, markSubtasksRequired } from '../../../../store/journey/actions';
import { buildJourneyUrl, getActionStatusesByStoryblokIds, getIntegrationComponent, isActionStatusAutoCompleted, isActionStatusSkipped } from '../../helpers';
import './Task.scss';
import { ProgressSummary } from '../../ActionItems/Types';
import { KeyValue } from '../../../Common/Types';
import { generateSubtaskSummaries, updateSubtaskSummaryDecisionTree } from './helpers';

interface Props {
  title: string;
  subtasks: Subtask[];
  subtaskSlug: string;
  taskStoryblokId: string;
  backgroundImage: IllustrationConfig | undefined;
  isMobile: boolean;
  onActionStatusComplete: (slugs: JourneySlugs) => void;
}

const Task: React.FC<Props> = (props: Props) => {
  const { title, subtasks, taskStoryblokId, subtaskSlug, backgroundImage, isMobile, onActionStatusComplete } = props;

  // User Data for PUT Requests
  const actionStatuses = useSelector(getActionStatusesByStoryblokIds([taskStoryblokId, ...subtasks.map(subtask => subtask.storyblok_id)])) || [];
  const actionStatusDictionary: KeyValue = actionStatuses.reduce((dictionary, nextStatus) => {
    return {...dictionary, [nextStatus.storyblok_id]: nextStatus}
  }, {});
  // Routing
  const slugs = useParams<JourneySlugs>();
  const routerHistory = useHistory();

  // Task and Subtask Tracking
  const defaultCurrentSubtask = subtaskSlug ? subtasks.findIndex((subtask => subtask.slug === subtaskSlug)) : 0
  const [currentSubtaskIndex, setCurrentSubtaskIndex] = useState(defaultCurrentSubtask);
  const [subtaskProgressSummaries, updateSubtaskProgressSummaries] = useState({} as KeyValue);
  const [percentageComplete, setPercentageComplete ] = useState(0);
  // Keeps track of HAC page
  const [currentSubtaskPage, setCurrentSubtaskPage] = useState(0);
  const [subtaskHistory, setSubtaskHistory] = useState([] as number[]);

  const subtaskSummaries = generateSubtaskSummaries(subtasks);
  const currentSubtaskSummary = subtaskSummaries[currentSubtaskIndex];
  const currentSubtask = currentSubtaskSummary?.subtask;
  const subtaskActionStatus = actionStatuses.find((actionStatus) => actionStatus.storyblok_id === currentSubtask?.storyblok_id);

  useEffect(() => {
    calculatePercentComplete(); 
  }, [subtaskSummaries, actionStatuses]);

  const actionStatusHash = useRef('');
  
  useEffect(() => {
    const hash = actionStatuses.reduce((stringHash, next) => stringHash + `${next.storyblok_id}:${next.completed_at}:`, '');
    const isHashChanged = actionStatusHash.current !== hash;

    if (!actionStatusDictionary || !isHashChanged) return;
    actionStatusHash.current = hash;  

    const decisionTreeHead = subtaskSummaries.find(item => item.isTreeHead);
    if (!decisionTreeHead) return;
    const summaries = subtaskSummaries.map(summary => {
      const updatedSummary = {...summary};
      if (subtaskHistory.length && summary.slug === currentSubtaskSummary.slug) {
        const previousSubtask = subtaskSummaries[subtaskHistory[subtaskHistory.length - 1]];

        updatedSummary.prevSlug = currentSubtaskSummary.slug = previousSubtask.slug;
        updatedSummary.nodeId = currentSubtaskSummary.nodeId = `${previousSubtask.nodeId}.${updatedSummary.slug}`;
      }
      return updatedSummary;
    });
    // Performs a update patch on the subtasks when they are updated
    updateSubtaskSummaryDecisionTree(summaries, actionStatusDictionary, decisionTreeHead, currentSubtaskSummary);
  }, [ actionStatuses ]);

  //  Gets component weights and adjusts subtask count accordingly
  const calculatePercentComplete = () => {   
    const taskActionStatus = actionStatusDictionary[taskStoryblokId];
    //  If task has been completed, just render 100%
    if (isActionStatusAutoCompleted(taskActionStatus)) {
      setPercentageComplete(1);
      return;
    }

    // Reduces subtaskSummaries to weight count for completion calculation
    const subtaskSummaryList = subtaskSummaries.filter(item => item.required);

    const summary = subtaskSummaryList.reduce((summary, subtaskSummary) => {
      const { subtask } = subtaskSummary; 
      const subtaskWeight = getIntegrationComponent(subtask.actionItems)?.subtaskWeight || 1;
      const actionStatus = actionStatusDictionary[subtask.storyblok_id];
      const progressSummary = subtaskProgressSummaries[subtask.storyblok_id] || { current: 0 };
      const completed = actionStatus?.completed_at ? subtaskWeight : progressSummary.current;

      return {
        total: summary.total + subtaskWeight,
        completed: summary.completed + completed
      };
    }, { completed: 0, total: 0});

    const completionPercentage = summary.completed / (summary.total);
    setPercentageComplete(completionPercentage);
  };

  const navigateToTaskList = () => {
    routerHistory.push(buildJourneyUrl({
      journeySlug: slugs.journeySlug,
      stepSlug: slugs.stepSlug
    }));
  };
  const getSubtaskIndexBySlug = (slug: string) => (subtasks.findIndex((t) => t.slug === slug));

  const navigateToSubtaskSlug = (nextSubtaskSlugs: JourneySlugs) => {
    if (!nextSubtaskSlugs.subtaskSlug) return;
    
  
    const { subtaskSlug } = nextSubtaskSlugs;
    const slugIndex = getSubtaskIndexBySlug(subtaskSlug);

    setCurrentSubtaskIndex(slugIndex);
    routerHistory.push(buildJourneyUrl(nextSubtaskSlugs));
  };

  const navigateToJourneyHub = () => {
    routerHistory.push(buildJourneyUrl({ journeySlug: slugs.journeySlug }));
  };

  const navigateToStepHub = () => {
    routerHistory.push(buildJourneyUrl({ journeySlug: slugs.journeySlug, stepSlug: slugs.stepSlug }));
  };

  const navigateInternalSubtask = (subtaskIndex: number) => {
    setCurrentSubtaskIndex(subtaskIndex);
    const nextSubtask = subtaskSummaries[subtaskIndex];
    routerHistory.push(buildJourneyUrl({ ...slugs, subtaskSlug: nextSubtask.slug }));
  };

  const isLastSubtask = () => currentSubtaskIndex === subtasks.length - 1;

  // Handles Post Completion Hook
  const onSubtaskStatusComplete = (nextSubtaskSlugs?: JourneySlugs) => {
    setSubtaskHistory([...subtaskHistory, currentSubtaskIndex]);
    if (!subtaskActionStatus?.completed_at) {
      onActionStatusComplete(slugs);
    }

    if (nextSubtaskSlugs) {
      navigateToSubtaskSlug(nextSubtaskSlugs);
      return;
    }
    
    if (currentSubtaskSummary.nextSlug && !isLastSubtask()) {
      navigateToSubtaskSlug({
        ...slugs,
        subtaskSlug: currentSubtaskSummary.nextSlug
      });
      return;
    }

    navigateToTaskList();
  };

  // Click Handlers
  const onContinueClick = async (startDate: string, fields?: UserDataRequest[]) => {
    if (!slugs.stepSlug || !slugs.taskSlug || !slugs.subtaskSlug) {
      navigateToJourneyHub();
      return;
    }


    //  Complete the subtask with the fields provided
    completeSubtaskStatus(slugs, startDate, fields);
    onSubtaskStatusComplete();
  }

  const onDecisionClick = async (startDate: string, nextSubtaskSlugs?: JourneySlugs) => {
    //  Checks if the current task needs to clear the tree immediately
    if (currentSubtaskSummary.isTreeHead && !nextSubtaskSlugs) {
      const updates = subtaskSummaries
        .filter(subtaskSummary => subtaskSummary.nodeId.includes(currentSubtaskSummary.nodeId) && subtaskSummary.isLeaf)
        .map(subtaskSummary => ({
          storyblok_id: subtaskSummary.storyblok_id,
          isRequired: false,
          slug: subtaskSummary.slug
        } as SubtaskRequiredUpdate));

        markSubtasksRequired(updates);
    }
    completeSubtaskStatus(slugs, startDate);
    onSubtaskStatusComplete(nextSubtaskSlugs);
  };

  const onSkipClick = () => {
    if (!slugs.stepSlug || !slugs.taskSlug || !slugs.subtaskSlug) {
      navigateToJourneyHub();
      return;
    }

    createSubtaskStatus(slugs.stepSlug, slugs.taskSlug, subtaskSlug, true);
    onSubtaskStatusComplete();
  };

  //  Handling the onBackArrow in a subtask
  const onBackClick = () => {
    const currentSubtaskPosition = subtasks.findIndex((item) => item.slug === currentSubtaskSummary.prevSlug);
  
    if (currentSubtaskPage > 0) {
      setCurrentSubtaskPage(currentSubtaskPage - 1);
      return;
    }

    if (subtaskHistory.length) {
      const subtaskIndex = subtaskHistory.pop() || 0;
 
      setSubtaskHistory(subtaskHistory);
      navigateInternalSubtask(subtaskIndex);
      return;
    }

    if (currentSubtaskPosition >= 0) {
      navigateInternalSubtask(currentSubtaskPosition);
      return;
    }
    navigateToTaskList();
  };

  // OnProgress handler when subtask informs of progressChange
  const onProgress = (progressSummary?: ProgressSummary): void => {
    if (!progressSummary) return;

    updateSubtaskProgressSummaries({
      ...subtaskProgressSummaries, 
      [progressSummary.subtaskName]: progressSummary
    });
  }

  if (!currentSubtask) {
    return <h1>Loading</h1>;
  }
  // Subtask Component Rendering
  const IntegrationComponentDefinition = getIntegrationComponent(currentSubtask.actionItems);
  
  const skipProps = {
    skipHeadingText: currentSubtask.skipHeadingText,
    skipBodyText: currentSubtask.skipBodyText,
    skipButtonText: currentSubtask.skipButtonText,
    skipCtaText: currentSubtask.skipCtaText
  };

  const isSubtaskComplete = subtaskActionStatus?.completed_at ? !isActionStatusSkipped(subtaskActionStatus) : false;
  const isSubtaskSkippable = currentSubtask.isSkippable && !isSubtaskComplete;

  const subtask = IntegrationComponentDefinition ?
    <IntegrationComponentDefinition.component
      title={currentSubtask.title}
      description={currentSubtask.description}
      onComplete={onContinueClick}
      subtaskUuid={currentSubtask.storyblok_id}
      taskUuid={taskStoryblokId}
      skipProps={skipProps}
      onSkip={onSkipClick}
      onProgress={onProgress}
      isSubtaskComplete={isSubtaskComplete}
      tile={currentSubtask.actionItems[0].tile?.length ? {...currentSubtask.actionItems[0].tile[0]} : undefined}
      currentSubtaskPage={currentSubtaskPage}
      setCurrentSubtaskPage={setCurrentSubtaskPage}
    /> :
    <SubtaskComponent
      title={currentSubtask.title}
      description={currentSubtask.description}
      isSkippable={isSubtaskSkippable}
      actionItems={currentSubtask.actionItems}
      subtask={currentSubtask}
      onComplete={onContinueClick}
      onCompleteDecision={onDecisionClick}
      onSkip={onSkipClick}
      skipProps={skipProps}
      key='current-subtask-component'
    />;
  
  const styles =
  {
    backgroundImage: isMobile ? `url(${backgroundImage?.images.task.filename}` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: '-104px',
    backgroundColor: '#EAF2FD'
  };

  return (
    <TrayContainer
      containerClassName="+height-full"
      containerStyles={styles}
      className="journey-task__container +pd-vertical-md +pd-horizontal +mg-top-md +mg-top-none--sm">
      <div className="journey-task +mg-bottom-lg--sm">
        <ProgressControls percentage={percentageComplete} title={title} onBack={onBackClick} onClose={navigateToStepHub} key='progress-controls' />
        {subtask}
      </div>
    </TrayContainer>
  )
}

export default Task;
