import React from "react";
import { Redirect } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import PrivateRoute from "../../utils/PrivateRoute";
import AuxAppJourneyContainer from "../../AuxAppJourneyContainer";
import { hasAllowedAuthRoles, AuthRoles } from "../../utils/authorization";

export default function JourneyRoutes(): JSX.Element {
  const { enableJourneysFeature } = useFlags();

  const preampFlag: boolean = window.showHomebuyingJourney;
  const journeyFlag = (enableJourneysFeature && preampFlag) || hasAllowedAuthRoles([AuthRoles.RoleInternal])
  if (!journeyFlag) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <PrivateRoute path="/learning/journeys/:journeySlug">
        <AuxAppJourneyContainer />
      </PrivateRoute>
      {/* <Redirect to="/dashboard" /> */}
    </>
  );
}
