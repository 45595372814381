import React from "react";
import { NavLink } from "react-router-dom";
import { elementClicked, ElementClickedInterface } from '../../services/tracking';
import './Sidebar.css';
import { GlossaryIcon } from '../../assets/tile_svgs';

type Props = {
  className?: string;
}

const Sidebar: React.FC<Props> = (props: Props) => {
  const {className = ""} = props;

  const trackTabClicked = (e: React.SyntheticEvent) => {

    const tabName = e.currentTarget.getAttribute('tab-name') || '';

    const trackingObj: ElementClickedInterface = {
      location: 'App_Navigation',
      position: 'SIDE',
      elementType: 'Tab',
      text: `${tabName}`,
      htmlId: `${tabName}_Tab`,
      actionOutcome: `${tabName?.toLowerCase()}_page`,
      outboundUrl: `https://www.bankrate.com/app/${tabName?.toLowerCase()}`,
    }

    elementClicked(trackingObj);
  }

  return (
    <div className="app-nav-wrapper">
      <nav className={`app-nav +bg-white ${className}`}>
        <ul className="list-bare +mg-bottom-none">
          <li tab-name="Dashboard" className="list-inline__item" onClick={trackTabClicked}>
            <NavLink
              className="sidebar-link"
              activeClassName="sidebar-link__active"
              data-testid="dashboard-link"
              to="/dashboard"
            >
              <span className="sidebar-link-content +width-full +display-flex +flex-direction-row ">
                <svg
                  className="+flex-no-shrink"
                  height="27px"
                  width="27px"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <title>myBankrate</title>
                  <path d="M6 6H0V0h6zM2 4h2V2H2zM15 6H9V0h6zm-4-2h2V2h-2zM24 6h-6V0h6zm-4-2h2V2h-2zM6 15H0V9h6zm-4-2h2v-2H2zM15 15H9V9h6zm-4-2h2v-2h-2zM24 15h-6V9h6zm-4-2h2v-2h-2zM6 24H0v-6h6zm-4-2h2v-2H2zM15 24H9v-6h6zm-4-2h2v-2h-2zM24 24h-6v-6h6zm-4-2h2v-2h-2z" />
                </svg>
                <span className="sidebar-label">myBankrate</span>
              </span>
            </NavLink>
          </li>
          <li tab-name="Learning" className="list-inline__item" onClick={trackTabClicked}>
            <NavLink
              className="sidebar-link"
              activeClassName="sidebar-link__active"
              data-testid="learning-link"
              to="/learning"
            >
              <span className="sidebar-link-content +width-full +display-flex +flex-direction-row">
                <GlossaryIcon fill="#000" width="27px" height="23px" className="+display-flex +align-items-center" />
                <span className="sidebar-label">myLearning</span>
              </span>
            </NavLink>
          </li>
          <li tab-name="Profile" className="list-inline__item" onClick={trackTabClicked}>
            <NavLink
              className="sidebar-link"
              activeClassName="sidebar-link__active"
              data-testid="profile-link"
              to="/profile"
            >
              <span className="sidebar-link-content +width-full +display-flex +flex-direction-row">
                <svg
                  className="+flex-no-shrink"
                  width="27px" height="27px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <title>Profile</title>
                  <path d="M24,22a12,12,0,0,0-8.19-11.37,6,6,0,1,0-7.62,0A12,12,0,0,0,0,22v2H15V22H2a10,10,0,0,1,20,0H19v2h5ZM12,10a4,4,0,1,1,4-4A4,4,0,0,1,12,10Z" />
                </svg>
                <span className="sidebar-label">Profile</span>
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
