import React, { useState, useEffect } from 'react';
import ExternalResourceContainer from "../Common/ExternalResourceContainer";
import WidgetError from './WidgetError';
import './Widgets.scss';

type Props = {
  className?: string;
};

const HomeInsuranceWidget: React.FC<Props> = ({ className = '' }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (isPolling) {
      return;
    }

    let tick = 0;
    const pollForIframe = setInterval(() => {
      tick++
      setIsPolling(true);
      // grabs first child of insurance-widget
      const el = document.querySelector('.home-value-prop-widget');
      // if widget div has content, show it. otherwise show the error tile
      if (el) {
        setIsLoaded(true);
        setIsPolling(false);
        setHasError(false);
        clearInterval(pollForIframe);
      }
      // if 5 seconds have passed
      if (tick === 10) {
        setHasError(true);
      }

    }, 500);
    return () => {
      clearInterval(pollForIframe);
    }
  }, []);

  return (
    <ExternalResourceContainer id='insurance-widget' externalSource={new URL("https://widgets.insurance-development.redventures.io/home-value-prop-widget.js")} isAsync={true} elementType='script' type='text/javascript'>
      {hasError && !isLoaded ? <WidgetError /> : <></>}
      {/* wrapping div is required due to target element being replaced once the widget loads */}
      <div className={className}>
        <div className={`insurance-widget`}>
          {!isLoaded && !hasError ? <div className='+width-full shimmer-box +mg-bottom-md'></div> : <></>}
        </div>
      </div>
    </ExternalResourceContainer>
  )
}
export default HomeInsuranceWidget;