import React from 'react';
import "./CreditRateTable.scss";

export type CreditRateTableProps = {
  readonly apr: string;
  readonly credit_range: string;
  readonly monthly_payment: string;
  readonly total_interest_paid: string;
  readonly blurb: string;
}

/**
 * Credit Rate Table Action Item Component
 */
 const CreditRateTable: React.FC<CreditRateTableProps> = (props: CreditRateTableProps) => {

  const { apr, monthly_payment, total_interest_paid, credit_range, blurb } = props;
      const rowData = [
        { text: "APR (Average)", value: apr },
        { text: "Monthly payment", value: monthly_payment },
        { text: "Total interest paid", value: total_interest_paid },
      ];

      const rows = rowData.map((row, rowIndex) => {
        return (
          <tr key={`crt-row-${rowIndex}`}>
            <td className="+type-body-one">{row.text}</td>
            <td className="+type-body-one">{row.value}</td>
          </tr>
        )
      });

      return (
        <>
          <table className="credit-table +fg-slate" key={`crt`}>
            <thead>
              <tr>
              <th><span className="form-label">FICO Score</span></th>
              <th><span className="form-label">{credit_range}</span></th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          <p className="+type-body-one +fg-slate">{blurb}</p>
        </>
      );
 }

 export default CreditRateTable;