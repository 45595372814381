import React from 'react';
import { CommonBaseProps } from '../components/Common/Types';

type Props = {
  height?: string;
  width?: string;
  fill?: string;
  onClick?: () => void;
} &CommonBaseProps;

const CloseIcon: React.FC<Props> = (props: Props) => {

  return (
    <div className={props.className} onClick={props.onClick && props.onClick}>
      <svg height={props.height || '20px'} width={props.width || '20px'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <title>Close</title>
        <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" fill={props.fill ? props.fill : 'black'}/>
        </svg>
    </div>
  );
};

export default CloseIcon;

