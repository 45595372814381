import React from 'react';
import EmailVerificationPage from './components/EmailVerification';
import store, { RootState} from './store'
import { Provider, connect } from 'react-redux';

const ConnectedEmailVerification = connect(
  (state: RootState) => ({
    personalProfile: state.profile.personal,
  })
)(EmailVerificationPage);

const AuxAppEmailVerification: React.FC = () => {

  return (
    <Provider store={store}>
      <ConnectedEmailVerification />
    </Provider>
  );
}

export default AuxAppEmailVerification;
