import React, { useEffect, useState } from 'react';
import ContentTile from '../../Journeys/Components/ContentTile';
import formatCurrency from '../../../utils/formatCurrency';
import './HomeAffordabilitySlider.scss';
import { CommonBaseProps } from '../../Common/Types';
import LabeledValue from '../../Common/LabeledValue';
import { calculateHomeAffordability } from './helpers';
import TrayModal from '../../Journeys/Components/TrayModal';
import Button from '../../Common/Button';
import InfoCircle from '../../../assets/tile_svgs/InfoCircle';

type HomeAffordabilitySliderProps = {
  annualIncome : number;
  monthlyDebt : number;
  downPayment : number;
  annualPropertyTax? : number;
  annualInsurance?: number,
  interestRate? : number;
  lengthOfLoanYears? : number;
  onChangeHandler?: (dtiValue: number, monthlyPayment: number, totalLoanAmount: number) => void
} & CommonBaseProps;

const HomeAffordabilitySlider: React.FC<HomeAffordabilitySliderProps> = (props: HomeAffordabilitySliderProps) => {
  const {  className = '', annualIncome, monthlyDebt, annualInsurance = 0, annualPropertyTax = 0, interestRate = 0, onChangeHandler} = props;
  const [ currentValue, setCurrentValue ] = useState(0);
  const [ showInfoModal, setShowInfoModal ] = useState(false);
  // eslint-disable-next-line
  const onChange = (event: any) => {
    // 1 - 100%
    setCurrentValue(event.target.value);
  }

  const [ minSlider, setMinSlider ] = useState(20);
  const [ maxSlider ] = useState(43);

  useEffect(() => {
    const currentDti = ((monthlyDebt * 12) + (annualInsurance + annualPropertyTax)) / annualIncome;
    const currentDtiPercentage = Math.round(currentDti * 100);
    if (currentDti * 100 <= minSlider) {
      setMinSlider(currentDtiPercentage + 1);
    } else if (currentDtiPercentage > minSlider) {
      setCurrentValue(currentDtiPercentage);
    }
  }, [ monthlyDebt, annualIncome ])
  //  Get the slider in percentage value
  const sliderPercentage = currentValue / 100;
 
  //  Get the calculator Debt to Income Ratio conversion from slider
  const targetDti = Math.round(((sliderPercentage * (maxSlider - minSlider) + minSlider))) / 100;
  const summary = calculateHomeAffordability({ ...props, targetDti: targetDti });

  useEffect(() => {
    if (!onChangeHandler) return;
    onChangeHandler(targetDti, summary.monthlyPayment, summary.total);
  }, [summary.total])

  const onShowInfoModal = (shouldShow: boolean) => () => {
    setShowInfoModal(shouldShow);
  };
  //  Handle the percentages
  const color = sliderPercentage <= .42 ? 'green' : sliderPercentage <= .78 ? 'blue' : 'red';

  //  Format currency
  const formattedTotal = formatCurrency(Math.round(summary.total));
  const formattedMonthly = formatCurrency(parseFloat(summary.monthlyPayment.toFixed(2)));

  //   Handles rendering the Tray Table
  const infoModal = (<TrayModal onOverlayClick={() => setShowInfoModal(false)}>
    <div className="+text-center hac-slider__info-tray +fg-slate +type-body-one">
      <h4 className="+type-heading-four +mg-bottom">How was this calculated?</h4>
      <p className="+mg-bottom">These estimates assume the following:</p>
      <ul className="+mg-bottom +text-left">
        <li>A <strong>30 year</strong> fixed-loan term</li>
        <li><strong>${Math.round(annualInsurance / 12)}/month</strong> in home insurance</li>
        <li><strong>${Math.round(annualPropertyTax / 12)}/month</strong> in property taxes</li>
        <li><strong>{(interestRate * 100).toPrecision(2)}%</strong> in annual interest</li>
      </ul>
      <p>Your true payment will vary based on the specific details of your loan and property.</p>
      <Button className="--primary --small" onClick={onShowInfoModal(false)}>Got it</Button>
    </div>
  </TrayModal>);

  //  Set the label color by the slider percentage
  return (
    <div className={`hac-slider +display-flex +justify-content-center ${className}`}>
      <ContentTile className="+text-center +pd-all +pd-bottom-ml">
        <div className="hac-slider__container">
          <div className="hac-slider__title">Estimated home price you can afford</div>
          <div className={`hac-slider__total +text-center +mg-bottom-sm`}>{formattedTotal}</div>
      
          <div className="hac-slider__range">
            <div className="hac-slider__overlay" style={{width: `${100 - (sliderPercentage * 100)}%`}} />
            <div className="hac-slider__break-wrapper">
              <div className="hac-slider__break-1"/>
              <div className="hac-slider__break-2"/>
              <div className="hac-slider__break-3"/>
            </div>
          </div>
          <input type="range" min="1" max="100" className={`hac-slider__input range--${color}`} 
            onChange={onChange} id="hac-slider-scale" value={currentValue}/>
          <div className="hac-slider__labels">
            <span className="hac-slider__break-1-label +text-left">Affordable</span>
            <span className="hac-slider__break-2-label">Stretching</span>
            <span className="hac-slider__break-3-label +text-right">Aggressive</span>
          </div>
          <div className="+display-flex +justify-content-space-around +mg-top-md +mg-bottom-ml">
            <LabeledValue value={formattedMonthly} label="Monthly payment"/>
            <LabeledValue value={`${(targetDti * 100).toFixed(0)}%`} label="Debt-to-income"/>
          </div>
          <hr/>
          <div className="+text-center +type-body-two">
            <a onClick={onShowInfoModal(true)} className="hac-slider__info">How was this calculated?  <InfoCircle  className={'+mg-left-sm'}/></a>
          </div>
        </div>
      </ContentTile>
      {  showInfoModal ? infoModal : <></>  }
    </div>
  )
};

export default HomeAffordabilitySlider
