import { KeyValue } from "../../../../Common/Types";
import { InputFormat } from "../../InputTile";

export const inputConfigurations: KeyValue = {
    annual_income: {
      headline: 'What is your annual income?',
      subcopy: 'Combine the gross (pre-tax) incomes of each person whose name will be on the loan.',
      inputLabel: 'Annual income',
      inputName: 'annual_income',
      inputId: 'home-affordability-annual-income',
      inputFormat: InputFormat.CURRENCY,
      component: "input-field"
    },
    monthly_debt: {
      headline: 'What is your monthly debt?',
      subcopy: 'Add up all your monthly payments, including credit card payments, alimony and child support.',
      inputLabel: 'Monthly debt',
      inputName: 'monthly_debt',
      inputId: 'home-affordability-monthly-debt',
      inputFormat: InputFormat.CURRENCY,
      component: "input-field"
    },
    down_payment: {
      headline: 'What is your down payment amount?',
      subcopy: 'How much cash do you plan to put down on your new home? Your best estimate will be fine.',
      inputLabel: 'Down payment amount',
      inputName: 'down_payment',
      inputId: 'home-affordability-down-payment',
      inputFormat: InputFormat.CURRENCY,
      component: "input-field"
    },
    annual_property_tax: {
      headline: 'What is the estimated annual property tax?',
      subcopy: 'If you\'re not sure, you can find this information on the real estate listing or on the home county\'s website.',
      inputLabel: 'Annual property tax',
      inputName: 'annual_property_tax',
      inputId: 'home-affordabilty-annual-property-tax',
      inputFormat: InputFormat.CURRENCY,
      component: "input-field",
      inputCategory: 'GENERAL',
    },
    annual_insurance_cost: {
      headline: 'What is the estimated insurance cost?',
      subcopy: 'The national average home insurance cost is $1,393 per year for $250,000 in dwelling coverage.',
      inputLabel: 'Annual Insurance Cost',
      inputName: 'annual_insurance_cost',
      inputId: 'home-affordabilty-estimated-insurance-cost',
      inputFormat: InputFormat.CURRENCY,
      component: "input-field"
    },
    estimated_interest: {
      headline: 'What is your interest rate?',
      subcopy: 'Your rate will depend on the type of mortgage you get (i.e. 30-year-fixed). The rate you provide doesn\'t need to be exact, since it will likely change anyway.',
      inputLabel: 'Interest Rate',
      inputName: 'estimated_interest',
      inputId: 'home-affordabilty-estimated-interest-rate',
      inputFormat: InputFormat.DECIMAL,
      placeholder: '%',
      component: "input-field"
    },
  }