import React, { useState, useEffect } from 'react';
import TrayContainer from '../../../Common/TrayContainer';
import './Onboarding.scss';
import SwiperCarousel from "../../../Common/SwiperCarousel";
import { PaginationOptions } from "swiper/types/components/pagination";
import SwiperCore from "swiper";
import useWindowDimensions from '../../../../utils/useWindowDimensions';
import { Onboarding } from '../../Types';
import { elementClicked, elementViewed } from '../../../../services/tracking';
import { formatTrackingText } from '../../helpers';

type Props = {
  readonly onClick: () => void;
  readonly screens: Onboarding[];
  readonly journeyName: string;
}

const JourneyOnboarding: React.FC<Props> = (props: Props) => {
  const { onClick, screens, journeyName } = props;
  const [page, setPage] = useState<number>(0);
  const isLast = () => page === pages.length - 1;
  const isMobile = useWindowDimensions().isMobile;
  const swiperOptions = {};

  useEffect(() => {
    if (screens.length > 0) {
      return elementViewed({
        elementType: "Screen",
        htmlId: `${formatTrackingText(journeyName)}_Onboarding_Screen1`,
        location: `${formatTrackingText(journeyName)}_Onboarding`,
        position: formatTrackingText(journeyName),
        text: formatTrackingText(screens[0].description),
      });
    }
  }, []);

  const navigation = {
    nextEl: ".journey-button-nav",
    disabled: null,
  }

  const pagination: PaginationOptions = {
    el: '.journey-onboarding__pagination',
    bulletClass: 'swiper-pagination-bullet swiper-pagination-bullet--grey',
    bulletActiveClass: 'swiper-pagination-bullet-active--blue',
    type: "bullets",
    // @TODO set to false
    clickable: !isMobile
  }

  const onScreenCTAClicked = (journeyName: string, buttonCopy: string, screenNumber: number) => {
    return elementClicked({
      actionOutcome: 'Next_Onboarding_Screen',
      outboundUrl: '',
      elementType: 'Button',
      location: `${formatTrackingText(journeyName)}_Onboarding_Screen${screenNumber}`,
      position: formatTrackingText(journeyName),
      text: formatTrackingText(buttonCopy),
      htmlId: `${formatTrackingText(journeyName)}_Onboarding_Screen${screenNumber}_${formatTrackingText(buttonCopy)}`,
    });
  }

  const onNextPage = (swiper: SwiperCore) => {
    // @TODO add tracking
    setPage(swiper.activeIndex);
  }

  const onFinalPage = () => {
    onClick();
  }
  const currentPage = screens[page];
  const pages = screens.map((d, i) => {
    return (
      <div key={i} className="+display-flex +flex-direction-column +align-items-center +justify-content-center +width-full">
        <div className="journey-onboarding__carousel-image">
          <object type="image/svg+xml" data={d.image.filename} className="+mg-bottom-md--sm +mg-bottom-none--lg +width-full"/>
        </div>
        <p className="+mg-bottom +mg-top +mg-top-lg--lg +fg-slate +style-bold +text-center journey-onboarding__description">{d.description}</p>
      </div>
    )
  });

  const screenCTA =
    !isLast() ?
      <button
        disabled={false}
        className="button --primary +mg-top-md +mg-top-none--sm +mg-bottom-lg journey-button-nav"
        onMouseDown={() => {
          onScreenCTAClicked(journeyName, currentPage.buttonCopy, page + 1);
        }}
        onClick={(e) => {
          e.currentTarget.blur();
        }}
      >
        {currentPage.buttonCopy}
      </button>
      :
      <button
        className="button --primary +mg-top-md +mg-top-none--sm +mg-bottom-lg"
        onMouseDown={() => {
          onScreenCTAClicked(journeyName, currentPage.buttonCopy, page + 1);
          onFinalPage();
        }}
      >
        {currentPage.buttonCopy}
      </button>;

  return (
    <TrayContainer className="journey-onboarding +bg-white +height-screen">
      <div className="+display-flex +flex-direction-column +align-items-center +width-full +align-self-center">
        <div className="journey-onboarding__carousel +width-full">
          <SwiperCarousel pagination={pagination} navigation={navigation} swiperOptions={swiperOptions} onSlideChangeTransitionStart={onNextPage}>
            {pages}
          </SwiperCarousel>
        </div>
        <div className={`journey-onboarding__pagination +text-center +pd-horizontal-md swiper-pagination-fraction +mg-top-none +mg-top--lg +mg-bottom-md--sm`}> </div>
        {screenCTA}
      </div>
    </TrayContainer >
  )
}

export default JourneyOnboarding;
