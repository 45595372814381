import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import AuxApp from './AuxApp';
import * as serviceWorker from './serviceWorker';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import constructLDProvider  from './launchDarkly';
import './styles/scss/global.scss';

declare global {
  interface Window {
    mountAuxApp: (id: string) => void;
    auxAppAccessToken: string;
    auxAppRefreshToken: string;
    refreshBaconProfile: () => void;
    requestMyFinanceAds: (params: {rootElement: HTMLElement | null}) => void;
    mfEmbed: {[index:string] : {message: string}};
    _wq: Record<string, unknown>[];
    Wistia: {
      api: (id: string) => void;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initializeInline: (args: {[key: string]: any}) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Calendly: any;
    showHomebuyingJourney: boolean;
  }
}

// S3 redirect normalization: convert from fragment-based to regular
const path = (/^#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  history.replaceState(null, '', path);
}

// App
window.mountAuxApp = async (id: string) => {
  const LaunchDarklyProvider = await constructLDProvider();

  ReactDOM.render(
    <Provider store={store}>
      <LaunchDarklyProvider>
        <AuxApp />
      </LaunchDarklyProvider>
    </Provider>,
    document.getElementById(id)
  );
};

document.getElementById('aux-app') && window.mountAuxApp('aux-app');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
