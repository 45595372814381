import React from 'react';
import { CommonBaseProps } from '../../components/Common/Types';
type Props = {
  width?: number | string;
  height?: number | string;
} & CommonBaseProps;

const InfoCircle: React.FC<Props> = (props: Props) => {
  const { className = '', width = '16', height = '16'} = props;
  return (
    <span style={{lineHeight: 0}}>
      <svg className={`${className} icon-info-circle`} aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fillRule="nonzero" d="M9 12H7V7h2v5zM7 5.0122C7 4.4512 7.4512 4 8 4c.561 0 1 .4512 1 1.0122C9 5.5488 8.561 6 8 6c-.5488 0-1-.4512-1-.9878zM8 16c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8-3.5817 8-8 8zm0-1c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7z" />
      </svg>
    </span>
  );
}

export default InfoCircle;
