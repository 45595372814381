import React from 'react';
import { CommonBaseProps } from './Types';

type Props = {
  id?: string;
  labelText?: string;
  children: JSX.Element[];
  name?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
} & CommonBaseProps;

export type FormSelect = React.FC<Props>;

const FormSelect: FormSelect = (props: Props) => {
  const { id = "", labelText = "", className = "", onChange, name = "", value = "" } = props;

  return (
    <div className={`form-element-container ${className}`}>
      <label htmlFor={id} className="form-label --floated">{labelText}</label>
      <div className="+pos-relative disable-max-width">
        <span className="form-select__arrow icon --icon-xs">
          <svg className="icon__glyph" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="-1 -2 8 8" version="1.1" aria-hidden="true">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
              <g id="Icons" transform="translate(-134.000000, -43.000000)" fill="">
                <polygon id="caret-small-down" points="134.707107 43 134 43.6965437 137.353553 47 140.707107 43.6965437 140 43 137.358734 45.6018093"></polygon>
              </g>
            </g>
          </svg>
        </span>
        <div className="+bg-white">
          <select id={id} name={name} className="form-select +type-body-one +bg-transparent" onChange={onChange} value={value}>
            {props.children}
          </select>
        </div>
      </div>
    </div>
  )
}

export default FormSelect;
