import React from 'react';
import {CommonBaseProps} from '../Types';
import './_basecontainer.scss';

type Props = {
  children: JSX.Element;
} & CommonBaseProps;

const BaseContainer: React.FC<Props> = (props: Props) => {
  const {className = '', children} = props;
  return (
    <section className={`base-container ${className}`}>
      {children}
    </section>
  )
}

export default BaseContainer;
