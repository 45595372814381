import React, { useEffect } from 'react';
import TrayModal from '../TrayModal';
import { NotificationPage, IllustrationConfig } from '../../Types';
import './NotificationPage.scss';

interface Props {
  handleClick: () => void;
  handleClose?: () => void;
  onLoad?: () => void;
  content: NotificationPage;
  className?: string;
  illustrationConfig: IllustrationConfig;
}

const NotificationPageComponent: React.FC<Props> = (props: Props) => {
  const { handleClick, handleClose, content, className = '', illustrationConfig, onLoad } = props;
  const background = { backgroundColor: `${illustrationConfig.illustration_config.background_color}` };
  const isReentry = content.type === 'reentry_tray';
  const overlayHandler = handleClose ? handleClose : handleClick;
  const filename = content.image?.filename ? content.image?.filename : illustrationConfig.images.tray.filename;
  const alt = content.image?.filename ? content.image?.alt : illustrationConfig.images.tray.alt;
  
  onLoad && useEffect(onLoad, []);

  return (
    <TrayModal onOverlayClick={overlayHandler} className={className}>
      <img style={background} src={filename} alt={alt} className="journey-notification-page__background" />
      <h1 className={`+type-heading-four +mg-top ${isReentry ? '+mg-bottom' : '+mg-bottom-sm'}`}>{content.heading}</h1>
      <p className="+fg-slate +type-body-one">{content.description}</p>
      <div className="+display-flex +flex-direction-column +align-items-center">
        <button className="button --primary +mg-top" onClick={handleClick}>{content.button_text}</button>
        {
          isReentry ? <button className="button --flat +fg-slate +mg-top" onClick={handleClose}>No thanks</button> : <></>
        }
      </div>
    </TrayModal>
  )
}

export default NotificationPageComponent;
