import React from 'react';
import { CheckMark } from '../../../../assets/tile_svgs';
import "./JourneyNode.scss";

interface Props {
  size: number;
  value: string | number;
  topOffset: number;
  isCompleted?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  styles?: React.CSSProperties;
}

const JourneyNode: React.FC<Props> = (props: Props) => {
  const { onClick, size, isCompleted = false, value, topOffset, styles = {} } = props;

  return (
    <div
      onClick={onClick}
      style={{
        transform: `translateY(${topOffset}px)`,
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size / 2}px`,
        cursor: `${isCompleted ? 'default' : 'pointer'}`,
        ...styles,
      }}
      className={`journey-node +display-flex +justify-content-center +align-items-center +pos-absolute +z-layer-one ${isCompleted && 'journey-node--completed'}`}>
      {isCompleted ? <CheckMark width={`${size / 1.85}px`} height={`${size / 1.85}px`} fill="white" /> : value}
    </div>
  );
}

export default JourneyNode;

