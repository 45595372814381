import React from 'react';
import './InputTile.scss';
import FormSelect from '../../../Common/FormSelect';

export type DropdownProps = {
  inputLabel: string;
  inputName: string;
  inputValue: string;
  options: DropdownOption[];
  errorCopy?: string;
  changeHandler: (fieldName: string, value: string) => void | null;
}

export type DropdownOption = {
  _uid: string;
  value: string;
}

const Select: React.FC<DropdownProps> = (props: DropdownProps) => {
  const {
    inputLabel,
    inputValue,
    options,
    inputName,
    changeHandler
  } = props;
  
  const onInputChange = (event: React.ChangeEvent) => {
    if (changeHandler) {
      const element = event.currentTarget as HTMLInputElement;
      changeHandler(inputName, element.value);
    }
  };

  return (
    <div className={`input-tile__input-container`}>
      <FormSelect
        id={inputName}
        labelText={inputLabel}
        name={inputName}
        onChange={onInputChange}
        className="+mg-bottom-none"
        value={inputValue}
      >
        {options.map((option) => <option key={option._uid} value={option.value} >{option.value}</option>)}
      </FormSelect>
    </div>
  );
}

export default Select;