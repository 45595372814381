import React from 'react';
import jwtDecode from 'jwt-decode'; 
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { BaeauToken, getAuthStatus, getAccessToken } from './utils/authorization';

type ProviderProps = {
  children: JSX.Element;
};

const constructLDProvider = async (): Promise<React.FunctionComponent<ProviderProps>> => {
  const { isAuthenticated, isVerified } = getAuthStatus();
  const token = getAccessToken();
  let provider: React.FunctionComponent<ProviderProps> = ({ children }: ProviderProps) => children;

  if (isAuthenticated && isVerified && token) {
    const { user_name } = jwtDecode(token) as BaeauToken;
    const userEmailDomain = user_name.split("@")[1];

    try {
      const LaunchDarklyProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID || '',
        user: {
          "key": userEmailDomain
        }
      })
      
      provider = LaunchDarklyProvider as React.FunctionComponent<ProviderProps>;
    }
    catch(err) {
      console.log("Error launching LD provider", err);
    }
  }


  return provider;
}

export default constructLDProvider;
