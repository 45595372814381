import { KeyValue } from "../../Common/Types";

type HomeAffordabilityCalculationProperties = {
  annualIncome: number;
  monthlyDebt: number;
  downPayment: number;
  annualPropertyTax?: number;
  annualInsurance?: number;
  interestRate?: number;
  lengthOfLoanYears?: number;
  targetDti: number;
  minDti?: number;
};

type AffordabilityResponse = {
  total: number;
  monthlyPayment: number;
} & KeyValue;
/**
 * 
 * @param props Home affordability calculations
 * @returns 
 */
export function calculateHomeAffordability(props: HomeAffordabilityCalculationProperties): AffordabilityResponse {
  const { annualIncome = 50000, monthlyDebt = 0, downPayment = 0, annualPropertyTax = 2741, annualInsurance = 0,
    interestRate = 0.045, lengthOfLoanYears = 30, targetDti = .2, minDti = 20 } = props;

  const additionalAnnualCosts = annualInsurance + annualPropertyTax;  
  const additionalMonthlyCost = additionalAnnualCosts / 12; //  Non principle/interest related costs
  const monthlyIncome = annualIncome / 12;  //  Monthly Income
  const currentMonthlyDti = (monthlyDebt) / monthlyIncome;  // The current DTI based on income and debt
  const targetDebtToIncome = targetDti; //  The DTI to target with current debt
  let budgetDebtToIncome = Math.round(100 * (targetDebtToIncome - currentMonthlyDti)) / 100;  //  Additional debt % allowed to reach target debt to income
  if (budgetDebtToIncome < minDti) {
    budgetDebtToIncome = targetDebtToIncome;
  }

  const monthlyLoanPayment = (budgetDebtToIncome * monthlyIncome);  // How much more debt per month you could take
  const fullMonthlyPayment = monthlyLoanPayment + additionalMonthlyCost; // Loan Debt + Insurance/Taxes
  const monthlyInterestRate = interestRate / 12;  // Monthly Interest
  const totalPayments = lengthOfLoanYears * 12;  // How many total payments there will be
  const lifetimePropertyTax = annualPropertyTax * lengthOfLoanYears; // Total tax for life of the loan
  const lifetimeInsurance = annualInsurance * lengthOfLoanYears; // Total insurance for life of the loan

  //  Calculate total paid with the budget amount in mind
  const principalAndInterest = (monthlyLoanPayment * lengthOfLoanYears * 12);
  
  // Get principal using Simple Interest Formula
  const principal =  principalAndInterest / (1 + interestRate * lengthOfLoanYears);
  const totalAffordability = principal + downPayment;

  return {
    additionalAnnualCosts,
    additionalMonthlyCost,
    interestRate,
    lifetimeInsurance,
    lifetimePropertyTax,
    currentMonthlyDti, 
    targetDebtToIncome, 
    budgetDebtToIncome, 
    monthlyDebt,
    monthlyLoanPayment,
    monthlyPayment: fullMonthlyPayment, 
    monthlyInterestRate, 
    totalPayments,
    principal,
    total: totalAffordability
  };
}