import { RootState } from '../index';
import { ActionStatus, Journey, UserData, Subtask, SubtaskRequiredUpdate, ActionStatusType } from '../../components/Journeys/Types';
import { KeyValue } from '../../components/Common/Types';
import { 
  UPDATE_CURRENT_JOURNEY, 
  UPDATE_USER_ACTION_STATUSES,
  UPDATE_USER_DATA, 
  UPDATE_USER_JOURNEYS, 
  RESET_JOURNEY_STORE, 
  UPDATE_JOURNEY_ERROR, 
  UPDATE_SHOW_REENTRY_SCREEN,
  UPDATE_SESSION_RENTRY_FLAG_CHECKED,
  UPDATE_JOURNEY_SCROLL,
  UPDATE_JOURNEY_SUBTASK_REQUIRED,
  UPDATE_SHOW_COMPLETION,
} from './actions';

export interface ActionStatusHash {
  [key: string]: ActionStatus
}

export interface UserDataHash {
  [key: string]: UserData
}

export interface JourneyState {
  currentJourney: Journey | null;
  storyblokSlugHash: KeyValue;
  actionStatuses: ActionStatusHash;
  journeyHash: ActionStatusHash;
  userData: UserDataHash | null;
  journeysLoaded: boolean;
  loadError: {[key: string] : string | null} | null;
  showReentryScreen: boolean;
  showCompletion: { [key: string]: boolean };
  sessionRentryFlagChecked: boolean;
  journeyScroll: number[];
}

export const journeyInitialState: JourneyState = {
  currentJourney: null,
  actionStatuses: {},
  journeyHash: {},
  storyblokSlugHash: {},
  userData: null,
  journeysLoaded: false,
  loadError: null,
  showReentryScreen: false,
  showCompletion: { 
    [ActionStatusType.JOURNEY]: false, 
    [ActionStatusType.STEP]: false,
  },
  sessionRentryFlagChecked: false,
  journeyScroll: [],
};

/**
 * Updates the action statuses for a user by storyblok_id
 * @param currentActionStatuses 
 * @param newActionStatuses 
 * @returns 
 */
export function reduceActionStatuses(currentActionStatuses: ActionStatusHash, newActionStatuses: ActionStatus[] = []): ActionStatusHash {
  const actionHash = {
    ...currentActionStatuses
  };

  newActionStatuses.forEach(actionStatus => {
    actionHash[actionStatus.storyblok_id] = actionStatus;
  });
  return actionHash;
}

/**
 * Updates the hashed journeys by journey_slug
 * @param currentJourneys 
 * @param updatedJourneys 
 * @returns 
 */
export function reduceJourneyHash(currentJourneys: ActionStatusHash = {}, updatedJourneys: ActionStatus[] = []): ActionStatusHash {
  const actionHash = {
    ...currentJourneys
  };
  updatedJourneys.forEach(updatedJourney => {
    actionHash[updatedJourney.journey_slug] = updatedJourney;
  });
  return actionHash;

}

//  Updates Subtasks in the journey if subtasks are "required"
export function updateJourneySubtaskRequired (journey: Journey | null, updates: SubtaskRequiredUpdate[]): Journey | null {
  const subtaskRequiredHash = updates.reduce((dictionary: KeyValue, nextUpdate) => {
    dictionary[nextUpdate.storyblok_id] = nextUpdate;
    return dictionary;
  }, {});

  // N Traversal with on nested subtasks using subtask update dictionary
  journey?.steps.forEach(step => {
    step.tasks.forEach(tasks => {
      tasks.subtasks.forEach((subtask: Subtask) => {
        const update = subtaskRequiredHash[subtask.storyblok_id];
        if (update) {
          subtask.required = update.isRequired;
        }
      })
    })
  })
  return journey;
}

export function reduceCurrentJourneySlugs (journey: Journey): KeyValue {
  if (!journey) return {};

  const subtaskHash: KeyValue = {};
  const sbHash: KeyValue = {};

  //Iterate over steps, add to sb hash
  journey.steps.forEach(step => {
    const stepSlugBase = {
      journeySlug: journey.slug,
      stepSlug: step.slug
    };

    sbHash[step.storyblok_id] = stepSlugBase;

    //  Iterate Tasks, add to sb hash
    step.tasks.forEach(task => {
      const taskSlugBase = { ...stepSlugBase, taskSlug: task.slug };
      sbHash[task.storyblok_id] = taskSlugBase;

      //  Iterate Subtasks, add to sb hash
      task.subtasks.forEach(subtask => {
        subtaskHash[subtask.storyblok_id] = subtask;
        sbHash[subtask.storyblok_id] = {
          ...taskSlugBase,
          subtaskSlug: subtask.slug
        }
      });
    });
  });

  return sbHash;
}
/**
 * Creates/Updates userData hash by field name
 * @param currentData 
 * @param updatedUserData 
 * @returns 
 */
export function reduceUserData(currentData: UserDataHash = {}, updatedUserData: UserData | UserData[]): UserDataHash {
  const dataHash = {
    ...currentData
  };

  const updatedData = Array.isArray(updatedUserData) ? updatedUserData : [updatedUserData];
  updatedData.forEach(update => dataHash[update.field_name] = update);
  return dataHash;
}

// eslint-disable-next-line 
export function journeyReducer(state: RootState, action: any) : RootState { 
  switch (action.type) {
    case UPDATE_CURRENT_JOURNEY:
      return {
        ...state,
        journey: {
          ...state.journey,
          currentJourney: action.journey,
          storyblokSlugHash: reduceCurrentJourneySlugs(action.journey),
          loadError: {
            ...state.journey.loadError,
            loadJourney: null
          }
        }
      };
    case UPDATE_JOURNEY_SUBTASK_REQUIRED:
      return {
        ...state,
        journey: {
          ...state.journey,
          currentJourney: updateJourneySubtaskRequired(state.journey.currentJourney, action.updates)
        }
      };
    case UPDATE_USER_JOURNEYS:
      return {
        ...state,
        journey: {
          ...state.journey,
          journeyHash: reduceJourneyHash(state.journey.journeyHash, action.journeys),
          journeysLoaded: true
        }
      };
    case UPDATE_USER_ACTION_STATUSES:
      return {
        ...state,
        journey: {
          ...state.journey,
          actionStatuses: reduceActionStatuses(state.journey.actionStatuses, action.actionStatuses)
        }
      };
      case UPDATE_USER_DATA:
        return {
          ...state,
          journey: {
            ...state.journey,
            userData: reduceUserData(state.journey.userData || {}, action.userData)
          }
        };

      case RESET_JOURNEY_STORE:
        return {
          ...state,
          journey: journeyInitialState
        }
      case UPDATE_JOURNEY_ERROR:
        return {
          ...state,
          journey: {
            ...state.journey,
            loadError: {
              ...state.journey.loadError,
              [action.actionType] : action.message
            },
          }
        };
      case UPDATE_SHOW_REENTRY_SCREEN:
        return {
          ...state,
          journey: {
            ...state.journey,
            showReentryScreen: action.showReentryScreen,
          }
        };
      case UPDATE_SHOW_COMPLETION: 
        return {
          ...state,
          journey: {
            ...state.journey,
            showCompletion: {
              ...state.journey.showCompletion,
              [action.actionType]: action.value
            }
          }
        };
      case UPDATE_SESSION_RENTRY_FLAG_CHECKED:
        return {
          ...state,
          journey: {
            ...state.journey,
            sessionRentryFlagChecked: action.checked,
          }
        }
      case UPDATE_JOURNEY_SCROLL:
        return {
          ...state, 
          journey: {
            ...state.journey,
            journeyScroll: action.position
          }
        }
    default:
      return state;
  }
}