import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getAuthStatus } from "./authorization";

const  { isAuthenticated, isVerified } = getAuthStatus();

/**
 * A wrapper for <Route> that redirects unverified users
 *
 * @param children - the component being rendered
 * @param rest - arguments available from React Router (e.g. path, location)
 * @constructor
 */
export default function PrivateRoute({ children, ...rest }: { children: JSX.Element | JSX.Element[] } & { [key: string]: unknown }): JSX.Element {
  if (isAuthenticated) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          // Render the appropriate component
          isVerified ? (
            children
          ) : (
            // Redirect user to email verification screen if user is not verified
            <Redirect
              to={{
                pathname: '/email-verification',
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  // Redirect user to login screen if auth token doesn't exist
  return (
    <Redirect to={{
      pathname: `/sign-in`,
      search: `?redirect-to=${location.href}`
    }} />
  );
}