import React, { useEffect } from 'react';
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from 'react-redux';
import { buildAppJourneyUrl, buildJourneyUrl, getTaskBySlug } from '../../helpers';
import { RootState } from '../../../../store';
import { JourneySlugs } from '../../Types';

const SubtaskLoader: React.FC = () => {
  const match = useRouteMatch<JourneySlugs>();
  const { journeySlug, stepSlug, taskSlug } = match.params;
  const baseJourneyUrl = buildAppJourneyUrl({journeySlug});
  
  // Missing step or task slug redirects to journey hub
  if (!stepSlug || !taskSlug) {
    return <Redirect to={baseJourneyUrl} />;
  }
  
  // Get current task object
  const journey = useSelector((state: RootState) => state.journey);
  const history = useHistory();
  const { currentJourney, actionStatuses } = journey;
  
  // Determine is user and journey data has been loaded into state
  const isJourneyDataLoaded = () => currentJourney && Object.keys(actionStatuses).length;

  // Returns first incomplete subtask url in a task if the task exists otherwise journey hub url
  const getRedirectUrl = (): string => {
    const task = getTaskBySlug(currentJourney, stepSlug, taskSlug);
    if (!task || !task.subtasks || !task.subtasks.length) {
      return baseJourneyUrl;
    }

    const taskStatus = actionStatuses[task.storyblok_id];
    const isTaskComplete = taskStatus && taskStatus.completed_at;

    const subtask = task.subtasks.find(item => {
      const actionStatus = actionStatuses[item.storyblok_id];
      return !actionStatus || !actionStatus.completed_at;
    });

    const subtaskSlug = subtask && !isTaskComplete ? subtask.slug : task.subtasks[0].slug;
    const subtaskUrl = buildJourneyUrl({ journeySlug, stepSlug, taskSlug, subtaskSlug });
    return subtaskUrl;
  }

  // Application needs to wait to load user and journey data if deep linking to task
  useEffect(() => {
    if (isJourneyDataLoaded()) {
      const url = getRedirectUrl();
      history.replace(url);
    }
  }, [currentJourney, actionStatuses]);


  if (isJourneyDataLoaded()) {
    const url = getRedirectUrl();
    return <Redirect to={url} />
  }

  return <></>;
}

export default SubtaskLoader;
