import jwtDecode from 'jwt-decode';
import { KeyValue } from '../components/Common/Types';

export enum AuthRoles {
  RoleCustomer = "ROLE_CUSTOMER",
  RoleInternal = "ROLE_INTERNAL",
  RoleAdmin = "ROLE_ADMIN",
  RoleService = "ROLE_SERVICE",
  RoleClient = "ROLE_CLIENT"
}

export interface BaeauToken {
  tenant_id: string;
  tenant_name: string;
  auth0_id: string;
  user_name: string;
  authorities: AuthRoles[];
  client_id: string;
  aud: string[];
  legacy_api_client_id: string | null;
  user_id: string;
  scope: string[];
  exp: number;
  customer_id: string;
  bedrock_id: string;
  account_verified: boolean;
  jti: string;
  iat: number;
}

export const getCookie = (cookie: string): string => {
  const name = cookie + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export const getAccessToken = (): string | null => {
  // grab the value from a cookie if it exists
  const cookieName = getAuthCookieName();
  if (getCookie(cookieName)) {
    window.auxAppAccessToken = getCookie(cookieName);
  }

  return window.auxAppAccessToken || process.env.REACT_APP_ACCESS_TOKEN || null;
};

export const getAuthCookieName = (): string => {
  return process.env.REACT_APP_ENVIRONMENT === "production" ? "BAEAU" : "BAEAU-QA";
}

/**
 * Decodes a JWT and returns a tuple of booleans
 * isAuthenticated: whether a user has a token or not
 * isVerified: decoded token value in regards to the user's verification status
 */
export const getAuthStatus = (): {
  isAuthenticated: boolean,
  isVerified: boolean;
} => {
  const authStatus = {
    isAuthenticated: false,
    isVerified: false
  };
  const encodedToken = getAccessToken();
  if (encodedToken === null) return authStatus;

  const decodedToken =  jwtDecode(encodedToken) as BaeauToken;
  if (Date.now() >= decodedToken.exp * 1000) return authStatus;

  authStatus.isAuthenticated = true;
  authStatus.isVerified = decodedToken.account_verified;

  return authStatus;
};

/**
 * Decodes a JWT and returns boolean
 * isPermitted: whether the token has all of the required roles
 */
export const hasAllowedAuthRoles = (roles: AuthRoles[]): boolean => {
  const token = getAccessToken();

  if (token) {
    const { authorities } = jwtDecode(token) as BaeauToken;
    return typeof roles.find((role) => authorities.includes(role)) === 'string';
  }

  return false;
};

export type UserSessionKey = KeyValue;

/**
 * Sets a user session cookie, which includes an object of feature flags
 */
export const setLocalUserSession = (newData: UserSessionKey): void => {
  const token = getAccessToken();

  if (!token) {
    return;
  }
  const updatedData = {};

  const existingData = getLocalUserSession();
  // If writing a new cookie, add existing data 
  if (existingData) {
    Object.assign(updatedData, existingData);
  }
  // If writing a new cookie, add new data 
  Object.assign(updatedData, newData)
  
  // Set cookie expiration to match authenticated session expiration
  const decodedToken = jwtDecode(token) as BaeauToken;
  const expirationDate = new Date(decodedToken.exp * 1000).toUTCString();

  const cookieToSet = [`BR_AUTH_USER_SESSION=${JSON.stringify(updatedData)}; `, `expires=${expirationDate}; `, 'path=/;'].join('');
  document.cookie = cookieToSet;
  return;
}

/**
 * Retrieves a user session flag value if a flag is provided, otherwise returns a parsed cookie.
 */
export const getLocalUserSession = (flag?: string): UserSessionKey | void => {
  const userSessionCookie = getCookie('BR_AUTH_USER_SESSION');
  if (!userSessionCookie) {
    return;
  }

  const parsedSession: UserSessionKey = JSON.parse(userSessionCookie);

  // checks value of flag for business logic use cases and returns
  if (flag) {
    return {[flag]: parsedSession[flag]};
  }

  // returns existing data if no flag is passed
  return parsedSession;
}
