import { logger } from '../RollbarErrorBoundary';
import { get } from './http';
import { getCookie } from '../utils/authorization';

export type LoansProductOffers = {
  id: string;
  amount: {
    min: number | null;
    max: number | null;
    value: number | null;
  },
  apr: number;
  expires: number;
  fees: string | null;
  interest_rate: number | null;
  lender: {
    name: string;
    logo: string;
    reviewScore: number | null;
    UUID: string;
  };
  rateDetails: string[];
  termsConditions: string;
  payment: number | null;
  terms: number | null;
  url: string | null;
  leadUUID: string;
  formUUID: string;
}

interface TrackingLoanOffersArgs {
  totalListings: number;
  loanAmount: number | null;
  tablePosition: number;
  rate: number | null;
  apr: number;
  productId: string;
  leadUUID: string;
  lenderUUID: string;
  formUUID: string;
  lenderName: string;
  destinationUrl: string;
  purchaseId: string;
}

export const fetchLoanOffers = async (): Promise<LoansProductOffers[]> => {
  const url = `${process.env.REACT_APP_LOANS_API}/v1/products?query={
    products {
      id
      apr {
        sub
        value
      }
      amount {
        min
        max
        value
      }
      terms {
        value
      }
      url
      interest_rate
      payment
      fees
      expires
      rate_details
      terms_and_conditions
      lead_uuid
      form_uuid
      lender {
        uuid
        name
        logo
        review {
          score
        }
      }
    }
  }`;

  const res = await get(url);

  if (res?.data?.products) {
    return transformLoanOffersResponse(res.data.products);
  }

  return [];
}

/**
 * Simple helper to normalize PL offer data to make it simpler to work with
 */
// eslint-disable-next-line
export const transformLoanOffersResponse = (response: { [key: string]: any }[]): LoansProductOffers[] => {
  const formatted = response.map((res) => ({
    id: res.id,
    amount: {
      min: res.amount.min,
      max: res.amount.max,
      value: res.amount.value
    },
    apr: res.apr.value,
    expires: res.expires || 0,
    fees: res.fees ? res.fees.replace('Origination ', '') : null,
    interest_rate: res.interest_rate,
    lender: {
      logo: res.lender.logo,
      name: res.lender.name,
      reviewScore: res.lender?.review?.score || null,
      UUID: res.lender.uuid,
    },
    rateDetails: res.rate_details || [],
    termsConditions: res.terms_and_conditions || '',
    payment: Math.round(res.payment),
    terms: res.terms.value,
    url: res.url,
    leadUUID: res.lead_uuid,
    formUUID: res.form_uuid,
  }));

  return sortLoanOffersByAPR(formatted);
};

// Sort by lowest APR
const sortLoanOffersByAPR = (offers: LoansProductOffers[]) => {
  return offers.sort((a, b) => a.apr - b.apr);
};

export const calculateLoansExpiration = (expiryTimestamp: number): number => {
  // get our two timestamps and the difference between them
  const today = new Date().getTime();
  // we receive a UNIX timestamp from the endpoint, so multiplying here to get a usable date for comparison
  const expiration = new Date(expiryTimestamp * 1000).getTime();
  const difference = expiration - today;

  // converts our difference to physical days and rounds up
  return Math.round(difference / (1000 * 3600 * 24));
};


/**
 * A series of steps to build a Loans tracking event, which is used to help
 * accurately report revenue metrics:
 *
 * 1.) Get values from tagular web context
 * 2.) Get args about specific loan product
 * 3.) Build / make request
 */
// eslint-disable-next-line
export const trackLoansOfferRevenue = async (options: TrackingLoanOffersArgs): Promise<{ [key: string]: any } | null> => {
  // pick off values we need from tagular
  // eslint-disable-next-line
  let tagularWebContext: { [key: string]: any } = {}
  if (window._Tagular && window._Tagular.webContext) {
    tagularWebContext = window._Tagular.webContext;
  }

  // parse / pick off values from the BRMP tracking cookie if it exists
  const brmp = getCookie('brmp');
  const data = brmp ? JSON.parse(decodeURIComponent(brmp)) : null;

  /**
   * Nearly all of these parameters are optional, which is why we fall back
   * to empty strings in most cases
   */
  const brmpData = {
    pid: data?.pid?.p || 'br3',
    ttcid: data?.ttcid || '',
    utm_adgid: data?.utm_adgid || '',
    gclid: data?.gclid || '',
    utm_campaign: data?.utm_campaign || '',
    utm_source: data?.utm_source || '',
    utm_medium: data?.utm_medium || '',
    utm_term: data?.utm_term || '',
    utm_content: data?.utm_content || '',
    utm_adid: data?.utm_adid || '',
    brid: data?.brid || '',
  }

  // get args from the loan offer
  const {
    totalListings,
    loanAmount,
    tablePosition,
    rate,
    apr,
    productId,
    leadUUID,
    formUUID,
    lenderUUID,
    destinationUrl,
    lenderName,
    purchaseId,
  } = options;

  // build our request body
  const body = {
    purchaseId,
    formId: formUUID,
    referrer_url: window.location.href,
    paidListings: totalListings,
    uniqueListings: totalListings,
    sectionPosition: 1,
    tablePosition,
    advertiserId: lenderUUID,
    productId,
    loanAmount,
    rate,
    apr,
    lenderCorrelationId: leadUUID,
    lenderName,
    destinationUrl,
    instanceId: tagularWebContext?.instanceId || '',
    userAgent: tagularWebContext?.userAgent || navigator.userAgent,
    ip: tagularWebContext?.ipAddress || '1.1.1.1',
    session_referral_url: tagularWebContext?.page?.url || '',
    session_id: tagularWebContext?.sessionId || '',
    anonymous_id: tagularWebContext?.anonymousId || '',
    flow: 6,
    ...brmpData,
  };

  const request = new Request(`${process.env.REACT_APP_LOANS_API}/v1/tracking`);
  const init: RequestInit = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'x-api-key': process.env.REACT_APP_LOANS_API_AUTH_TOKEN || '',
    },
    cache: 'default',
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(request, init);

    return await response.json();
  } catch (e) {
    logger.warn(e as Error)
  }

  return null;
};
