import React from 'react';

type Props = {
  to: string;
}

const AuxAppRedirectWrapper: React.FC<Props> = (props: Props) => {
  const { to } = props;

  window.location.replace(to);
  return <></>;
}

export default AuxAppRedirectWrapper;