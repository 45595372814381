import React from 'react';

type Props = {
  direction: "left" | "right" | "downward" | "upward";
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.SyntheticEvent) => void;
  ariaHidden?: boolean;
}

const AngleBracket: React.FC<Props> = (props: Props) => {
  const rotationAmount = {
    "right" : 0,
    "downward": 90,
    "left" : 180,
    "upward" : 270,
  }[props.direction];

  return (
    <svg 
      width={props.width ? props.width : 7} 
      height={props.height ? props.height : 12}
      viewBox="0 0 7 12" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      className={props.className ? props.className : ''}
      style={props.style ? props.style : undefined}
      onClick={props.onClick ? props.onClick : undefined}
      aria-hidden={!!props.ariaHidden}
     >
      <g 
        style={{transformOrigin: 'center', transform: `rotate(${rotationAmount}deg)`}}>
        <path d="M1.9 0L0.5 1.5L4.7 6L0.5 10.5L1.9 12L7.5 6L1.9 0Z" fill={props.fill ? props.fill : 'white' } />
      </g>
    </svg>
  )
}

export default AngleBracket;
