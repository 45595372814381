import React, { useEffect, useRef } from 'react';
import Cleave from 'cleave.js/react';
import { addJourneyScrollPosition } from '../../../../store/journey/actions';

export enum InputFormat {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  CURRENCY = "CURRENCY",
  ZIP = "ZIP",
  DECIMAL_PERCENTAGE = "DECIMAL_PERCENTAGE",
  DECIMAL = "DECIMAL"
}

interface InputOptions {
  [key: string]: {
    format: Record<string, unknown>
  }
}

const InputOptions: InputOptions = {
  [InputFormat.CURRENCY]: {
    format: {
      numeral: true,
      prefix: "$ ",
      rawValueTrimPrefix: true,
    }
  },
  [InputFormat.NUMBER]: {
    format: {
      numericOnly: true
    }
  },
  [InputFormat.TEXT]: {
    format: {
      delimiter: ""
    }
  },
  [InputFormat.ZIP]: {
    format: {
      blocks: [5],
      numericOnly: true
    }
  },
  [InputFormat.DECIMAL]: {
    format: {
      numeral: true,
      numeralDecimalMark: '.',
      numeralDecimalScale: 4
    }
  },
  [InputFormat.DECIMAL_PERCENTAGE]: {
    format: {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalMark: ".",
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      prefix: '%',
      tailPrefix: true,
      stripLeadingZeroes: true
    }
  }
}

type Props = {
  inputLabel: string;
  inputName: string;
  inputId: string;
  inputFormat: InputFormat;
  inputValue?: string;
  alternativeText?: string;
  errorCopy?: string;
  inputContainerClassname?: string;
  placeholder?: string;
  changeHandler?: (fieldName: string, value: string) => void;
};

/**
 * Input Tile componenet
 */
const InputField: React.FC<Props> = (props: Props) => {
  const {
    inputLabel,
    inputName,
    inputId,
    inputFormat,
    inputValue,
    alternativeText,
    errorCopy,
    placeholder = '',
    changeHandler,
  } = props;

  // eslint-disable-next-line
  const onInputChange = (event: any) => {
    if (changeHandler) {
      changeHandler(inputName, event.target.rawValue);
    }
  };

  const ref = useRef(null);
  const options = InputOptions[inputFormat]?.format || InputOptions[InputFormat.TEXT].format;

  useEffect(() => {
    if (errorCopy && ref.current) {
      addJourneyScrollPosition((ref.current as HTMLElement).offsetTop);
    }
  }, [errorCopy]);

  return (
    <div ref={ref} className={`input-tile__input-container`}>
      <div className={`input-tile__input form-control ${errorCopy ? "--has-error" : ""}`}>
        <label className="form-label" htmlFor={inputId}>{inputLabel}</label>
        <Cleave options={options} id={inputId} onChange={onInputChange} name={inputName} value={inputValue} placeholder={placeholder} className="form-input" />
      </div>

      {errorCopy && (
        <span className="form-element-help --has-error">
          {errorCopy}
        </span>
      )}

      {alternativeText && (
        <div className="input-tile__alt-text +fg-slate">
          {alternativeText}
        </div>
      )}
    </div>
  );
}

export default InputField;