import { InterestsInterface } from "../components/Interests/InterestsInterface";

export type InterestsType =
  'SAVING_MONEY' |
  'HOMEBUYING' |
  'HOME_REFINANCING' |
  'PERSONAL_LOANS' |
  'HOME_EQUITY' |
  'CREDIT_CARDS' |
  'INVESTING' |
  'DEBT_MANAGEMENT' |
  'RETIRED_LIVING' |
  'RETIRED_PREP' |
  'STUDENT_LOANS' |
  'AUTO_LOAN' |
  'INSURANCE' |
  'TAX_INFORMATION';

export interface NewInterestInterface {
  name: InterestsType;
  label: string;
  selected: boolean;
  feedSlug: string;
}

export type TransitionalInterestInterface = InterestsInterface | NewInterestInterface;


export const initialInterests: TransitionalInterestInterface[] = [
  {
    name: 'SAVING_MONEY',
    label: 'Saving money',
    selected: false,
    feedSlug: 'saving-money-daily-brief',
  },
  {
    name: 'HOMEBUYING',
    label: 'Homebuying',
    selected: false,
    feedSlug: 'homebuying-daily-brief',
  },
  {
    name: 'HOME_REFINANCING',
    label: 'Home refinancing',
    selected: false,
    feedSlug: 'home-refinancing-daily-brief',
  },
  {
    name: 'PERSONAL_LOANS',
    label: 'Personal loans',
    selected: false,
    feedSlug: 'personal-loans-daily-brief',
  },
  {
    name: 'HOME_EQUITY',
    label: 'Home equity',
    selected: false,
    feedSlug: 'home-equity-daily-brief',
  },
  {
    name: 'CREDIT_CARDS',
    label: 'Credit cards',
    selected: false,
    feedSlug: 'credit-cards-daily-brief',
  },
  {
    name: 'INVESTING',
    label: 'Investing',
    selected: false,
    feedSlug: 'investing-daily-brief',
  },
  {
    name: 'DEBT_MANAGEMENT',
    label: 'Debt management',
    selected: false,
    feedSlug: 'debt-management-daily-brief',
  },
  {
    name: 'RETIRED_LIVING',
    label: 'Retired living',
    selected: false,
    feedSlug: 'retired-living-daily-brief',
  },
  {
    name: 'RETIRED_PREP',
    label: 'Retirement prep',
    selected: false,
    feedSlug: 'retirement-prep-daily-brief',
  },
  {
    name: 'STUDENT_LOANS',
    label: 'Student loans',
    selected: false,
    feedSlug: 'student-loans-daily-brief',
  },
  {
    name: 'AUTO_LOAN',
    label: 'Auto loans',
    selected: false,
    feedSlug: 'auto-loans-daily-brief',
  },
  {
    name: 'INSURANCE',
    label: 'Insurance',
    selected: false,
    feedSlug: 'insurance-daily-brief',
  },
  {
    name: 'TAX_INFORMATION',
    label: 'Tax information',
    selected: false,
    feedSlug: 'tax-information-daily-brief',
  },
];
