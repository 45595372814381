import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

// Styles
import './styles/css/app.css';
import './styles/css/classy-suppliment.css';

// Pages
import AuxAppEmailVerification from './AuxAppEmailVerification';
import TryAgainContainer from './components/TryAgain';

// Components
import Sidebar from "./components/Sidebar";
import RedirectLoadingView from './components/Loader/RedirectLoader';
import AuxAppBetaBadgeContainer from './AuxAppBetaBadgeContainer';
import AuxAppRedirectWrapper from './components/AuxAppRedirectWrapper';

// Utils
import PrivateRoute from './utils/PrivateRoute';
import JourneyRoutes from './components/Journeys/router';

const AuxApp: React.FC = () => {
  const ACCOUNTS_API_BASE_URL: string = process.env.REACT_APP_ACCOUNTS_API_BASE_URL || '';
  const ROUTE_BASENAME: string = process.env.REACT_APP_ROUTE_BASENAME !== undefined
  ? process.env.REACT_APP_ROUTE_BASENAME
  : 'app';
  const REACT_APP_BANKRATE_BASE_URL: string = process.env.REACT_APP_BANKRATE_BASE_URL || 'https://www.bankrate.com';
  
  return (
    <Router basename={ROUTE_BASENAME}>
      <div className="app-container +pos-relative">
        <section className="app-content">
          <AuxAppBetaBadgeContainer />
          <Switch>
            <Route path="/sign-in">
              <RedirectLoadingView windowLocation={`${ACCOUNTS_API_BASE_URL}/login`} />
            </Route>
            <Route path="/create-account">
              <RedirectLoadingView windowLocation={`${ACCOUNTS_API_BASE_URL}/login`} />
            </Route>
            <Route path="/reset-password">
              <RedirectLoadingView windowLocation={`${ACCOUNTS_API_BASE_URL}/login`} params={{ isPasswordReset: "true" }} />
            </Route>
            <Route path="/email-verification">
              <AuxAppEmailVerification />
            </Route>
            <Route path="/learning/journeys" component={JourneyRoutes} />
            <Route path="/learning">
              <AuxAppRedirectWrapper to={`${process.env.REACT_APP_REDIRECT_BASE_URLS}/personal-finance/learning/courses?redirect=true`} />
            </Route>
            <Route path="/learning/:route">
              <AuxAppRedirectWrapper to={`${process.env.REACT_APP_REDIRECT_BASE_URLS}/personal-finance/learning/courses?redirect=true`} />
            </Route>
            <Route path="/personal-finance">
              <AuxAppRedirectWrapper to={`${process.env.REACT_APP_REDIRECT_BASE_URLS}/personal-finance/learning/courses?redirect=true`} />
            </Route>
            <Route path="/logout">
              <RedirectLoadingView windowLocation={`${ACCOUNTS_API_BASE_URL}/logout`} />
            </Route>
            <PrivateRoute path="/try-again">
              <Sidebar />
              <TryAgainContainer />
            </PrivateRoute>
            {/* Default Route - if path not found, redirect to /dashboard */}
            {/* Redirecting routes to disable SPA NW-3850 */}
            <Route>
              <AuxAppRedirectWrapper to={`${REACT_APP_BANKRATE_BASE_URL}`} />
            </Route>
          </Switch>
        </section>
      </div>
    </Router>
  );
};

export default AuxApp;
