import { getAccessToken } from "../utils/authorization";
// eslint-disable-next-line 
const retrieve = async (method: string, url: string, body?: Record<string, unknown> | null, headers?: HeadersInit | null): Promise<any> => {
  const token = getAccessToken();
  if (!token) {
    throw new Error(`Error: No Access Token provided`);
  }
  const request = new Request(url);
  const init: RequestInit = {
    method,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + getAccessToken(),
      'Content-Type': 'application/json;charset=UTF-8',
    },
    // mode: 'cors',
    cache: 'default',
  };
  if (body) {
    init.body = JSON.stringify(body);
  }
  if(headers){
    init.headers = {...init.headers,...headers}
  }
  const response = await fetch(request, init);

  if (response.status == 401 && url.includes("redventuresla.com")) {
    window.location.replace("/logout")
  }
  if (!response.ok) {
    throw new Error(`Error: ${response.status}`);
  }
  try {
    return await response.json()
  } catch(e) {
    return null;
  }
};
// eslint-disable-next-line 
export const get = (url: string): Promise<any> => {
  return retrieve('GET', url);
};
// eslint-disable-next-line 
export const post = (url: string, body?: Record<string, unknown>): Promise<any> => {
  return retrieve('POST', url, body);
};
// eslint-disable-next-line 
export const patch = (url: string, body: Record<string, unknown>, headers?: HeadersInit | null): Promise<any> => {
  return retrieve('PATCH', url, body, headers);
};
// eslint-disable-next-line 
export const put = (url: string, body: Record<string, unknown>, headers?: HeadersInit | null): Promise<any> => {
  return retrieve('PUT', url, body, headers);
};
