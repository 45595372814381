import { ActionItem, ActionStatus, ActionStatusSummary, BaseSubtaskProps, Journey, JourneySlugs, Step, SkipProps, Task, Subtask, IllustrationConfig, JourneyBase, JourneyTileContent } from './Types';
import { IntegrationDictionary } from './ActionItems/Integrations';
import { ComponentDefinition } from './ActionItems/Types';
import { RootState } from '../../store';
import { JourneyUserStateInterface } from '../Profile/ProfileInterface';
import { ActionStatusHash } from '../../store/journey';
import toCamelCase from 'lodash.camelcase';
import { LocalSessionFlag } from './Types';

/** Journey Helpers */

/**
 * Returns Journey ActionStatus based on JourneySlug 
 */
export const getJourneyActionStatus = (journeySlug: string) => (state: RootState): ActionStatus | null => {
  const { journeyHash, actionStatuses } = state.journey;

  if (!journeyHash[journeySlug]) {
    return null;
  }

  const { storyblok_id } = journeyHash[journeySlug];
  return actionStatuses[storyblok_id];
};

/**
 * Returns array of action statuses for storyblok ids provided. If no action status exists
 * for an id it will befiltered out of results.
 */
export const getActionStatusesByStoryblokIds = (storyblokIds: string[]) => (state: RootState): ActionStatus[] => {
  const { actionStatuses } = state.journey;
  return storyblokIds
    .filter((id: string) => actionStatuses[id])
    .map((id: string) => actionStatuses[id]);
}

/**
 * Returns a TaskSubtaskStatus object which tallys the number of complete, incomplete and skipped subtasks
 */
export const getStatusSummary = (state: RootState) => (items: Step[] | Task[] | Subtask[]): ActionStatusSummary => {
  const { actionStatuses } = state.journey;
  const actions = items.map(item => actionStatuses[item.storyblok_id]);

  const initial: ActionStatusSummary = {
    complete: 0,
    incomplete: 0,
    skipped: 0,
  };

  return actions.reduce((acc, item) => {
    if (!item) {
      acc.incomplete++;
      return acc;
    }

    if (item.started_at && item.completed_at) {
      acc.complete++;
    } else if (item.completed_at) {
      acc.skipped++;
    } else {
      acc.incomplete++;
    }

    return acc;
  }, initial);
}

/**
 * Lookup and return step object for provided journey by slug
 */
export const getStepBySlug = (journey: Journey | null, stepSlug: string): Step | undefined => {
  if (!journey) return;
  return journey.steps.find((step => step.slug === stepSlug));
}

/**
 * Lookup and return task object for provided journey using step and task slugs.
 */
export const getTaskBySlug = (journey: Journey | null, stepSlug: string, taskSlug: string): Task | undefined => {
  if (!journey) return;
  const step = getStepBySlug(journey, stepSlug);
  return step?.tasks.find((task => task.slug === taskSlug));
}

/**
 * Lookup and return subtask object for provided journey using task and subtask slugs.
 */
export const getSubtaskBySlug = (journey: Journey | null, stepSlug: string, taskSlug: string, subtaskSlug: string): Subtask | undefined => {
  if (!journey) return;
  const task = getTaskBySlug(journey, stepSlug, taskSlug);
  return task?.subtasks.find((subtask => subtask.slug === subtaskSlug));
}

/**
 * Returns true if all other sibling steps, tasks, or subtasks
 * are complete not including the slug provided. Essentially checking if a 
 * given item is the last incomplete item of its type.
 */
export const isOtherSiblingsComplete = (items: JourneyBase[], itemSlug: string, actionStatuses: ActionStatusHash): boolean => {
  return items.every((item: JourneyBase) => {
    if (item.slug === itemSlug) return true;
    const actionStatus = actionStatuses[item.storyblok_id];
    const isRequired = (typeof item.required === 'boolean' ? item.required : true);
    return isRequired ? !!actionStatus?.completed_at : true;
  });
}

/**
 * Returns all sibling items of a step, task, or subtask. Which items
 * to lookup is determined by the lowest level slug provided.
 * Example: If a step slug and task slug are provided, tasks will be returned
 */
export const getSiblingItems = (journey: Journey, slugs: JourneySlugs): JourneyBase[] => {
  const { stepSlug = '', taskSlug = '', subtaskSlug = ''} = slugs;
  if (subtaskSlug) {
    const task = getTaskBySlug(journey, stepSlug, taskSlug);
    return task?.subtasks || [];
  }
  if (taskSlug) {
    const step = getStepBySlug(journey, stepSlug);
    return step?.tasks || [];
  }
  if (stepSlug) {
    return journey.steps;
  }

  return [];
}

/**
 * Finds journey user state for given slug or creates an instance of JourneyUserStateInterface with default values.
 */
export const findJourneyUserState = (userStateJourneys: JourneyUserStateInterface[] | undefined, journeySlug: string): JourneyUserStateInterface => {
  const defaultState = {
    journey_slug: journeySlug,
    skipped: false
  };

  if (!userStateJourneys) {
    return defaultState;
  }

  const journeyUserState = userStateJourneys.find(item => item.journey_slug === journeySlug);
  if (!journeyUserState) {
    return defaultState;
  }

  return journeyUserState;
}

/**
 * Returns users journey action status for current journey
 */
export const getUserJourney = (state: RootState): ActionStatus | undefined => {
  const { currentJourney, journeyHash } = state.journey;

  if (!currentJourney) return;

  const userJourney = journeyHash[currentJourney.slug];
  return userJourney;
}

/**
 * Returns users journey action status given a journey slug
 */
 export const getUserJourneyBySlug = (slug: string) => (state: RootState): ActionStatus | undefined => {
  const { journeyHash } = state.journey;
  
  return journeyHash[slug];
}

/**
 * Dynamically builds a journey url based on the slugs provided.
 * If a slug is missing, that piece of the url will not be appended.
 */
export const buildJourneyUrl = (slugs: JourneySlugs): string => {
  const { journeySlug, stepSlug, taskSlug, subtaskSlug } = slugs;
  const paths = {
    journeys: journeySlug,
    steps: stepSlug,
    tasks: taskSlug,
    subtasks: subtaskSlug
  }
  return Object.entries(paths).reduce((acc, cur) => {
    const [key, value] = cur;
    return value ? `${acc}/${key}/${value}` : acc;
  }, '/learning');
}

export const getSlugsByStoryblokId = (state: RootState) => (id: string): JourneySlugs => {
  const { storyblokSlugHash } = state.journey;
  const slugs = storyblokSlugHash[id];

  return slugs;
}
/**
 * Prepends /app to the build journey url function.
 * Required when using history.pushState.
 */
export const buildAppJourneyUrl = (slugs: JourneySlugs): string => {
  return '/app' + buildJourneyUrl(slugs);
};

/** Subtask Helpers */

/**
 * Takes the list action items for a Subtask and determines if the subtask
 * is an integration. Currently a subtask is defined as an integration
 * if the first item in the array is of component type intergration. 
 */
export const checkIsIntegration = (actionItems: ActionItem[]): boolean => {
  return actionItems.length > 0 && actionItems[0].component === 'integration';
}

/**
 * Takes the list of action items for an Integration Subtask and returns the
 * key to be used for component lookup in the dictionary.
 */
export const getIntegrationKey = (actionItems: ActionItem[]): string => {
  return actionItems[0].integration_name;
}

/**
 * Takes the list of action items for a subtask and checks if the
 * subtask is an integration. If the subtask is an integration we lookup
 * the integration key and use the key to find the correct integration
 * componenet in the IntegrationDictionary.
 */
export const getIntegrationComponent = (actionItems: ActionItem[]): ComponentDefinition | null => {
  const isIntegration = checkIsIntegration(actionItems);
  if (!isIntegration) {
    return null;
  }

  const integrationKey = getIntegrationKey(actionItems);
  return IntegrationDictionary[integrationKey];
}

/**
 * Takes BaseSubtaskProps for a Subtask or Integration component and returns
 * an object containing all the copy props used to render the skip modal.
 */
export const getSkipProps = (props: BaseSubtaskProps): SkipProps => {
  const { skipProps } = props;
  if (!skipProps) {
    return {
      skipHeadingText: '',
      skipBodyText: '',
      skipButtonText: '',
      skipCtaText: '',
    };
  }

  const { skipHeadingText, skipBodyText, skipButtonText, skipCtaText } = skipProps;

  return {
    skipCtaText,
    skipHeadingText,
    skipBodyText,
    skipButtonText,
  };
}

/**
 * Accepts an action status hash and array of steps. Finds the 
 * first in progress action status and matching step (if exists), then returns either that
 * illustration config or defaults to the config of the first step.  
 */
export const getIllustration = (actionStatuses: ActionStatusHash, steps: Step[]): IllustrationConfig => {
  const firstNotComplete = Object.values(actionStatuses).filter((v) => v.action_type === 'STEP' && v.completed_at !== null)[0];
  return steps.reduce((acc, curr) => {
    if (firstNotComplete && firstNotComplete.storyblok_id === curr.storyblok_id) {
      acc = curr.illustration_config;
      return acc;
    }
    acc = steps[0].illustration_config;
    return acc;

  },
    {
      illustration_config: {
        background_color: '',
        hub_position_desktop: '',
      },
      images: {},
    }
  )
};

const getFirstToComplete = (actionStatuses: ActionStatusHash, array: JourneyBase[]): JourneyBase | undefined => {
  return array.find((item: JourneyBase) => {
    return !actionStatuses[item.storyblok_id]?.completed_at;
  })
}

export const getNextSubtaskSlugs = (actionStatuses: ActionStatusHash, journey: Journey): JourneySlugs => {
  const firstStepToComplete = getFirstToComplete(actionStatuses, journey.steps) as Step;
  const firstTaskToComplete = getFirstToComplete(actionStatuses, firstStepToComplete.tasks) as Task;
  const firstSubtaskToComplete = getFirstToComplete(actionStatuses, firstTaskToComplete.subtasks) as Subtask;

  return {
    journeySlug: journey.slug,
    stepSlug: firstStepToComplete.slug,
    taskSlug: firstTaskToComplete.slug,
    subtaskSlug: firstSubtaskToComplete.slug,
  };
}
// Return journey slugs/rename function
export const getNextSubtaskUrl = (actionStatuses: ActionStatusHash, journey: Journey): string => {
  const slugs = getNextSubtaskSlugs(actionStatuses, journey);
  return buildJourneyUrl(slugs);
}

/**
 * Per conversation with product, the convention for tracking values will be 
 * 1) Underscores to seperate each word
 * 2) Uppercase first character for each word
 * 
 * The current formatting in our global tracking methods only follows the first of those guidelines, 
 * so we can utilize this method for journey specific tracking.
 * 
 * Ex. "test value" => "Test_Value"
 */
export const formatTrackingText = (str: string, symbol = ' '): string => {
  return str
  .split(symbol)
  .map(word => word[0].toUpperCase() + word.substring(1))
  .join('_');
};

export const constructNamespacedSessionFlag = (journeySlug: string, localSessionFlag: LocalSessionFlag): string => {
  if (journeySlug.length === 0) throw new Error("Journey slug cannot be empty");
  return `${toCamelCase(journeySlug)}${localSessionFlag}`;
}

/**
 * Returns true if an action status was skipped by checking if there is a
 * completed_at date and no started_at date.
 */
export const isActionStatusSkipped = (actionStatus: ActionStatus | undefined): boolean => {
  return !!(actionStatus?.completed_at && !actionStatus?.started_at);
}

/**
 * Returns true if an action status was completed by checking if there is 
 * completed_at date AND started_at date
 * @param actionStatus 
 * @returns 
 */
export const isActionStatusAutoCompleted = (actionStatus: ActionStatus | undefined): boolean => {
  return !!(actionStatus?.completed_at && actionStatus?.started_at);
}

/**
 * Returns the corresponding tile state of a Journey given a
 * corresponding action status
 */
export const getTileContent = (
  actionStatus: ActionStatus | undefined,
  journey: Journey
): JourneyTileContent => {
  if (actionStatus === undefined ) {
    return journey.tile.inactive;
  } else if (typeof actionStatus.completed_at === "string") {
    return journey.tile.completed
  } else {
    return journey.tile.active
  }
}