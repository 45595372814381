import React from 'react';
import { CommonBaseProps } from '../Types';
import './LabeledValue.scss';

type LabeledValueProps = {
  value: string | number;
  label: string;
  valueClassname?: string;
  labelClassName?: string
} & CommonBaseProps;

const LabeledValue: React.FC<LabeledValueProps> = (props: LabeledValueProps) => {
  const { label, value, valueClassname = '', labelClassName = '' } = props;
  return (
    <div>
      <div className={`+text-center +heading-beta ${valueClassname} labeled-value__value`}>{value}</div>
      <div className={`+text-center +mg-top-sm ${labelClassName} labeled-value__label`}>{label}</div>
    </div>
  );
};

export default LabeledValue;