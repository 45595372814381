/* eslint-disable */
import React from "react";
import { KeyValue } from '../../../Common/Types';
import "./FreeForm.scss";

const textLegend: KeyValue = {
  span: 'span',
  link: 'a'
}

const headingLegend: KeyValue = {
  heading_1: 'h1',
  heading_2: 'h2',
  heading_3: 'h3',
  heading_4: 'h4',
  heading_5: 'h5',
  heading_6: 'h6'
}

const markupLegend: KeyValue = {
  horizontal_rule: 'hr',
  heading: 'h',
  ordered_list: 'ol',
  paragraph: 'p',
  list_item: 'li',
  bullet_list: 'ul',
  hard_break: 'br',
}

type LinkProps = {
  href: string,
  linktype: string,
  target: string
}

const getMarks = (mark: any) => {
  const markType = mark?.type;
  if (markType === 'styled') {
    // default font color to slate, but have override available for black font color
    if (mark.attrs.class === '+fg-black') {
      return '+fg-black';
    }
  }
  if (markType === 'bold') {
    return '+style-bold';
  }
  if (markType === 'italic') {
    return '+style-italic';
  }
  if (markType === 'underline') {
    return '+style-underline';
  }
  // if no supported marks, return empty string
  return '';
}

const generateElement = (elementType: string, classNames: string, key: number, content: KeyValue | null, linkProps?: LinkProps) => {
  let elementProps = {
    className: classNames,
    key: `wysiwyg-${elementType}-${key}`
  }

  // if linkProps exists, add href and target to element properties
  if (linkProps) {
    Object.assign(elementProps, { href: linkProps.href, target: linkProps.target });
  }
  const tag = React.createElement(elementType, elementProps, content);
  return tag;
}

/**
 * This function returns a formatted JSX element in the end. Will also handle the following cases while formatting:
 * - sets the "default" copy color to slate
 * - formats all content types, given a value.type to key off of
 * - determines how to format value type "text" if an href exists
 * - formats headings
 * - formats all other supported content types
 */
const formatContent = (item: any, index: number): any => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [key, value] = item;
  const content: KeyValue | null = value.content ? Object.entries(value.content).map((item2, index) => { return formatContent(item2, index); }) : null;

  const classNames: string[] = [];

  if (value.marks) {
    value.marks.map((mark: string) => {
      classNames.push(getMarks(mark));
    });
  } else if (value.type === 'horizontal_rule') {
    classNames.push('journey-subtask__hr');
  } else {
    classNames.push('+fg-slate');
  }

  const classNameString = classNames.join(' ');

  if (value.type === 'heading') {
    return generateElement(headingLegend[`heading_${value.attrs.level}`], classNameString, index, content);
  }
  if (value.type === 'text') {
    const isHref = value.marks?.some((mark: KeyValue) => !!mark?.attrs?.href);
    if (isHref) {
      const link = value.marks?.filter((mark: KeyValue) => !!mark?.attrs?.href)[0].attrs;
      return generateElement(textLegend['link'], `${classNameString} +fg-blue`, index, value.text, link);
    }

    return generateElement(textLegend['span'], classNameString, index, value.text);
  }
  return generateElement(markupLegend[value.type], classNameString, index, content);
}

export const createHTML = (content: any): JSX.Element => {
  const childList = Object.entries(content).map((item, index) => {
    return formatContent(item, index);
  });

  return <div className="free-form +type-body-one">{childList}</div>;
}
