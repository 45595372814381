import React from "react";
import { useParams } from "react-router-dom";
import { elementClicked } from "../../../../services/tracking";
import Button from "../../../Common/Button";
import ButtonGroup from "../../../Common/ButtonGroup";
import { CommonBaseProps } from "../../../Common/Types";
import { JourneySlugs } from "../../Types";
import "./DecisionButtonGroup.scss";

type DecisionButtonConfig = {
  text: string;
  next_subtask: string;
};

export type Props = {
  question: string;
  decision_buttons: DecisionButtonConfig[];
  onClick: (storyId: string) => void;
  skipOnClick?: () => void;
  skipText?: string;
  isSkippable?: boolean;
  continueText?: string;
  stacked?: boolean;
} & CommonBaseProps;

const DecisionButtonGroup: React.FC<Props> = (props: Props) => {
  const { className = "", question, decision_buttons, onClick, skipOnClick, skipText, continueText = "Continue", stacked} = props;
  const slugs = useParams<JourneySlugs>();
  const onClickButton = (storyblokId: string, buttonText: string) => () => {
    elementClicked({
      elementType: 'Button',
      htmlId: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}_${slugs.subtaskSlug}_${buttonText}`,
      location: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}`,
      position: slugs.journeySlug,
      text: buttonText,
      outboundUrl: '',
      actionOutcome: `next sub task`
    });
    onClick(storyblokId)
  };
  const [firstDecisionButton, ...tail1] = decision_buttons;
  const [secondDecisionButton, ...tail2] = tail1;
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [subtextDecisionButton, ..._temp] = tail2;


  //  Renders a single "decision" tree by emulating the button group
  if (decision_buttons.length === 1 || stacked) {
    return <ButtonGroup 
      continueText={continueText} 
      continueOnClick={onClickButton(decision_buttons[0].next_subtask, 'Continue')}
      skipOnClick={stacked && decision_buttons[1] ? onClickButton(decision_buttons[1].next_subtask, 'Skip_for_now') : skipOnClick}
      skipText={skipText} >
    </ButtonGroup>;
  }
  
  return (
    <div
      className={`input-tile form-element-container +display-flex +flex-direction-column +align-items-center +text-center ${className}`}
    >
      <h4 className="+type-heading-four +mg-vertical-md">{question}</h4>
      <div className="+display-flex +flex-direction-row +justify-content-space-between +width-full +mg-bottom-md +pd-horizontal-md">
        {[firstDecisionButton, secondDecisionButton].map((d, i) =>
          d ? (
            <Button
              key={`decision-button-${i}`}
              className="--neutral +flex-1 +mg-right"
              onClick={onClickButton(d.next_subtask, d.text)}
            >
              {d.text}
            </Button>
          ) : (
            <></>
          )
        )}
      </div>
      {subtextDecisionButton ? (
        <p
          className="decision-button-group__subheading +type-body-one +cursor-pointer"
          onClick={onClickButton(subtextDecisionButton.next_subtask, subtextDecisionButton.text)}
        >
          {subtextDecisionButton.text}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DecisionButtonGroup;
