import React, { useEffect } from 'react';

type Props = {
  type: "text/javascript";
  elementType: "script";
  isAsync: boolean;
  id?: string;
  externalSource?: URL;
  text?: string;
  children?: JSX.Element | JSX.Element[];
  onLoad?: string;
}

const ExternalResourceContainer: React.FC<Props> = (props: Props) => {
  const { externalSource, type, elementType, text, id, isAsync, children, onLoad } = props;

  useEffect(() => {
    const widgetLoaderScript = document.createElement(elementType);
    widgetLoaderScript.type = type;

    if (isAsync) {
      widgetLoaderScript.setAttribute("async", "");
    }

    if (id) {
      widgetLoaderScript.id = id;
    }

    if (externalSource) {
      widgetLoaderScript.src = externalSource.toString();
    }

    if (text) {
      widgetLoaderScript.text = text;
    }

    if (onLoad) {
      widgetLoaderScript.setAttribute("onload", onLoad);
    }

    document.body.appendChild(widgetLoaderScript);
    
    return () => {
      document.body.removeChild(widgetLoaderScript);
    };
  }, []);

  return (
    <div className="+width-full">
      {children}
    </div>
  )
};

export default ExternalResourceContainer;