import { ComponentDictionary } from '../Types';
import HomeAffordabilityCalculator from './HomeAffordabilityCalculator';
import HomeInsuranceIntegration from './HomeInsuranceIntegration';
import MortgageLeadWidget from './MortgageLeadWidget';
import MyMoveQuoteWidget from './MyMoveQuoteWidget';
import AllConnectBroadbandWidget from './AllConnectBroadbandWidget';

const IntegrationDictionary: ComponentDictionary = {
  home_affordability_calculator: { component: HomeAffordabilityCalculator, subtaskWeight: 2 },
  home_insurance_quotes: { component: HomeInsuranceIntegration },
  mortgage_embedded_lead_form: { component: MortgageLeadWidget },
  my_move_quote_comparison: { component: MyMoveQuoteWidget },
  all_connect_broadband: { component: AllConnectBroadbandWidget },
};

export {
  IntegrationDictionary,
  HomeAffordabilityCalculator,
  HomeInsuranceIntegration,
  MortgageLeadWidget,
  MyMoveQuoteWidget,
  AllConnectBroadbandWidget,
}
