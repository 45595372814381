import { markSubtasksRequired } from "../../../../store/journey/actions";
import { KeyValue } from "../../../Common/Types";
import { ActionStatus, Subtask, SubtaskRequiredUpdate } from "../../Types";

export type SubtaskSummary = {
    subtask: Subtask;
    slug: string;
    index: number;
    storyblok_id: string;
    prevSlug?: string;
    nextSlug?: string;
    rootIndex?: number;
    order?: number;
    lastInBranch: boolean;
    isLeaf: boolean;
    isTreeHead: boolean;
    required: boolean;
    nodeId: string;
};

export type  StoryblokDictionary<DictionaryType> = {
  [storyblok_id: string]: DictionaryType
};

export type SubtaskSummaryDictionary = StoryblokDictionary<SubtaskSummary>;
export type ActionStatusDictionary = StoryblokDictionary<ActionStatus>;
/**
 * Recusrive function that sets order of a node and its children if there are any
 * @param subtaskSummaryDictionary 
 * @returns 
 */
export const connectLeafSubtasks = (subtaskSummaryDictionary: SubtaskSummaryDictionary) => (subtaskSummary: SubtaskSummary, order: number, prev: SubtaskSummary | null, lastRootNode?: SubtaskSummary): number  =>{
  const { subtask } = subtaskSummary;
  if (!subtask) return order;

  let nextInOrder = order + 1; 
  const previousNode = subtaskSummary.isLeaf ? prev : lastRootNode;
  subtaskSummary.order = order;       
  subtaskSummary.nodeId = previousNode ? `${previousNode?.nodeId}.${subtaskSummary.slug}` : subtaskSummary.slug;
  const prevRootNode = subtaskSummary.isLeaf ? lastRootNode: subtaskSummary;

  //  If a parent of child nodes
  if (Array.isArray(subtask.decisionGroup) && subtask.decisionGroup.length) {
      subtaskSummary.lastInBranch = false;

      if (!subtaskSummary.isLeaf) {
        subtaskSummary.isTreeHead = true;
      }

      subtask.decisionGroup[0].decision_buttons.forEach(({ next_subtask }) => {
          if (!next_subtask) return;
        
          const nextSubtaskSummary = subtaskSummaryDictionary[next_subtask];

          if (!nextSubtaskSummary) return;

          nextSubtaskSummary.prevSlug = subtaskSummary.slug;
          nextSubtaskSummary.isLeaf = true;
          const travelDepth = connectLeafSubtasks(subtaskSummaryDictionary)(nextSubtaskSummary, order + 1, subtaskSummary, prevRootNode); 
          nextInOrder = nextInOrder > travelDepth ? nextInOrder : travelDepth; 
      });
  }
  
  //  Set the slug if it is not already set
  if (!subtaskSummary.prevSlug && prev) {
    subtaskSummary.prevSlug = subtaskSummary.isLeaf ? prev.slug : lastRootNode?.slug;
  }

  return nextInOrder;
}
/**
 * Returns both dictionary hash and array list for subtask sumarries
 */
export function getSubtaskSummaryDictionaryList (subtasks: Subtask[]): { subtaskSummaryList: SubtaskSummary[], subtaskSummaryDictionary: SubtaskSummaryDictionary} {
  const subtaskSummaryDictionary: KeyValue = {};

  //  Iterate over all the subtasks and create the subtaskSummaryDictionary
  const subtaskSummaryList: SubtaskSummary[] = subtasks.map((subtask, i) => {
    const subtaskSummary = {
      subtask,
      slug: subtask.slug,
      storyblok_id: subtask.storyblok_id,
      index: i,
      lastInBranch: true,
      isLeaf: false,
      required: typeof subtask.required !== 'undefined' ? subtask.required : true,
      nodeId: subtask.slug,
      isTreeHead: false,
    };
    subtaskSummaryDictionary[subtask.storyblok_id] = subtaskSummary;
    return subtaskSummary;
  });
  return {
    subtaskSummaryDictionary,
    subtaskSummaryList
  };
}
// Generate SubtaskSummaries from Subtasks
export function generateSubtaskSummaries (subtasks: Subtask[]): SubtaskSummary[] {
    const { subtaskSummaryDictionary, subtaskSummaryList } = getSubtaskSummaryDictionaryList(subtasks);
    const connectLeaves = connectLeafSubtasks(subtaskSummaryDictionary);

    //  Tree Depth of the node
    let order = 0;
    let lastRootNode: SubtaskSummary | undefined;
    //  update the order and connect leaves if there are any on the node
    subtaskSummaryList.forEach((subtaskSummary, i, all) => {
      const prev = i !== 0 ? all[i-1] : null;
      if (typeof subtaskSummary.order === 'number') return;
      lastRootNode = prev && !prev.isLeaf ? prev : lastRootNode;
   
      const newOrder = connectLeaves(subtaskSummary, order, prev, lastRootNode);
      lastRootNode = subtaskSummary.isLeaf ? lastRootNode : subtaskSummary;
      order = newOrder;
    });

    let lastCursor = subtaskSummaryList.length - 1;
    let iterationCursor = subtaskSummaryList.length - 1;

    //  Chains the non leaf nodes together
    while (iterationCursor >= 0) {
      const subtaskSummary = subtaskSummaryList[iterationCursor];
      const lastCursorSummary = subtaskSummaryList[lastCursor];

      //  Move on if the iteration was the last cursor checked
      if (!subtaskSummary || lastCursor === iterationCursor) {
        iterationCursor--;
        continue;
      }
  
      //  Move down both cursors if there is no last cursor or it is a leaf
      if (!lastCursorSummary || lastCursorSummary.isLeaf) {
        lastCursor--;
        iterationCursor--;
        continue;
      }

      subtaskSummary.nextSlug = lastCursorSummary.slug;

      //  Set the right cursor to be the current one if its not a leaf
      if (!subtaskSummary.isLeaf) {
        lastCursor = iterationCursor;
      }
      iterationCursor--;
    }
    return subtaskSummaryList;
}
/**
 * A list of nodeIds from a given node path
 * @returns 
 */
export function reduceNodePathToIds (nodes: string[]): string[] {
  return nodes.reduce((pathList, next) => {
    const last = pathList.length ? `${pathList[pathList.length - 1]}.${next}` : next;
    pathList.push(last);
    return pathList;
  }, [] as string[]);
}

/**
 * Looks for the subtask which was most recently completed
 * @param subtaskSummaries 
 * @param actionStatusDictionary 
 * @param defaultSummary The summary to fall back on if the reduce doesnt find any subtasks that have actionStatuses
 * @returns 
 */
export function getLastCompletedSubtask (subtaskSummaries: SubtaskSummary[], actionStatusDictionary: ActionStatusDictionary, defaultSummary: SubtaskSummary ): SubtaskSummary {
  return subtaskSummaries.reduce((latestTask: SubtaskSummary, nextTask) => {
    const storyblok_id = latestTask?.storyblok_id || '';
    const statusToCompare = actionStatusDictionary[nextTask.storyblok_id];
    const latestTaskStatus = actionStatusDictionary[storyblok_id];
    
    if (!statusToCompare || !latestTaskStatus) return latestTask;
    if (!latestTask  && statusToCompare && statusToCompare.completed_at) return nextTask;

    if (!statusToCompare.completed_at) return latestTask;
    if (!latestTaskStatus || !latestTaskStatus.completed_at) return nextTask;

    return statusToCompare.completed_at > latestTaskStatus.completed_at ? nextTask : latestTask;
  }, defaultSummary);
}
// TODO: Could be extracted into a global reducer, but need to talk about reusing tasks.
/** 
 * Finds the latest action that was completed by a user and performs the path update
**/
export function updateSubtaskSummaryDecisionTree (subtaskSummaries: SubtaskSummary[], actionStatusDictionary: KeyValue, head: SubtaskSummary, currentSubtaskSummary: SubtaskSummary): void {
    // Gets the leaves of the branch
    const summariesToCheck = subtaskSummaries.filter(item => item.nodeId.includes(head.nodeId) && item.isLeaf);

    const latestTaskCompleted = getLastCompletedSubtask(subtaskSummaries, actionStatusDictionary, currentSubtaskSummary);

    const nodesToMarkTrue = latestTaskCompleted.nodeId.split('.');
    const nodePathList = reduceNodePathToIds(nodesToMarkTrue);

    const nodesToMark = summariesToCheck.map((subtaskSummary) => {
      //  Any path node previous to the nodePath and any leaves below the selected node
      const isRequired = subtaskSummary.nodeId.includes(latestTaskCompleted.nodeId) || nodePathList.includes(subtaskSummary.nodeId)

      return {
        storyblok_id: subtaskSummary.storyblok_id,
        isRequired: !!isRequired,
        slug: subtaskSummary.slug
      } as SubtaskRequiredUpdate;
    });

    markSubtasksRequired(nodesToMark);
} 