import React from 'react';
import './ProgressBar.scss';
import { CommonBaseProps } from '../../../components/Common/Types';

type Props = {
  percentage?: number;
} & CommonBaseProps;

const ProgressBar: React.FC<Props> = (props : Props) => {
  const { percentage = 0, className = '', dataTestId = 'progress-bar' } = props;
  const styledPercentage = `${percentage || 0}%`;
  return (
    <div className={`progress-bar ${className}`} data-testid={dataTestId}>
      <div className="progress-bar__fill" style={{ 'width': styledPercentage }} />
    </div>
  )
};

export default ProgressBar;
