import React from 'react';
import { useParams } from 'react-router-dom';
import { elementClicked } from '../../services/tracking';
import ExternalResourceContainer from '../Common/ExternalResourceContainer';
import { JourneySlugs } from '../Journeys/Types';

type Props = {
  ctaCopy?: string;
  className?: string;
}

const MortgageLeadForm: React.FC<Props> = ({ ctaCopy = '', className = '' }: Props) => {
  const slugs = useParams<JourneySlugs>();
  const id = 'lead-form-trigger';
  const elfUrl = process.env.REACT_APP_ELF_URL as string;

  const loadWidget = () => {
    elementClicked({
      elementType: 'Button',
      htmlId: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}_${slugs.subtaskSlug}_Find_rates`,
      location: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}`,
      position: slugs.journeySlug,
      text: 'Find_rates',
      outboundUrl: '',
      actionOutcome: `mortgage lead form`
    });
    window.postMessage(
      {
        triggerId: id,
        name: 'SHOW_EMBEDDED_FORM',
        formUrl: 'https://mortgage.bankrate.com',
      },
      '*'
    )
  }

  return (
    <ExternalResourceContainer externalSource={new URL(elfUrl)} type="text/javascript" elementType="script" isAsync={true}>
      <button id={id} onClick={loadWidget} className={`${className} button --primary `}> {ctaCopy ? ctaCopy : 'Continue'}</button>
    </ExternalResourceContainer>
  )
};

export default MortgageLeadForm
