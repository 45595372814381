import React from 'react';
import './Overlay.scss'

interface Props {
  onClick?: () => void;
  blur?: boolean
}

const Overlay: React.FC<Props> = ({ onClick, blur = false }: Props) => {
  const blurClass = blur ? 'overlay-cover--blur' : '';
  return (
    <div className={`overlay-cover ${blurClass}`} onClick={onClick}></div>
  )
};

export default Overlay;
