import React, { useEffect, useRef, useState } from 'react';
import ExternalResourceContainer from "../Common/ExternalResourceContainer";
import WidgetError from './WidgetError';
import useLoadMyFinance from './useLoadMyFinance';
import './Widgets.scss';

const MyMoveWidget: React.FC = () => {
  const widgetSource = 'https://static.myfinance.com/widget/inlineMedia_core.js';
  const ref = useRef(null);
  const el = document.querySelector('[data-creative-set-id="9bbe9a3e-5166-419d-8bd6-cd8d494cf170"]');
  useLoadMyFinance(el, ref);

  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const loadError = () => {
      setHasError(true);
    };
    const timeout = setTimeout(loadError, 15000);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventListener = (e: any) => {
      const { ad } = e?.detail || {};

      if (ad) {
        clearTimeout(timeout);
        setIsLoaded(true);
      }
    };
    window.addEventListener('inline.widgetloadstart', eventListener);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('inline.widgetloadstart', eventListener)
    };
  }, []);

  return (
    <div ref={ref}>
      <ExternalResourceContainer
        id='myFinance-widget-script'
        externalSource={new URL(widgetSource)}
        isAsync={true}
        elementType='script'
        type='text/javascript'
      >
        {!isLoaded && !hasError ? <div className='+width-full shimmer-box +mg-bottom-md --my-move'></div> : <></>}
        {hasError ? <WidgetError /> : <div className="myFinance-widget external-widget" data-creative-set-id="9bbe9a3e-5166-419d-8bd6-cd8d494cf170" data-campaign="kue-movers-widget"></div>}
      </ExternalResourceContainer>
    </div>
  )
}

export default MyMoveWidget;
