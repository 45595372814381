import React from 'react';
import { Link } from 'react-router-dom';
import AngleBracket from '../../../../assets/AngleBracket';
import { CommonBaseProps } from '../../../Common/Types';
import "./BackButton.scss";

type Props = {
  url: string;
} & CommonBaseProps;
  
const BackButton: React.FC<Props> = (props: Props) => {
  const { url, className } = props;

  return (
    <Link
      className={`journey-back-button ${className} button --extra-small --flat +fg-white +pd-vertical-sm +pd-horizontal +mg-left +mg-top`}
      to={url}
    >
      <AngleBracket
        className="+mg-right-sm"
        fill="#0157FF"
        direction="left"
      />
      Back
    </Link>
  )
}

export default BackButton;