import React, { useState } from 'react';
import { useEffect } from 'react';
import './VerticalProgressBar.scss';
import JourneyNode from '../JourneyNode';

export type ProgressBarNode = {
  isCompleted: boolean;
  ref: React.MutableRefObject<null | HTMLLIElement>, 
  onClick: () => void;
  styles?: React.CSSProperties;
}

type Props = {
  className?: string;
  nodeSize?: number;
  dataTestId?: string;
  nodeList: ProgressBarNode[]
}

const ProgressBar: React.FC<Props> = ({ className, nodeList, nodeSize = 32, dataTestId="" }: Props) => {
  const [progressNodes, setProgressNodes] = useState<JSX.Element[]>([]);
  const [progressNodeLinks, setProgressNodeLinks] = useState<JSX.Element[]>([]);
 
  const computeDistanceFromParent = (ref: React.MutableRefObject<null | HTMLLIElement>) => {
    if (!ref || !ref.current) return 0;
    const parentNodePosition = ref.current.parentElement?.getBoundingClientRect().top || 0
    const nodePosition = ref.current.getBoundingClientRect().top;
    const nodeHeight = ref.current.offsetHeight;
    return Math.round((nodePosition - parentNodePosition) + (nodeHeight / 2));
  }

  const createProgressNode = (val: number, topOffset: number, isCompleted: boolean, onClick: () => void, styles: React.CSSProperties) => (
    <JourneyNode size={nodeSize} value={val} isCompleted={isCompleted} topOffset={topOffset} onClick={onClick} styles={styles}/>
  );

  const createProgressNodeLink = (height: number, topOffset: number, index: number) => (
    <svg viewBox={`0 0 3 ${height}`} width={3} style={{transform: `translate(${(nodeSize/ 2) - 1}px, ${topOffset}px)`}} key={index} height={height} className="+pos-absolute vertical-progress-bar__node-link">
      <line x1="1" y1="0" x2="1" y2={height} strokeWidth={3} width={3} height={height}/>
    </svg>
  );

  useEffect(() => {
    const nodeLinksData: {from: number | undefined; to: number | undefined}[] = [];

    setProgressNodes(nodeList.map((node, index) => {
      const { styles = {} } = node;

      const nodePosition = computeDistanceFromParent(node.ref);
      if (index === 0) {
        nodeLinksData.push({
          from: nodePosition, 
          to: undefined   
        });
      } else {
        nodeLinksData[nodeLinksData.length - 1].to = nodePosition;
        nodeLinksData.push({
          from: nodePosition, 
          to: undefined   
        })
      }

      return createProgressNode(index + 1, nodePosition - (nodeSize / 2), node.isCompleted, node.onClick, styles) 
    }));

    if (nodeList.length <= 1) {
      setProgressNodeLinks([]);
    } else {
      setProgressNodeLinks(
        nodeLinksData.filter(link => !!link.to && !!link.from).map((link, index) => {
          if (!link.to || !link.from) return <></>;

          return createProgressNodeLink(link.to - link.from, link.from, index);

        })
      )
    }

  }, [nodeList])


  return (
    <div data-testid={dataTestId} className={`vertical-progress-bar +display-flex +flex-direction-column ${className}`} aria-hidden={true}>
      {progressNodes}
      {progressNodeLinks}
    </div>
  )
}

export default ProgressBar;