import React from 'react';
import './ContentTile.scss';
import { CommonBaseProps } from '../../../Common/Types';

type Props = {
  children: JSX.Element | JSX.Element[]
} & CommonBaseProps;

const ContentTile: React.FC<Props> = (props: Props) => {
  const { className='', dataTestId, children } = props;
  return (
    <div
      data-testid={dataTestId}
      className={`content-tile ${className}`}
    >
      { children}
    </div>
  )
}

export default ContentTile;

