export const formatCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatAccountString = (type: string | null): string | null => {
  if (!type) {
    return null;
  }
  const lower = type.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const maskAccountNumber = (accountNumber?: string | null, numVisible = 4, numMasked = 3): string => {
  if (!accountNumber) {
    return 'N/A';
  }
  const numLength = accountNumber.length;
  const lastNVisible = numLength > numVisible ? accountNumber.slice(numLength - numVisible, numLength) : accountNumber;
  // generate numMasked dots for the mask
  const mask = [...Array(numMasked).keys()].map(_ => '.').join('');

  return `${mask}${lastNVisible}`;
}

export const formatDate = (d: string | null, full?: boolean): string | null => {
  if (d) {
    const date = new Date(d);

    return date.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: full ? 'numeric' : undefined,
    });
  }

  return null;
};

export const shortenMonth = (m: string): string | undefined => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  return months.find((mon) => m?.includes(mon));
};

export const formatTracking = (word: string): string => {
  return word.split(' ').map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join('_');
}

export const validateDate = (date: string): boolean => {
  const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return dateRegex.test(date);
}

export const validatePhone = (phone: string): boolean => {
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
  return phoneRegex.test(phone);
}

export const validateZip = (zipCode: string): boolean => {
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return zipRegex.test(zipCode);
}

export const clamp = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);
