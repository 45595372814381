/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Button from "../../../Common/Button";
import ButtonGroup from "../../../Common/ButtonGroup";
import {
  InputTileResponse,
  InputValueDictionary,
  SelectFieldResponse,
  SubtaskType,
} from "../../ActionItems/Types";
import { findJourneyUserState, getSkipProps, getSlugsByStoryblokId } from "../../helpers";
import { ActionItem, BaseSubtaskProps, JourneySlugs, Subtask } from "../../Types";
import TrayModal from "../TrayModal";
import "./Subtask.scss";
import {
  getDynamicComponent,
  getFilteredEntries,
  getUserDataFromInputValues,
  isValidInputList,
  SubtaskConfiguration,
} from "./helpers";
import { updateJourneyUserState } from "../../../../store/actions";
import { useParams } from "react-router-dom";
import DecisionButtonGroup from "../../ActionItems/DecisionButtonGroup";
import { elementClicked } from "../../../../services/tracking";

interface Props extends BaseSubtaskProps {
  title: string;
  subtask?: Subtask;
  description: string;
  isSkippable: boolean;
  children?: JSX.Element | JSX.Element[];
  actionItems?: ActionItem[];
  continueText?: string;
  type?: SubtaskType;
  onCompleteDecision?: (startDate: string, slugs: JourneySlugs) => void;
}
/**
 * SubtaskComponent that renders based on the subtask type from SB
 * @param props
 * @returns
 */
const SubtaskComponent: React.FC<Props> = (props: Props) => {
  const {
    title,
    description,
    isSkippable,
    children,
    subtask,
    onComplete,
    onSkip,
    actionItems = [],
    continueText,
    type = SubtaskType.DYNAMIC,
    onCompleteDecision
  } = props;
  const { skipHeadingText, skipBodyText, skipButtonText } = getSkipProps(props);
  const slugs = useParams<JourneySlugs>();
  //  Setup State Data
  const userData = useSelector((state: RootState) => state.journey.userData);
  const continueCtaText = continueText ? continueText : subtask?.continueCtaText || "Continue";
  // Setup Component State
  const { journeySlug } = useParams<JourneySlugs>();
  const userStateJourneys = useSelector(
    (state: RootState) => state.userState.journeys
  );
  const getStoryblokSlugs = useSelector((state: RootState) => getSlugsByStoryblokId(state));
  const journeyUserState = findJourneyUserState(userStateJourneys, journeySlug);
  const [isSkipActive, setIsSkipActive] = useState(false);
  const [inputDictionary, setInputValues] = useState(
    {} as InputValueDictionary
  );
  const [isSubmitted, setSubmitted] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [isInitialized, setIsInitialized] = useState(false);
  
  const initComponent = () => {
    if (actionItems.length && userData) {
      const inputDictionary: InputValueDictionary = {};
      actionItems.forEach((item) => {
        const allInputs = item as InputTileResponse;

        // These action items are not Input Tiles
        if (!allInputs.inputs) return;

        allInputs.inputs.forEach((input) => {
          const { field_name } = input;

          if (!field_name) return;
          const currentUserData = userData[field_name];

          const currentValue = currentUserData ? currentUserData.value : "";

          // Set input dictionary for input with user data value
          inputDictionary[field_name] = {
            field_name,
            value: currentValue,
            required: !!input.required,
          };

          // Set value for select input types
          const selectInput = input as SelectFieldResponse;
          if (selectInput.options) {
            inputDictionary[field_name].value = currentValue || selectInput.options[0].value;
          }
        });
      });

      setInputValues(inputDictionary);
      setIsInitialized(true);
      setSubmitted(false);
    }
  };
  //  Initializes the Inputs with User Data and Fields based on the content loaded from SB
  useEffect(() => {
    if (actionItems.length && userData && !isInitialized) {
      initComponent();
    }
  }, [actionItems, userData]);

  // INPUT CHANGE HANDLER
  const onInputChangeHandler = (fieldName: string, value: string) => {
    const fieldState = inputDictionary[fieldName];
    setInputValues({
      ...inputDictionary,
      [fieldName]: { ...fieldState, field_name: fieldName, value },
    });
  };

  //  ON SELECTED ITEM HANDLER
  const onItemSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = event.target;
    onInputChangeHandler(name, value);
  };

  // WHEN SUBTASK CONTINUE BUTTON IS CLICKED
  const onSubtaskContinue = () => {
    elementClicked({
      elementType: 'Button',
      htmlId: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}_${slugs.subtaskSlug}_Continue`,
      location: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}`,
      position: slugs.journeySlug,
      text: 'Continue',
      outboundUrl: '',
      actionOutcome: `next sub task`
    });
    //  Handle Dynamic Storblok content if there are inputs
    if (type === SubtaskType.DYNAMIC && Object.keys(inputDictionary).length) {
      onFormSubmit();
      return;
    }

    onComplete(startDate);
  };

  // SKIPPING
  const onFirstSkipClick = () => {
    updateJourneyUserState({
      ...journeyUserState,
      skipped: true,
    });
    setIsSkipActive(true);
  };

  const skippableProps = isSkippable
    ? {
        skipText: subtask?.skipCtaText || "Skip for now",
        skipOnClick: journeyUserState.skipped
          ? onSkip || onSubtaskContinue
          : onFirstSkipClick,
      }
    : {};

  /**
   * Validates and Submits the dynamic inputs
   */
  const onFormSubmit = () => {
    setSubmitted(true);
    const inputValues = getFilteredEntries(inputDictionary);
    const isValid = isValidInputList(inputValues);

    if (!isValid) return;

    const fields = getUserDataFromInputValues(inputValues);
    onComplete(startDate, fields);
  };

  const subtaskConfiguration: SubtaskConfiguration = {
    onInputChangeHandler,
    onItemSelected,
    isSubmitted,
    inputDictionary,
    onSkip,
    isSkippable,
    title
  };

  //  Get the Renderable Components w/ current Configuration
  const components = actionItems.map((actionItem, index) =>
    getDynamicComponent(actionItem, subtaskConfiguration, `action-item-${index}`)
  );

  const skipModal = (
    <TrayModal onOverlayClick={() => setIsSkipActive(false)}>
      <div className="+text-center">
        <h4 className="+type-heading-four +mg-bottom">{skipHeadingText}</h4>
        <p className="+type-body-one +fg-slate">{skipBodyText}</p>
        <Button
          className="--primary --small"
          onClick={onSkip || onSubtaskContinue}
        >
          {skipButtonText}
        </Button>
      </div>
    </TrayModal>
  );
  const buttonGroupStyle = isSkippable ? "+mg-bottom-lg--sm" : "+mg-bottom-lg";

  // if the decisionGroup has length
  const decisionGroup = subtask ? subtask.decisionGroup[0] : null;
  const renderDefaultNavigation = (
    <>
      <ButtonGroup
        continueText={continueCtaText}
        className={`+pd-top ${buttonGroupStyle}`}
        continueOnClick={onSubtaskContinue}
        {...skippableProps}
      />
      {isSkippable && isSkipActive && skipModal}
    </>
  );

  //  When one of the decisions is clicked, tell the parent the storyblok ref
  const onDecisionButtonClick = (storyblokId: string) => {
    const slugs = getStoryblokSlugs(storyblokId);
    if (!onCompleteDecision) return;

    onCompleteDecision(startDate, slugs);
    return;
  };

  const decisionGroupButtons = decisionGroup ? (
    <DecisionButtonGroup {...decisionGroup} 
      {...skippableProps} 
      onClick={onDecisionButtonClick} 
      continueText={continueCtaText} />
  ) : (
    renderDefaultNavigation
  );

  return (
    <div className="journey-subtask">
      <h3 className="+type-heading-three +mg-bottom journey-subtask__heading">
        {title}
      </h3>
      <p className="+type-body-one journey-subtask__description +fg-slate">{description}</p>
      <div className="+pd-top">
        {components}
        {children}
      </div>
      {decisionGroupButtons}
    </div>
  );
};

export default SubtaskComponent;
