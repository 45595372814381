import React from 'react';

const EmailIcon: React.FC = () => {
  return (
    <div>
      <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
        <defs>
          <path id="path-1" d="M0,28 L0,0 L36,0 L36,12 L32.108,12 L32.108,18 L36,18 L36,28 L0,28 Z" />
        </defs>
        <g id="email-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" transform="translate(6.000000, 10.000000)">
            <g id="Rectangle">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Mask" />
              <rect stroke="#151515" strokeWidth="2" mask="url(#mask-2)" x="1" y="1" width="34" height="26" />
            </g>
            <polyline id="Path-5" stroke="#151515" strokeWidth="2" points="1 1 18 11 35 1" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default EmailIcon;
