import React from 'react';

interface Props {
    className?: string
} 
const VideoPlayIcon: React.FC<Props> = (props: Props) => {
    const {className} = props;
    return (
        <svg width="25px" height="25px" className={`${className}`} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-778.000000, -389.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g transform="translate(572.000000, 270.000000)">
                        <g transform="translate(39.000000, 119.000000)">
                            <g transform="translate(167.000000, 0.000000)">
                                <polygon points="19.845 36.39 38.21 25.14 19.845 13.89"></polygon>
                                <path d="M25,0 C11.1928813,0 0,11.1928813 0,25 C0,38.8071187 11.1928813,50 25,50 C38.8071187,50 50,38.8071187 50,25 C50,18.3695878 47.366079,12.0107399 42.6776695,7.32233047 C37.9892601,2.63392101 31.6304122,0 25,0 Z M25,46.5 C13.1258779,46.5 3.5,36.8741221 3.5,25 C3.5,13.1258779 13.1258779,3.5 25,3.5 C36.8741221,3.5 46.5,13.1258779 46.5,25 C46.5,36.8741221 36.8741221,46.5 25,46.5 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default VideoPlayIcon;