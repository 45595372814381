import React from 'react';
import SubtaskComponent from '../../Components/Subtask';
import { IntegrationProps } from '../Types';
import MortgageLeadForm from '../../../Widgets/MortgageLeadForm';
import ContentTile from '../../Components/ContentTile';
import HouseSearch from '../../../../assets/journey_svgs/HouseSearch';

const MortgageLeadWidget: React.FC<IntegrationProps> = (props: IntegrationProps) => {
  const { onComplete, title, description, tile } = props;

  return (
    <SubtaskComponent
      title={title}
      description={description}
      isSkippable={false}
      onComplete={onComplete}>
      <ContentTile className="content-tile__integration">
        <HouseSearch />
        <h4 className="+type-heading-seven +mg-top-md +mg-bottom">{tile?.heading_copy}</h4>
        <p className="+type-body-one +fg-slate content-tile__integration-body +mg-bottom-md">{tile?.body_copy}</p>
        <MortgageLeadForm ctaCopy={tile?.cta_copy} className="+style-bold +type-body-one" />
      </ContentTile>
      <p className="+fg-slate +mg-top +text-center +type-body-one">If you&apos;d rather skip this for now, hit “continue” below.</p>
    </SubtaskComponent>
  )
}

export default MortgageLeadWidget;
