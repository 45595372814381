import React from 'react';
import Button from "../../../../Common/Button";
import TrayModal from "../../../Components/TrayModal";

type Props = {
  onClose: () => void;
  message: string;
};

const ErrorModal: React.FC<Props> = (props: Props) => {
  const { onClose, message } = props;

  return <TrayModal onOverlayClick={onClose}>
    <div className="+text-center hac-slider__info-tray +fg-slate +type-body-one">
      <h4 className="+type-heading-four +mg-bottom">Oops! Let&apos;s fix this.</h4>
      <p className="+mg-bottom">{message}</p>
      <Button className="--primary --small +mg-top-ml" onClick={ onClose }>Adjust my answers</Button>
    </div>
  </TrayModal>;
};

export default ErrorModal;
