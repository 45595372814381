import React from "react";
import ButtonComponent from "../Button";
import { CommonBaseProps } from "../Types";
import './ButtonGroup.scss';
type Props = {
    continueText: string;
    continueOnClick: () => void;
    skipOnClick?: () => void;
    skipText?: string;
} & CommonBaseProps;

const ButtonGroup: React.FC<Props> = (props: Props) => {
  const {continueText, skipText, continueOnClick, skipOnClick, className = ''} = props;
  const secondAction = skipText ?
    <>
      <hr className="+mg-top-md +mg-bottom-none"/>
      <p className="+fg-slate +type-body-one --flat +mg-top +cursor-pointer" onClick={skipOnClick}>{skipText}</p> 
    </> : <></>;
  return (
    <div className={`button-group +display-flex +flex-direction-column +align-items-center ${className}`}>
        <ButtonComponent className="--primary --small group-button" onClick={continueOnClick}>{continueText}</ButtonComponent>
        {secondAction}
    </div>
  );
}

export default ButtonGroup;