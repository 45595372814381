import { DropdownOption } from "./InputTile/Select";
import { BaseSubtaskProps, ActionItem } from "../Types";
import { InputFormat } from "./InputTile";

export interface IntegrationProps extends BaseSubtaskProps {
  title: string;
  description: string;
  onProgress?: (progressSummary?: ProgressSummary) => void;
  subtaskUuid: string;
  taskUuid: string;
  isSubtaskComplete: boolean;
  tile?: {
    cta_copy: string;
    body_copy: string;
    heading_copy: string;
  } | undefined;
  currentSubtaskPage?: number;
  setCurrentSubtaskPage?: (page: number) => void;
}

export type ComponentDefinition = {
  component: React.FC<IntegrationProps>,
  subtaskWeight?: number;
};

export type ComponentDictionary = {
  [key: string]: ComponentDefinition
};

export type InputTileResponse = {
  headline: string;
  subcopy: string;
  inputs: (InputFieldResponse | SelectFieldResponse)[];
  skippable?: boolean;
  skippable_cta_text?: string;
} & ActionItem;

export type InputFieldResponse = {
  field_name: string;
  label?: string;
  required: boolean;
  type: InputFormat;
  placeholder?: string;
  inputValue: string;
  errorCopy: string;
} & ActionItem;

export type SelectFieldResponse = {
  field_name: string;
  label?: string;
  required: boolean;
  options: DropdownOption[],
  inputValue: string;
  errorCopy: string;
} & ActionItem;

export type DecisionButtonConfig = {
  next_subtask: string;
  text: string;
} & ActionItem;

export type DecisionButtonResponse = {
  decision_buttons: DecisionButtonConfig[];
  question: string;
} & ActionItem;

export type VideoPlayerResponse = {
  caption: string;
  video_url: string;
} & ActionItem;

export type InfoTileResponse = {
  heading: string;
  body: string;
} & ActionItem;

export enum SubtaskType {
  INTEGRATION,
  DYNAMIC
}

export type InputValue = {
  field_name: string;
  value: string;
  required: boolean;
}

export type InputValueDictionary = {
  [field_name: string]: InputValue
}

export type CreditScoreConfig = {
  readonly _uid: string;
  readonly apr: string;
  readonly blurb: string;
  readonly component: "credit-score-configuration";
  readonly monthly_payment: string;
  readonly total_interest_paid: string;
  readonly credit_range: string;
  readonly value: string;
}

export type ProgressSummary = {
    current: number;
    total: number;
    subtaskName: string;
};