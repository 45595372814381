import React from 'react';
import './_infocomponent.scss';
import { LightBulbWithRays } from "../../../assets/tile_svgs";

type Props = {
  heading: string;
  body: string,
  className?: string
  icon?: JSX.Element,
}

const InfoComponent: React.FC<Props> = ({ heading, body, icon = <LightBulbWithRays fill="#0157FF" width="32px" height="32px" />, className = '' }) => (
  <section className={`+display-flex +flex-direction-column info-component +pd-all +pd-vertical-ml +pos-relative ${className}`}>
    <span className="info-component__icon +pos-absolute +display-flex +justify-content-center +align-items-center">
      { icon }
    </span>
    <strong className="+type-body-one info-component__heading +fg-black +font-style-bold">
      { heading }
    </strong>
    <p className="+type-body-one +mg-all-none +pd-top +fg-slate">
      { body }
    </p>
  </section>
);

export default InfoComponent;
