import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import { findFirst } from 'fp-ts/lib/ReadonlyArray';
import React, { useState } from 'react';
import { CommonBaseProps } from '../../../Common/Types';
import Dropdown, { DropdownOption } from '../Dropdown';
import './DropdownControlledContainer.scss';

export type DropdownControlledContainerOption = {
  readonly option: DropdownOption;
  readonly component: JSX.Element;
}

export type DropdownControlledContainerProps = {
  readonly label: string;
  readonly options: readonly DropdownControlledContainerOption[];
  readonly defaultValue?: string;
} & CommonBaseProps;

const DropdownControlledContainer: React.FC<DropdownControlledContainerProps> = (props: DropdownControlledContainerProps) => {
  const { className = "", label, options, defaultValue = "" } = props;

  const initialContent = options.find((opt) => opt.option.value === defaultValue)?.option.value || '760-850';
  const [selectedContent, setSelectedContent] = useState<string>(initialContent);
  const handleSelectContent = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedContent(changeEvent.target.value.toString())
  }

  const selectedContentComponent = pipe(
    options,
    findFirst(o => o.option.value === selectedContent),
    O.map((a: DropdownControlledContainerOption) => a.component),
    O.toNullable
  )

  return (
    <div className={` ${className}`}>
      <Dropdown selected={defaultValue} label={label} options={options.map(o => o.option)} onChange={handleSelectContent} />
      {selectedContentComponent}
    </div>
  );
}

export default DropdownControlledContainer;