import React from 'react';
import './LoadingSpinner.css';
import LoadingSpinner from './LoadingSpinner';
import queryString from 'query-string';

type Props = {
  windowLocation: string;
  params?: { [key: string]: string }
}

const RedirectLoadingView: React.FC<Props> = (props: Props) => {
  // Get query params from URL, and grab the first item if multiples exist
  const params = queryString.parse(window.location.search);
  const normalizedParams = Object.keys(params).reduce((accum, currKey) => {
    accum[currKey.toLowerCase()] = params[currKey];
    return accum;
  }, params)
  const redirectTo = Array.isArray(normalizedParams['redirect-to']) ? normalizedParams['redirect-to'][0] : normalizedParams['redirect-to'];
  const isSignUp = Array.isArray(normalizedParams['issignup']) ? normalizedParams['issignup'][0] : normalizedParams['issignup'];
  const utmContent = Array.isArray(normalizedParams['utm_content']) ? normalizedParams['utm_content'][0] : normalizedParams['utm_content'];
  const utmSource = Array.isArray(normalizedParams['utm_source']) ? normalizedParams['utm_source'][0] : normalizedParams['utm_source'];
  const utmCampaign = Array.isArray(normalizedParams['utm_campaign']) ? normalizedParams['utm_campaign'][0] : normalizedParams['utm_campaign'];
  const stlnk = Array.isArray(normalizedParams['stlnk']) ? normalizedParams['stlnk'][0] : normalizedParams['stlnk'];
  const pid = Array.isArray(normalizedParams['pid']) ? normalizedParams['pid'][0] : normalizedParams['pid'];
  const userGoals = Array.isArray(normalizedParams['user_goals']) ? normalizedParams['user_goals'][0] : normalizedParams['user_goals'];

  // if the above query params exist, encode string and push to queryParams array
  const queryParams: string[] = [];

  if (redirectTo) {
    const encodeRedirectTo = encodeURIComponent(redirectTo);
    queryParams.push(`redirect-to=${encodeRedirectTo}`);
    queryParams.push(`return-to=${encodeRedirectTo}`);
  }

  if (isSignUp) {
    queryParams.push(`isSignUp=true`);
  }

  if (utmContent) {
    const encodeUtmContent = encodeURIComponent(utmContent);
    queryParams.push(`utm_content=${encodeUtmContent}`);
  }

  if (utmSource) {
    const encodeUtmSource = encodeURIComponent(utmSource);
    queryParams.push(`utm_source=${encodeUtmSource}`);
  }

  if (utmCampaign) {
    const encodeUtmCampaign = encodeURIComponent(utmCampaign);
    queryParams.push(`utm_campaign=${encodeUtmCampaign}`);
  }

  if (stlnk) {
    const encodeStlnk = encodeURIComponent(stlnk);
    queryParams.push(`stInk=${encodeStlnk}`);
  }

  if (pid) {
    const encodePid = encodeURIComponent(pid);
    queryParams.push(`pid=${encodePid}`);
  }

  if (userGoals) {
    const encodeUserGoals = encodeURIComponent(userGoals);
    queryParams.push(`user_goals=${encodeUserGoals}`);
  }

  if (props.params) {
    Object.keys(props.params).forEach(param => {
      const encodeParam = encodeURIComponent(param);
      const encodeValue =  props.params ? encodeURIComponent(props.params[param]) : '';
      queryParams.push(`${encodeParam}=${encodeValue}`);
    })
  }

  // concatenate query string and update window location
  const query = queryParams.length ? `?${queryParams.join(`&`)}` : '';
  window.location.href = `${props.windowLocation}${query}`;

  return (
    <div
      data-testid="redirect-loader"
      className="+pd-vertical-lg"
      style={{ margin: 'auto' }}
    >
      <LoadingSpinner width="150px" />
    </div>
  );
};

export default RedirectLoadingView;
