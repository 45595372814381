import React from 'react';
import ContentTile from '../Journeys/Components/ContentTile';
import JourneyError from '../../assets/journey_svgs/JourneyError';
const WidgetError: React.FC = () => {
  return (
    <>
      <ContentTile className="content-tile__integration">
        <JourneyError />
        <h4 className="+mg-top-md +mg-bottom">Oops! Something went wrong</h4>
        <p className="+type-body-one +fg-slate content-tile__integration-body +mg-bottom-md">You may need to disable pop-up blockers or exit incognito mode.</p>
      </ContentTile>
      <p className="+fg-slate +mg-top +text-center +type-body-one">If you&apos;d rather skip this for now, hit “continue” below.</p>
    </>
  )
}

export default WidgetError;
