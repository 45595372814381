import React, { useRef } from 'react';
import { ActionStatusSummary, JourneySlugs, Step, Task } from '../../Types';
import TrayContainer from '../../../Common/TrayContainer';
import './Step.scss';
import AngleBracket from '../../../../assets/AngleBracket';
import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { createTaskStatus, completeTaskStatus, uncompleteActionStatus } from '../../../../store/journey/actions';
import { getActionStatusesByStoryblokIds, getStatusSummary, buildJourneyUrl, formatTrackingText } from '../../helpers';
import VerticalProgressBar, { ProgressBarNode } from '../VerticalProgressBar';
import BackButton from '../BackButton';
import CompletionPage from '../CompletionPage';
import { elementClicked } from '../../../../services/tracking';

type Props = {
  onClick?: () => void;
  className?: string;
  dataTestId?: string;
  step: Step;
  journeySlug: string;
  onActionStatusComplete: (slugs: JourneySlugs) => void;
  showStepCompletion: boolean;
  setShowStepCompletion: (completion: boolean) => void;
}

const StepContainer: React.FC<Props> = ({ className = "", step, journeySlug, dataTestId = "", onActionStatusComplete, showStepCompletion, setShowStepCompletion }: Props) => {
  const { title, description, tasks, eyebrow_text, slug: stepSlug, illustration_config } = step;
  const taskActionStatuses = useSelector(getActionStatusesByStoryblokIds(tasks.map(task => task.storyblok_id)));
  const taskSummaryFunction = useSelector(getStatusSummary);
  const routerHistory = useHistory();
  /**
   * Checks if a given task is complete
   */
  const isTaskComplete = (taskStoryblokId: string) => !!taskActionStatuses.find(({ storyblok_id }) => storyblok_id === taskStoryblokId)?.completed_at;


  /*
  * Manually marks a task as complete or uncomplete depending on the state
  */
  const onTaskNodeClick = (
    stepSlug: string, 
    task: Task, 
    hasUncompleteSubtasks: boolean,
    isCompleted: boolean,
  ) => async () => {
    if (!isCompleted) {
      await createTaskStatus(stepSlug, task.slug);
      await completeTaskStatus(stepSlug, task.slug);
      onActionStatusComplete({ journeySlug, stepSlug, taskSlug: task.slug });
      return;
    }

    if (hasUncompleteSubtasks) {
      await uncompleteActionStatus(task.storyblok_id);
    }
  };

  const handleCompletionClick = () => {
    setShowStepCompletion(false);
    routerHistory.push(buildJourneyUrl({
      journeySlug: journeySlug,
    }));
    return;
  }

  // Initialize Progress Nodes
  const progressBarNodes: ProgressBarNode[] = [];

  /**
   * Task List Tile Creation
   */
  const taskList = tasks.map((task) => {
    const { title, description, storyblok_id, slug } = task;
    const taskSummary: ActionStatusSummary = taskSummaryFunction(task.subtasks);
    const isCompleted = isTaskComplete(storyblok_id);
    const hasUncompleteSubtasks = taskSummary.incomplete > 0;
    const progressBarNodeRef = useRef(null);
    const displayText = taskSummary.skipped ? "You've skipped some of this subtask" : description;

    progressBarNodes.push({ 
      ref: progressBarNodeRef, 
      isCompleted, 
      onClick: onTaskNodeClick(stepSlug, task, hasUncompleteSubtasks, isCompleted) ,
      styles: hasUncompleteSubtasks ? { cursor: "pointer"} : {}
    });

    const elementClickedData = {
      actionOutcome: "next sub task",
      outboundUrl: "",
      elementType: "Button",
      htmlId: `${formatTrackingText(journeySlug, '-')}_${step.title}_${title}`,
      location: `${formatTrackingText(journeySlug)}_Sub_Task_Screen_${step.title}`,
      position: formatTrackingText(journeySlug),
      text: `Your_${formatTrackingText(step.title)}`
    }
    return (
      <li key={storyblok_id} onClick={() => elementClicked(elementClickedData)} className="+mg-bottom" ref={progressBarNodeRef}>
        <NavLink onClick={() => createTaskStatus(step.slug, slug)} className="journey-step__task-container +width-full" to={buildJourneyUrl({ journeySlug: journeySlug, stepSlug: step.slug, taskSlug: slug })}>
          <TrayContainer borderOrientation="All" className={`+width-full journey-step__task ${isCompleted && 'journey-step__task--completed'}`}>
            <>
              <div className="+display-flex +justify-content-space-between +align-items-center +width-full">
                <div className="+text-left">
                  <h4 className="+mg-bottom-sm journey-step__task-heading">{title}</h4>
                  <p className="+mg-all-none journey-step__task-description">{isCompleted ? displayText : description}</p>
                </div>
                <AngleBracket direction="right" fill={`${isCompleted ? '#008071' : '#515260'}`} className="journey-step__task-icon" ariaHidden={true} />
              </div>
            </>
          </TrayContainer>
        </NavLink>
      </li>
    );
  });

  return (
    <div className="journey-step +pos-relative +bg-alice-blue +overflow-hidden">
      <BackButton className="+pos-absolute +mg-left-none--md" url={buildJourneyUrl({ journeySlug })} />
      <div className="+display-flex +align-items-center +justify-content-center journey-step__image">
        <img style={{ maxHeight: '414px', maxWidth: '414px' }} src={illustration_config.images.task.filename} alt={`${illustration_config.images.task.alt}`} />
      </div>

      <main className="+width-full +height-full +bg-white +border-radius__sm">
        <TrayContainer className={`+pd-vertical-md +pd-horizontal +pd-horizontal-md--md +height-full ${className} +box-shadow__none`} borderOrientation="Top">
          <div data-testid={dataTestId} className="+display-flex +justify-content-center +align-items-center +flex-direction-column +height-full +width-full journey-step__main-content">
            <div className="+type-heading-five journey-step__eyebrow-text +mg-top-md--md +mg-bottom" role="doc-subtitle">{eyebrow_text}</div>
            <h3 className="+type-heading-three">{title}</h3>
            <p className="+type-body-one journey-step__description +text-center">{description} </p>
            <div className="+display-flex +justify-content-flex-start +width-full +mg-top--md">
              <VerticalProgressBar nodeList={progressBarNodes} />
              <ol className="journey-step__tasks +mg-left-md">
                {taskList}
              </ol>
            </div>
          </div>
        </TrayContainer>
      </main>
      {showStepCompletion &&
        <CompletionPage
          completionPage={step.completionPage}
          handleClick={handleCompletionClick}
          buttonLink={`/learning/journeys/${journeySlug}`}
          illustrationConfig={illustration_config}
        />
      }
    </div>
  )
};

export default StepContainer;
