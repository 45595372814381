import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VideoPlayIcon from '../../../../assets/VideoPlayIcon';
import { elementClicked } from '../../../../services/tracking';
import VideoModal from '../../../Common/VideoModal';
import { JourneySlugs } from '../../Types';

import './VideoPlayer.scss';

type Props = {
  videoCaption: string;
  videoUrl: string;
  videoPlayerClassname?: string;
  subtaskTitle: string;
};

/**
 * Video Player componenet
 */
const VideoPlayer: React.FC<Props> = (props: Props) => {
  const { videoCaption, videoUrl, videoPlayerClassname, subtaskTitle } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  const getThumbnail = async (url: string) => {
    fetch(url).then(async res => {
      const response = await res.json();
      if(url.includes('vimeo')) {
        let [ url ] = response.thumbnail_url.split('-d_');
        url += '-d_960x540';
        setThumbnailUrl(url);
        return;
      }
      setThumbnailUrl(response.thumbnail_url);
    })
  }

  const slugs = useParams<JourneySlugs>();
  
  useEffect(() => {
    if(videoUrl.includes('wistia')) {
      getThumbnail(`https://fast.wistia.net/oembed?url=${videoUrl}.jpg`);
      return;
    }
    
    if(videoUrl.includes('vimeo')) {
      getThumbnail(`https://vimeo.com/api/oembed.json?url=${videoUrl}`);
      return;
    }
  }, [videoUrl]);

  return (
    <div className={`+display-flex +flex-direction-column +justify-content-center +align-items-center +mg-bottom-md ${videoPlayerClassname}`}>
      <div
        style={{
          backgroundImage: `url("${thumbnailUrl}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
        className="video-player-preview">
        <span
          onClick={() => {
            setShowVideo(true);
            elementClicked({
              elementType: 'Button',
              htmlId: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}_${slugs.subtaskSlug}_Play_Button`,
              location: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}`,
              position: slugs.journeySlug,
              text: 'Play_button',
              outboundUrl: '',
              actionOutcome: `play video`
            });

          }}
          role="button"
          className="play-icon +display-flex +pos-relative +flex-direction-column +align-items-center +cursor-pointer"
        >
          <VideoPlayIcon className="play-icon-svg" />
        </span>
      </div>
      <div className="+mg-top +type-body-one +fg-slate">{videoCaption}</div>
      {
        showVideo &&
        <VideoModal
          className="video-player-modal"
          videoUrl={videoUrl}
          title={`${subtaskTitle} video`}
          closeModalFunc={() => setShowVideo(false)}
          showCloseButton={true}
        />
      }
    </div>

  )
}

export default VideoPlayer;