/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useLayoutEffect, useEffect, useState } from 'react';
import BaseContainer from '../Common/BaseContainer';
import { Redirect, useRouteMatch } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ActionStatusCompletionSummary, ActionStatusType, Journey, JourneySlugs, LocalSessionFlag } from "./Types";
import LoadingSpinner from '../Loader/LoadingSpinner';
import { useSelector } from 'react-redux';
import { getStepBySlug, getTaskBySlug, getIllustration, isOtherSiblingsComplete, getSiblingItems, formatTrackingText, buildJourneyUrl } from './helpers';
import TaskContainer from './Components/Task';
import {
  completeCurrentJourneyStatus,
  completeStepStatus,
  completeTaskStatus,
  createNewUserJourney,
  setShowReentryScreen,
  setSessionRentryFlagChecked,
  addJourneyScrollPosition,
  clearScrollPosition,
  setShowCompletion
} from '../../store/journey/actions';
import Step from './Components/Step';
import Onboarding from './Components/Onboarding/index';
import JourneyHub from './Components/JourneyHub';
import { ActionStatusHash } from '../../store/journey';
import { clamp } from '../../utils/format';
import { getUserJourneyBySlug, constructNamespacedSessionFlag } from './helpers';
import { setLocalUserSession, getLocalUserSession } from '../../utils/authorization';
import CompletionPage from './Components/CompletionPage';
import { formSubmitted } from '../../services/tracking';

interface Props {
  error: {[key: string] : string | null} | null;
  bedrockId: string;
  journey: Journey | null;
  actionStatuses: ActionStatusHash;
  showReentryScreen: boolean;
  journeysLoaded: boolean;
  sessionRentryFlagChecked: boolean;
  scrollPositions: number[];
  showCompletion: {[key: string]: boolean};
}

const JourneyComponent: React.FC<Props> = (props: Props) => {
  const { error, journey, bedrockId, actionStatuses, showReentryScreen, journeysLoaded, sessionRentryFlagChecked, scrollPositions, showCompletion } = props;
  const { isMobile } = useWindowDimensions();
  const match = useRouteMatch<JourneySlugs>();
  const { journeySlug, stepSlug = '', taskSlug = '', subtaskSlug = '' } = match.params;

  const userJourneyAction = useSelector(getUserJourneyBySlug(journeySlug));
  const userStartedJourney = userJourneyAction !== undefined;
  const isJourneyComplete =
    userStartedJourney &&
    userJourneyAction.completed_at !== null &&
    userJourneyAction.completed_at !== undefined;


  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
  const [showIntroScreen, setShowIntroScreen] = useState<boolean>(false);
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);

  // scroll to top on initial render
  useEffect(() => {
    addJourneyScrollPosition(0);
  }, [match.params]);

  useEffect(() => {
    if (!scrollPositions.length) return;

    // grabs first scroll position and scrolls up to that, then clears positions
    const firstPosition = scrollPositions.sort((a, b) => a - b)[0];
    const position = clamp(firstPosition - 64, 0, Infinity);
    window.scrollTo({ top: position, behavior: 'auto' });
    clearScrollPosition();
    return;
  }, [scrollPositions]);

  const handleOnboarding = () => {
    setOnboardingComplete(true);
  }
  const handleReentryClose = (journey: Journey) => {
    // Set showReentry to false, update user session cookie
    setShowReentryScreen(false);
    const reentrySessionKey = constructNamespacedSessionFlag(journey.slug, LocalSessionFlag.HideReentry)
    setLocalUserSession({ [reentrySessionKey]: true });
  }

  const handleReentryClick = () => {
    // Set showReentry to false
    setShowReentryScreen(false);
  }

  /*
  *
  * Once the journey hash is loaded, we can use the user action of the current journey 
  * to determine the correct state of the onboarding flow
  * 
  */
  useLayoutEffect(() => {
    if (journeysLoaded) {
      setShowOnboarding(
        !onboardingComplete &&
        !userStartedJourney
      );
      setShowIntroScreen(
        onboardingComplete &&
        !userStartedJourney
      );
    }
  }, [journeysLoaded, onboardingComplete, userStartedJourney]);

  /*
  *
  * Once the journey hash is loaded, we can use the user action of the current journey 
  * to determine the correct state of the reentry screen
  * 
  * When a user loads a journey, the reentry will appear only if the following statements are true
  * a) The user has already started a journey
  * b) The user has not completed a journey
  * c) There is no flag present to disable the reentry screen
  * 
  */  
  const isJourneyHub = !stepSlug && !taskSlug && !subtaskSlug;

  useLayoutEffect(() => {
    if (!sessionRentryFlagChecked && journeysLoaded) {
      if (userStartedJourney && !isJourneyComplete && isJourneyHub) {
        const reentrySessionKey = constructNamespacedSessionFlag(userJourneyAction.journey_slug, LocalSessionFlag.HideReentry);
        const reentryObject = getLocalUserSession(reentrySessionKey);
        const sessionShowEntry =  typeof reentryObject === 'undefined' || typeof reentryObject[reentrySessionKey] === 'undefined';

        setShowReentryScreen(sessionShowEntry);
      }

      setSessionRentryFlagChecked(true);
    }
  }, [journeysLoaded]);

  if (error?.loadJourney) {
    return <Redirect to="/learning" />;
  }

  if (!journey || !journeysLoaded) {
    return (
      <div className="+width-screen +height-screen +display-flex +align-items-center">
        <LoadingSpinner width="150px" />
      </div>
    );
  }

  /**
   * Cascades completion events for Journey, Step and Tasks
   */
  const getActionStatusCompletionSummary = (slugs: JourneySlugs, completionSummary: ActionStatusCompletionSummary): ActionStatusCompletionSummary => {
    const { journeySlug, stepSlug = '', taskSlug = '', subtaskSlug = '' } = slugs;
    const siblings = getSiblingItems(journey, slugs);

    if (subtaskSlug && isOtherSiblingsComplete(siblings, subtaskSlug, actionStatuses)) {
      formSubmitted({
        formName: formatTrackingText(journeySlug,"-"),
        formType: "PREFERENCE",
        stepName: `${formatTrackingText(stepSlug,"-")}_Completed`,
        stepType: "PREFERENCE",
      })
      completeTaskStatus(stepSlug, taskSlug);
      return getActionStatusCompletionSummary(
        { journeySlug, stepSlug, taskSlug },
        { ...completionSummary, taskCompleted: true }
      );
    }

    if (subtaskSlug) return completionSummary;

    if (taskSlug && isOtherSiblingsComplete(siblings, taskSlug, actionStatuses)) {
      completeStepStatus(stepSlug);
      return getActionStatusCompletionSummary(
        { journeySlug, stepSlug },
        { ...completionSummary, stepCompleted: true }
      );
    }

    if (taskSlug) return completionSummary;

    if (stepSlug && isOtherSiblingsComplete(siblings, stepSlug, actionStatuses)) {
      completeCurrentJourneyStatus();
      return getActionStatusCompletionSummary(
        { journeySlug },
        { ...completionSummary, journeyCompleted: true }
      );
    }

    return completionSummary;
  }

  /**
   * Handles what to do upon completion of parent level action statuses
   */
  const onActionStatusComplete = (slugs: JourneySlugs): void => {
    const completionSummary = getActionStatusCompletionSummary(slugs, {});

    if (completionSummary.journeyCompleted) {
      setShowCompletion(ActionStatusType.JOURNEY, true);
      return;
    }

    if (completionSummary.stepCompleted) {
      setShowCompletion(ActionStatusType.STEP, true);
      return;
    }
  }
  /**
   * Creates a new Journey action status
   */

  const startJourney = () => {
    setShowReentryScreen(false);
    createNewUserJourney(bedrockId, {
      journey_slug: journeySlug,
      storyblok_id: journey?.storyblok_id,
      action_type: ActionStatusType.JOURNEY,
      started_at: (new Date()).toISOString(),
      parent_id: null
    });
  }

  /**
   * Grabs the correct illustration, given a hash of action statuses and step array.
   */
  const illustration = getIllustration(actionStatuses, journey?.steps);

  //TODO: getCompletionStatus -> uses getIllustration
  //TODO: handle state for completion modal

  if (showOnboarding) {
    return (
      <div className="content-tile +pd-all-none +height-full--no-bleed +footer-border">
        <Onboarding onClick={handleOnboarding} screens={journey.onboarding} journeyName={journey.title} />
      </div>
    );
  }

  let content;

  if (subtaskSlug) {
    /**
     * A subtask slug is provided in the url.
     * Render specific subtask.
     */
    const task = getTaskBySlug(journey, stepSlug, taskSlug);
    const stepIllustration = getStepBySlug(journey, stepSlug)?.illustration_config;

    if (!task) {
      return <Redirect to={buildJourneyUrl({ journeySlug })} />
    }
    content = <TaskContainer title={task.title} taskStoryblokId={task.storyblok_id} subtasks={task.subtasks} subtaskSlug={subtaskSlug} backgroundImage={stepIllustration} isMobile={isMobile} onActionStatusComplete={onActionStatusComplete} />
  } else if (stepSlug) {
    /**
     * A step slug is provided in the url with no subtask or task.
     * Render all tasks belonging to the step slug provided.
     */
    const step = getStepBySlug(journey, stepSlug);
    if (!step) {
      return <Redirect to={buildJourneyUrl({ journeySlug })} />
    }
    content = <Step step={step} journeySlug={journeySlug} onActionStatusComplete={onActionStatusComplete} showStepCompletion={showCompletion.STEP} setShowStepCompletion={() => setShowCompletion(ActionStatusType.STEP, false)} />;
  } else {

    content = <JourneyHub
      journey={journey}
      showIntroScreen={showIntroScreen}
      showReentryScreen={showReentryScreen}
      startJourney={startJourney}
      introTray={journey.introTray}
      illustrationConfig={illustration}
      isMobile={isMobile}
      onActionStatusComplete={onActionStatusComplete}
      reentryTray={journey.reentryTray}
      actionStatuses={actionStatuses}
      handleReentryClose={handleReentryClose}
      handleReentryClick={handleReentryClick}
    />;
  }

  return (
    <BaseContainer className={`${isMobile || isJourneyHub ? '+bg-white' : '+bg-alice-blue'} +display-flex +justify-content-center +height-full--no-bleed +footer-border`}>
      <section className="base-container__content-container +width-full">
        {content}
        {showCompletion.JOURNEY &&
          <CompletionPage
            completionPage={journey.completionPage}
            handleClick={() => setShowCompletion(ActionStatusType.JOURNEY, false)}
            buttonLink={`/learning`}
          />
        }
      </section>
    </BaseContainer>
  )
}

export default JourneyComponent;