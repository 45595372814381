import React from 'react';
import { RootState } from './store';
import { connect } from 'react-redux';
import BetaBadge from './components/BetaBadge';

const ConnectedBetaBadgeContainer = connect(
  (state: RootState) => ({
    betaFeatures: state.betaFeatures
  })
)(BetaBadge);

const AuxAppBetaBadgeContainer: React.FC = () => {
  return <ConnectedBetaBadgeContainer />;
};

export default AuxAppBetaBadgeContainer;