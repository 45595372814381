import FocusTrap from 'focus-trap-react';
import React from 'react';
import { CommonBaseProps } from '../../../Common/Types';
import Overlay from "../../../Common/Overlay";
import './TrayModal.scss';

type Props = {
  onOverlayClick: () => void;
  children: JSX.Element[] | JSX.Element;
} & CommonBaseProps;

const TrayModal: React.FC<Props> = (props: Props) => {
  const {
    onOverlayClick,
    children,
    className = '',
    dataTestId = ''
  } = props;

  return (
      <div className="tray-modal" data-testid={dataTestId}>
        <div className={`tray-modal__container +pd-horizontal-md +pd-bottom-lg +pd-all-lg--sm ${className}`}>
          <div className="tray-modal__content">
            {children}
          </div>
        </div>
        <FocusTrap>
          <Overlay onClick={onOverlayClick} />
        </FocusTrap>
      </div>
  );
}

export default TrayModal;
