import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { completeStepStatus, createTaskStatus, uncompleteActionStatus } from '../../../../store/journey/actions';
import { createStepStatus } from '../../../../store/journey/actions';
import { buildJourneyUrl, getActionStatusesByStoryblokIds, getNextSubtaskSlugs, getStatusSummary } from '../../helpers';
import { IllustrationConfig, Journey, JourneySlugs, Step, NotificationPage, Task } from '../../Types';
import JourneyNode from '../JourneyNode';
import "./JourneyHub.scss";
import NotificationPageComponent from '../NotificationPage';
import { ActionStatusHash } from '../../../../store/journey';
import BackButton from '../BackButton';
import { getLocalUserSession } from '../../../../utils/authorization';
import { elementClicked, elementViewed } from '../../../../services/tracking';
import { formatTrackingText } from '../../helpers';

interface Props {
  journey: Journey;
  actionStatuses: ActionStatusHash;
  startJourney: () => void;
  showIntroScreen: boolean;
  showReentryScreen: boolean;
  introTray: NotificationPage;
  illustrationConfig: IllustrationConfig;
  isMobile: boolean;
  onActionStatusComplete: (slugs: JourneySlugs) => void;
  reentryTray: NotificationPage;
  handleReentryClose: (journey: Journey) => void;
  handleReentryClick: () => void;
}

const JourneyHub: React.FC<Props> = (props: Props) => {
  const {
    journey,
    actionStatuses,
    startJourney,
    showIntroScreen,
    showReentryScreen,
    introTray,
    illustrationConfig,
    isMobile,
    onActionStatusComplete,
    reentryTray,
    handleReentryClose,
    handleReentryClick,
  } = props;

  const routerHistory = useHistory();
  const { title: journeyName } = journey;

  const stepActions = useSelector(getActionStatusesByStoryblokIds(journey.steps.map(step => step.storyblok_id)));
  const summaryFunction = useSelector(getStatusSummary);

  const session = getLocalUserSession('journeyReferrerPath');
  const referrerUrl = (session && session.journeyReferrerPath) ? session.journeyReferrerPath : '/learning';

  useEffect(() => {
    const steps = journey.steps;
    const complete = summaryFunction(steps);

    elementViewed({
      elementType: "Screen",
      htmlId: `${formatTrackingText(journeyName)}_Overview_Hub_${complete.complete}_of_${steps.length}`,
      location: `${formatTrackingText(journeyName)}_Overview_Hub`,
      position: formatTrackingText(journeyName),
      text: `Your_${formatTrackingText(journeyName)}`,
    })
  }, []);

  const emitToastScreenViewed = (screen: NotificationPage) => (
    elementViewed({
      elementType: "Screen",
      htmlId: `${formatTrackingText(journeyName)}_${formatTrackingText(screen.heading)}`,
      location: `${formatTrackingText(journeyName)}_${formatTrackingText(screen.heading)}`,
      position: formatTrackingText(journeyName),
      text: formatTrackingText(screen.heading),
    })
  );

  const emitToastScreenCTAClicked = (screen: NotificationPage, actionOutcome: string, ctaText: string) => (
    elementClicked({
      elementType: "Button",
      htmlId: `${formatTrackingText(journeyName)}_${formatTrackingText(screen.heading)}_${formatTrackingText(ctaText)}`,
      location: `${formatTrackingText(journeyName)}_${formatTrackingText(screen.heading)}`,
      position: formatTrackingText(journeyName),
      text: formatTrackingText(ctaText),
      actionOutcome,
      outboundUrl: "",
    })
  );

  /**
   * Creates and returns inline style object for styling step banner image
   */
  const getBannerStyles = (config: IllustrationConfig) => {
    const { illustration_config, images } = config
    const imageUrl = isMobile ? images.hub_mobile.filename : images.hub_desktop.filename;
    return {
      backgroundColor: illustration_config.background_color,
      backgroundPositionX: isMobile ? 'center' : illustration_config.hub_position_desktop,
      backgroundImage: `url("${imageUrl}")`,
    }
  }

  /**
   * Returns text for step progress
   * Example: 2 / 3 subtasks
   */
  const getProgressText = (tasks: Task[]) => {
    const taskSummary = summaryFunction(tasks);
    return `${taskSummary.complete}/${tasks.length} tasks`;
  }

  /**
   * Creates a step status and routes to step url
   */
  const onTileClick = (step: Step) => {
    elementClicked({
      elementType: "Button",
      htmlId: `${formatTrackingText(journeyName)}_Overview_Hub_${formatTrackingText(step.title)}`,
      location: `${formatTrackingText(journeyName)}_Overview_Hub`,
      position: formatTrackingText(journeyName),
      text: formatTrackingText(step.title),
      actionOutcome: "open next step",
      outboundUrl: "",
    })
    createStepStatus(step.slug);
    const url = buildJourneyUrl({ journeySlug: journey.slug, stepSlug: step.slug });
    routerHistory.push(url);
  }

  /**
   * Marks step complete if currently incomplete.
   * Marks step incomplete if currently complete and at least 1 subtask is also incomplete
   */
  const onNodeClick = async (e: React.SyntheticEvent, step: Step, isCompleted: boolean) => {
    e.stopPropagation();

    if (!isCompleted) {
      // Update complete to create if not exist
      await createStepStatus(step.slug);
      await completeStepStatus(step.slug);
      onActionStatusComplete({ journeySlug: journey.slug, stepSlug: step.slug });
      return;
    }

    // check if all child subtasks are complete
    const summary = summaryFunction(step.tasks);
    if (summary.incomplete > 0) {
      await uncompleteActionStatus(step.storyblok_id);
    }
  }

  /**
   * Returns if a given step is comepleted
   */
  const isStepCompleted = (storyblokId: string) => {
    return !!stepActions.find(({ storyblok_id }) => storyblok_id === storyblokId)?.completed_at;
  }

  /**
   * Create markup for each step tile
   */
  const createStepTiles = (steps: Step[]) => {
    return steps.map((step, index) => {
      const isCompleted = isStepCompleted(step.storyblok_id);
      return (
        <div
          onClick={() => onTileClick(step)}
          className={`hub-tile ${isCompleted && 'hub-tile--completed'}`}
          key={`hub-tile-${index}`}
        >
          <div className="hub-tile__node">
            <JourneyNode size={32} value={index + 1} topOffset={-16} isCompleted={isCompleted} onClick={(e) => onNodeClick(e, step, isCompleted)} />
          </div>

          <div className="hub-tile__container">
            <div className="hub-tile__banner" style={getBannerStyles(step.illustration_config)}></div>
            <div className="hub-tile__content +pd-vertical-ml +pd-horizontal">
              <div className="hub-tile__header +mg-bottom-sm">
                <h4 className="hub-tile__title journey-heading +mg-bottom-sm">{step.title}</h4>
                <span className="hub-tile__progress journey-text">{getProgressText(step.tasks)}</span>
              </div>
              <p className="journey-text +mg-all-none">{step.description}</p>

              <p className="hub-tile__progress hub-tile__progress--xs journey-text +mg-top +mg-bottom-none">{getProgressText(step.tasks)}</p>
            </div>
          </div>
        </div>
      );
    });
  }

  const handleReentryCtaClick = () => {
    handleReentryClick();
    const slugs = getNextSubtaskSlugs(actionStatuses, journey);
    if (!slugs.stepSlug || !slugs.taskSlug) return;

    createTaskStatus(slugs.stepSlug, slugs.taskSlug);
    routerHistory.push(buildJourneyUrl(slugs));
  }

  const introTrayComponent =
    <NotificationPageComponent
      content={introTray}
      handleClick={() => {
        emitToastScreenCTAClicked(introTray, "open journey overview", introTray.button_text || "");
        startJourney();
      }}
      onLoad={() => {
        emitToastScreenViewed(introTray);
      }}
      className={`+text-center +border-radius__${isMobile ? 'lg' : 'md'}`}
      illustrationConfig={illustrationConfig}
    />
    ;

  const reentryTrayComponent =
    <NotificationPageComponent
      content={reentryTray}
      handleClick={() => {
        emitToastScreenCTAClicked(reentryTray, "open next subtask", reentryTray.button_text || "");
        handleReentryCtaClick();
      }}
      handleClose={() => {
        emitToastScreenCTAClicked(reentryTray, "open journey overview", "No thanks");
        handleReentryClose(journey);
      }}
      onLoad={() => {
        emitToastScreenViewed(reentryTray);
      }}
      className={`+text-center +border-radius__${isMobile ? 'lg' : 'md'}`}
      illustrationConfig={illustrationConfig}
    />
    ;

  return (
    <div className="journey-hub +mg-left-lg--md">
      {showIntroScreen && introTrayComponent}
      {showReentryScreen && reentryTrayComponent}
      <div className="mg-top-sm--md">
        <BackButton url={referrerUrl} className="+mg-bottom-ml" />
      </div>
      <h2 className="+type-heading-two +text-center">Your {journey.title}</h2>
      <div className="journey-hub__tiles">
        {createStepTiles(journey.steps)}
      </div>
    </div>
  );
}

export default JourneyHub;
