import React, { useEffect } from 'react';
import { RootState } from './store';
import { loadProfile } from './store/actions';
import { connect, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import JourneyContainer from './components/Journeys';
import SubtaskLoader from './components/Journeys/Components/SubtaskLoader';
import { loadJourney, loadUserActionsByJourney, loadUserJourneys, setSessionRentryFlagChecked } from './store/journey/actions';
import { getUserJourney } from './components/Journeys/helpers';
import PrivateRoute from './utils/PrivateRoute';
import Sidebar from './components/Sidebar';
import useWindowDimensions from './utils/useWindowDimensions';

interface MatchParams {
  journeySlug: string;
}

const ConnectedJourneyContainer = connect(
  (state: RootState) => ({
    error: state.journey.loadError,
    bedrockId: state.profile.id,
    journey: state.journey.currentJourney,
    actionStatuses: state.journey.actionStatuses,
    scrollPositions: state.journey.journeyScroll,
    showReentryScreen: state.journey.showReentryScreen,
    showCompletion: state.journey.showCompletion,
    journeysLoaded: state.journey.journeysLoaded,
    sessionRentryFlagChecked: state.journey.sessionRentryFlagChecked
  })
)(JourneyContainer);

const AuxAppJourneyContainer: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  const { journeySlug } = match.params;
  const bedrockId = useSelector((state: RootState) => state.profile.id);
  const userJourney = useSelector(getUserJourney);
  const isMobile = useWindowDimensions().isMobile;
  // Step One - Load Profile & Passthrough Journey
  useEffect(() => {
    loadProfile();
    loadJourney(journeySlug);
  }, [journeySlug]);

  //  Step Two - Load User Journeys
  useEffect(() => {
    if (bedrockId) {
      loadUserJourneys(bedrockId);
    }
  }, [bedrockId]);

  //  Step Three - When Journey loads, load actions
  useEffect(() => {
    if (bedrockId && userJourney) {
      loadUserActionsByJourney(bedrockId, userJourney.uuid);
    }
  }, [userJourney]);

  useEffect(() => {
    return () => setSessionRentryFlagChecked(false);
  }, []);

  return (
    <>
      <PrivateRoute exact path="/learning/journeys/:journeySlug">
        {!isMobile ? <Sidebar className='--journey-hub +height-full--no-bleed +footer-border' /> : <></>}
        <ConnectedJourneyContainer />
      </PrivateRoute>
      <PrivateRoute exact path="/learning/journeys/:journeySlug/steps/:stepSlug">
        <ConnectedJourneyContainer />
      </PrivateRoute>
      <PrivateRoute exact path="/learning/journeys/:journeySlug/steps/:stepSlug/tasks/:taskSlug">
        <SubtaskLoader />
      </PrivateRoute>
      <PrivateRoute exact path="/learning/journeys/:journeySlug/steps/:stepSlug/tasks/:taskSlug/subtasks/:subtaskSlug">
        <ConnectedJourneyContainer />
      </PrivateRoute>
    </>
  );
};

export default AuxAppJourneyContainer;
