import React from 'react';
import './VideoModal.css';
import CloseIcon from '../../../assets/CloseIcon';
import { CommonBaseProps } from '../Types';

type Props = {
  videoUrl: string;
  title: string;
  closeModalFunc: () => void;
  maxWidth?: string;
  isWistia?: boolean;
  showCloseButton?: boolean;
} & CommonBaseProps;

const VideoModal: React.FC<Props> = (props: Props) => {
  const styles = props.maxWidth ? { maxWidth: props.maxWidth } : {}
  return (
    <div className={`video-modal ${props.className || ""}`} data-testid="videoModal" onClick={props.closeModalFunc}>
      <div className="video-modal__background +z-layer-five +display-flex +align-items-center +justify-content-center +height-full +width-full +pd-horizontal">
          <div className="video-modal__embeded-video-wrapper +pos-relative" style={styles}>
            <div className="video-modal__embedded-video-container" onClick={(e) => e.stopPropagation()}>
              <iframe
                className={`${props.isWistia ? 'wistia_embed' : ''} video-modal__embeded-video +pos-absolute +width-full +height-full`}
                src={ props.videoUrl }
                title={props.title}
                allow="autoplay; fullscreen"
              />
              {
                props.showCloseButton &&
                <CloseIcon
                  className="+pos-absolute video-modal__close-icon"
                  width="30px"
                  height="30px"
                  fill="white"
                  onClick={props.closeModalFunc}
                  />
              }
            </div>
          </div>
      </div>
    </div>
  );
}

export default VideoModal;