/* eslint-disable @typescript-eslint/no-unused-vars */
import store from '..';
import {
  ActionStatus,
  Journey,
  UserData,
  ActionStatusRequest,
  UserDataRequest,
  ActionStatusBase,
  ActionStatusType,
  JourneySlugs,
  SubtaskRequiredUpdate,
} from '../../components/Journeys/Types';
import { logger } from '../../RollbarErrorBoundary';
import { isValidJourney } from '../../components/Journeys/Validators';
import { updateError } from '../actions';
import { get, post, put } from '../../services/http';
import { getStepBySlug, getTaskBySlug, getSubtaskBySlug } from '../../components/Journeys/helpers';

export const UPDATE_SESSION_RENTRY_FLAG_CHECKED = 'UPDATE_SESSION_RENTRY_FLAG_CHECKED';
export const UPDATE_CURRENT_JOURNEY = 'UPDATE_CURRENT_JOURNEY';
export const UPDATE_USER_JOURNEYS = "UPDATE_USER_JOURNEYS";
export const USER_JOURNEYS_LOADED = "USER_JOURNEYS_LOADED";
export const UPDATE_USER_ACTION_STATUSES = "UPDATE_USER_ACTION_STATUSES";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const RESET_JOURNEY_STORE = "RESET_JOURNEY_STORE";
export const UPDATE_JOURNEY_ERROR = "UPDATE_JOURNEY_ERROR";
export const UPDATE_JOURNEY_SUBTASK_REQUIRED = "UPDATE_JOURNEY_SUBTASK_REQUIRED";
export const UPDATE_SHOW_REENTRY_SCREEN = "UPDATE_SHOW_REENTRY_SCREEN";
export const UPDATE_JOURNEY_SCROLL = "UPDATE_JOURNEY_SCROLL";
export const UPDATE_SHOW_COMPLETION = "UPDATE_SHOW_COMPLETION";

const AUX_JOURNEY_API_BASE_URL = process.env.REACT_APP_AUX_JOURNEY_API_BASE_URL as string;

// Actions
function updateCurrentJourney(journey: Journey) {
  return {
    type: UPDATE_CURRENT_JOURNEY,
    journey,
  };
}

function updateJourneyError(message: string | null, actionType: string) {
  return {
    type: UPDATE_JOURNEY_ERROR,
    message,
    actionType
  };
}

function updateUserJourneys(journeys: Journey[]) {
  return {
    type: UPDATE_USER_JOURNEYS,
    journeys
  };
}

export function updateUserActions(actionStatuses: ActionStatus[]): { type: string; actionStatuses: ActionStatus[] } {
  return {
    type: UPDATE_USER_ACTION_STATUSES,
    actionStatuses
  };
}

export function updateUserData(userData: UserData[]): { type: string; userData: UserData[] } {
  return {
    type: UPDATE_USER_DATA,
    userData
  };
}

export function resetJourneyStore(): { type: string } {
  return {
    type: RESET_JOURNEY_STORE,
  }
}

export function updateSubtasksRequired(updates: SubtaskRequiredUpdate[]): { type: string; updates: SubtaskRequiredUpdate[]} {
  return {
    type: UPDATE_JOURNEY_SUBTASK_REQUIRED,
    updates
  };
}

function updateShowReentryScreen(showReentryScreen: boolean) {
  return {
    type: UPDATE_SHOW_REENTRY_SCREEN,
    showReentryScreen
  }
}

function updateSessionRentryFlagChecked(checked: boolean) {
  return {
    type: UPDATE_SESSION_RENTRY_FLAG_CHECKED,
    checked,
  }
}

function updateJourneyScroll(position: number[]) {
  return {
    type: UPDATE_JOURNEY_SCROLL,
    position
  }
}

function updateShowCompletion(actionType: ActionStatusType, value: boolean) {
  return {
    type: UPDATE_SHOW_COMPLETION,
    actionType,
    value,
  };
}

/**
 * Loads a journey by the slug
 * @param slug 
 */
export async function loadJourney(slug: string): Promise<void> {
  try {
    store.dispatch(updateJourneyError(null, loadJourney.name));

    const journey = await get(`${AUX_JOURNEY_API_BASE_URL}/${slug}`);
    const validJourney: Journey | null = await isValidJourney(journey);
    if (!validJourney) {
      throw new Error(`Journey with slug ${slug} does not exist or is not valid`);
    }
    validJourney.slug = slug;

    store.dispatch(updateCurrentJourney(validJourney));
  } catch (e) {
    store.dispatch(updateJourneyError((e as Error).message, loadJourney.name));
    logger.error(e as Error);
    console.error(e);
  }
}

/**
 * Loads a users journey by bedrock id if passed
 * @param bedrockId 
 * @returns 
 */
export async function loadUserJourneys(bedrockId: string): Promise<ActionStatus[] | undefined> {
  try {
    const requestURL = `${AUX_JOURNEY_API_BASE_URL}/users/${bedrockId}/journeys`;
    const journeys = await get(requestURL);

    if (!Array.isArray(journeys)) {
      throw new Error(`There were some issues getting the journeys for the user`);
    }
    store.dispatch(updateUserJourneys(journeys));
    return journeys;
  } catch (e) {
    store.dispatch(updateJourneyError((e as Error).message, loadUserJourneys.name));
    logger.error(e as Error);
    console.error(e);
  }
}

/**
 * Loads a users actions by journey id and bedrock id if both are present
 * @param bedrockId 
 * @param journeyId 
 * @returns 
 */
export async function loadUserActionsByJourney(bedrockId: string, journeyId: string): Promise<void> {
  try {
    const requestURL = `${AUX_JOURNEY_API_BASE_URL}/users/${bedrockId}/actions/${journeyId}`;
    const response = await get(requestURL);
    store.dispatch(updateUserActions(response.actions));
    store.dispatch(updateUserData(response.fields));
  } catch (e) { 
    store.dispatch(updateError((e as Error).message));
    logger.error(e as Error);
    console.error(e);
  }
}

export async function createNewUserJourney(bedrockId: string, data: ActionStatusBase): Promise<void> {
  if (!bedrockId || !data) {
    return;
  }
  try {
    const requestURL = `${AUX_JOURNEY_API_BASE_URL}/users/${bedrockId}/journeys`;
    const response = await post(requestURL, data);

    store.dispatch(updateUserJourneys([response]));
    store.dispatch(updateUserActions([response]));
  } catch (e) {
    store.dispatch(updateError((e as Error).message));
    logger.error(e as Error);
    console.error(e);
  }
}

/**
 * Sends the API request to update create/update user action status data
 */
export async function updateJourneyData(bedrockId: string, journeyId: string, payload: { actions: ActionStatusRequest[]; fields: UserDataRequest[]; }): Promise<void> {
  try {
    const { fields, actions } = await put(`${AUX_JOURNEY_API_BASE_URL}/users/${bedrockId}/actions/${journeyId}`, payload) as { actions: ActionStatus[]; fields: UserData[] };
    if (fields.length !== 0) store.dispatch(updateUserData(fields));
    if (actions.length !== 0) store.dispatch(updateUserActions(actions));

  } catch (e) {
    store.dispatch(updateError((e as Error).message));
    logger.error(e as Error);
    console.error(e);
  }
}

/**
 * Creates a base ActionStatusRequest object with a defaulted started_at date
 * to the current time.
 */
export function createNewActionStatus(journeySlug: string, storyblokId: string, actionType: ActionStatusType, parentId: string, skipped?: boolean | undefined): ActionStatusRequest {
  return {
    journey_slug: journeySlug,
    storyblok_id: storyblokId,
    action_type: actionType,
    // if skipped, don't send timestamp
    started_at: skipped ? null : new Date().toISOString(),
    // if skipped, send timestamp
    completed_at: skipped ? new Date().toISOString() : null,
    parent_id: parentId
  }
}

/**
 * Sends a request to update an ActionStatus for the current user. Fetches the users
 * bedrock id and current journey uuid from the store before sending update request.
 */
export async function sendActionStatus(action: ActionStatusRequest, fields?: UserDataRequest[]): Promise<void> {
  const { profile, journey } = store.getState();
  const { actionStatuses, currentJourney } = journey;
  const journeyActionStatus = actionStatuses[currentJourney.storyblok_id];

  await updateJourneyData(profile.id, journeyActionStatus.uuid, {
    actions: [action],
    fields: fields || []
  });
}

/**
 * Updates completed_at for any existing ActionStatus looked up by storyblok id
 */
async function completeActionStatus(storyblokId: string): Promise<void> {
  const { actionStatuses } = store.getState().journey;

  const actionStatus = actionStatuses[storyblokId];

  // Action Status to update does not exist
  if (!actionStatus) return;

  await sendActionStatus({
    ...actionStatus,
    started_at: actionStatus.started_at ? actionStatus.started_at : new Date().toISOString(),
    completed_at: new Date().toISOString()
  });
}

/**
 * Nulls completed_at for any existing ActionStatus looked up by storyblok id
 */
export async function uncompleteActionStatus(storyblokId: string): Promise<void> {
  const { actionStatuses } = store.getState().journey;
  const actionStatus = actionStatuses[storyblokId];

  // Action Status to update does not exist
  if (!actionStatus) return;

  await sendActionStatus({
    ...actionStatus,
    completed_at: null
  });
}

/**
 * Creates a new Step ActionStatus for the current user if one
 * does not exist for the given step.
 */
export async function createStepStatus(stepSlug: string): Promise<void> {
  const { currentJourney, actionStatuses } = store.getState().journey;
  const step = getStepBySlug(currentJourney, stepSlug);

  // Bad slug or ActionStatus already exists
  if (!step || actionStatuses[step.storyblok_id]) return;
  const actionStatus = createNewActionStatus(currentJourney.slug, step.storyblok_id, ActionStatusType.STEP, currentJourney.storyblok_id);
  await sendActionStatus(actionStatus);
}

/**
 * Updates the completed at date for an existing Step ActionStatus
 */
export async function completeStepStatus(stepSlug: string): Promise<void> {
  const { currentJourney } = store.getState().journey;
  const step = getStepBySlug(currentJourney, stepSlug);
  // Bad Slug
  if (!step) return;
  await completeActionStatus(step.storyblok_id);
}

/**
 * Updates the completed at date for an existing Step ActionStatus
 */
export async function completeCurrentJourneyStatus(): Promise<void> {
  const { currentJourney } = store.getState().journey;
  await completeActionStatus(currentJourney.storyblok_id);
}

/**
 * Creates a new Task ActionStatus for the current user if one does not
 * exist. Also creates a new parent Step ActionStatus if one
 * does not exist for the given task.
 */
export async function createTaskStatus(stepSlug: string, taskSlug: string): Promise<void> {
  const { currentJourney, actionStatuses } = store.getState().journey;
  const step = getStepBySlug(currentJourney, stepSlug);
  const task = getTaskBySlug(currentJourney, stepSlug, taskSlug);

  // Bad slug or ActionStatus already exists
  if (!step || !task || actionStatuses[task.storyblok_id]) return;

  // Creates a step action in case where user doesnt have one
  // createStepStatus function returns early if one exists 
  await createStepStatus(step.slug);

  const actionStatus = createNewActionStatus(currentJourney.slug, task.storyblok_id, ActionStatusType.TASK, step.storyblok_id);

  await sendActionStatus(actionStatus);
}

/**
 * Updates the completed at date for an existing Task ActionStatus
 */
export async function completeTaskStatus(stepSlug: string, taskSlug: string): Promise<void> {
  const { currentJourney } = store.getState().journey;
  const task = getTaskBySlug(currentJourney, stepSlug, taskSlug);

  // Bad Slug
  if (!task) return;
  await completeActionStatus(task.storyblok_id);
}

/**
 * Creates a new Subtask ActionStatus for the current user if one does not
 * exist. Also creates a new parent Task ActionStatus if one
 * does not exist for the given subtask.
 */
export async function createSubtaskStatus(stepSlug: string, taskSlug: string, subtaskSlug: string, isSkipped?: boolean, fields?: UserDataRequest[]): Promise<void> {
  const { currentJourney, actionStatuses } = store.getState().journey;
  const task = getTaskBySlug(currentJourney, stepSlug, taskSlug);
  const subtask = getSubtaskBySlug(currentJourney, stepSlug, taskSlug, subtaskSlug);

  // Bad slug or ActionStatus already exists
  if (!task || !subtask || actionStatuses[subtask.storyblok_id]?.created_at) return;

  // Creates a task action in case where user doesnt have one
  // createTaskStatus function returns early if one exists 
  await createTaskStatus(stepSlug, task.slug);
  const actionStatus = createNewActionStatus(currentJourney.slug, subtask.storyblok_id, ActionStatusType.SUBTASK, task.storyblok_id, isSkipped);

  await sendActionStatus(actionStatus, fields);
}

/**
 * Updates the completed at date for an existing Subtask ActionStatus
 */
export async function completeSubtaskStatus(slugs: JourneySlugs, startDate?: string, fields?: UserDataRequest[]): Promise<void> {
  const { currentJourney, actionStatuses } = store.getState().journey;
  const { stepSlug = '', taskSlug = '', subtaskSlug = '' } = slugs;

  // Get the storyblock objects
  const subtask = getSubtaskBySlug(currentJourney, stepSlug, taskSlug, subtaskSlug);
  const task = getTaskBySlug(currentJourney, stepSlug, taskSlug);

  // Bad Slug
  if (!task || !subtask) return;

  // Retrieves or Creates the action status as is
  const actionStatus = actionStatuses[subtask.storyblok_id]
    ? actionStatuses[subtask.storyblok_id]
    : createNewActionStatus(currentJourney.slug, subtask.storyblok_id, ActionStatusType.SUBTASK, task?.storyblok_id);

  //  Get Current start date, date passed from calling function, or set a new start date
  const startSubtaskDate = actionStatus.started_at || startDate || new Date().toISOString();

  //  Updates it to send
  const updatedActionStatus = {
    ...actionStatus,
    started_at: startSubtaskDate,
    completed_at: new Date().toISOString()
  };

  await sendActionStatus(updatedActionStatus, fields);
}

export function setShowReentryScreen(showReentryScreen: boolean): void {
  store.dispatch(updateShowReentryScreen(showReentryScreen));
  return;
}

export function setShowCompletion(actionStatus: ActionStatusType, value: boolean): void {
  store.dispatch(updateShowCompletion(actionStatus, value));
  return;
}

export function markSubtasksRequired (subtaskRequiredUpdates: SubtaskRequiredUpdate[]): void {
  store.dispatch(updateSubtasksRequired(subtaskRequiredUpdates));
  return;
}

export function setSessionRentryFlagChecked(checked: boolean): void {
  store.dispatch(updateSessionRentryFlagChecked(checked));
  return;
}

export function addJourneyScrollPosition(position: number): void {
  const currentPositions = store.getState().journey.journeyScroll;
  
  store.dispatch(updateJourneyScroll([...currentPositions, position]));
  return;
}

export function clearScrollPosition(): void {
  store.dispatch(updateJourneyScroll([]));
  return;
}