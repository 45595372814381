import React from 'react';
import { CommonBaseProps } from './Types';

type Props = {
  id?: string;
  children?: JSX.Element | string;
  icon?: JSX.Element;
  isActive?: boolean;
  onClick?: () => void
} & CommonBaseProps;

export type Button = React.FC<Props>;

const ButtonComponent: Button = (props: Props) => {
  const className = props.className || '';
  const isActive = props.isActive || false;

  return (
    <button id={props.id} className={`button ${className} ${isActive ? '--active-button' : ''}`} onClick={props.onClick}>
        {props.icon}
        <span>{props.children}</span>
    </button>
  )
}

export default ButtonComponent;
