import React, { useEffect, useState } from 'react';
import SubtaskComponent from '../../../Components/Subtask';
import { InputValueDictionary, IntegrationProps, ProgressSummary } from '../../Types';
import InputTile from '../../InputTile';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import InfoComponent from '../../../../Common/InfoComponent';
import HomeAffordabilitySlider from '../../../../Widgets/HomeAffordabilitySlider';
import { formatTrackingText } from '../../../helpers';
import { getFilteredEntries, getUserDataFromInputValues, isValidInputDictionary } from '../../../Components/Subtask/helpers';
import ErrorModal from './ErrorModal';
import { inputConfigurations } from './inputConfiguration';
import { elementClicked, formSubmitted } from '../../../../../services/tracking';
import { useParams } from 'react-router-dom';
import { JourneySlugs } from '../../../Types';

const HomeAffordabilityCalculator: React.FC<IntegrationProps> = (props: IntegrationProps) => {
  const { onComplete, onSkip, onProgress = () => null, skipProps, subtaskUuid, isSubtaskComplete, currentSubtaskPage = 0, setCurrentSubtaskPage = () => null } = props;
  const currentJourney = useSelector((state: RootState) => state.journey.currentJourney);
  const userData = useSelector((state: RootState) => state.journey.userData);
  const [shouldShowErrorModal, setShowErrorModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [startDate] = useState(new Date());
  const slugs = useParams<JourneySlugs>();

  const hideErrorModal = () => setShowErrorModal(false);
  const showErrorModal = () => setShowErrorModal(true);

  const monthlyDebtThreshold = .43;
  const fieldData = userData || {};
  const getFieldData = (field_name: string) => {
    return {
      field_name,
      required: true,
      value: fieldData[field_name]?.value || ''
    };
  };

  //  Maps field data from the userData
  const getInputDictionary = (): InputValueDictionary => {
    return {
      annual_income: getFieldData('annual_income'),
      monthly_debt: getFieldData('monthly_debt'),
      down_payment: getFieldData('down_payment'),
      annual_property_tax: getFieldData('annual_property_tax'),
      annual_insurance_cost: getFieldData('annual_insurance_cost'),
      estimated_interest: getFieldData('estimated_interest'),
    };
  };

  const [inputDictionary, setInputValues] = useState(getInputDictionary());

  useEffect(() => {
    if (userData) {
      setInputValues(getInputDictionary());
    }
  }, [userData]);


  const { annual_income,
    monthly_debt,
    down_payment,
    annual_property_tax,
    annual_insurance_cost,
    estimated_interest } = inputDictionary;

  const onContinueClick = () => {
    const summary: ProgressSummary = {
      current: currentSubtaskPage + 1,
      total: 2,
      subtaskName: subtaskUuid
    };

    //  Progress to the next Screen
    if (currentSubtaskPage === subtasks.length - 1 ) {
      const entries = getFilteredEntries(inputDictionary);
      const fields = getUserDataFromInputValues(entries);
      onComplete(startDate.toISOString(), fields);
    } else {
      setCurrentSubtaskPage(currentSubtaskPage + 1);
      window.scrollTo({top: 0, behavior: 'auto'});
    }
    onProgress(summary);
  };

  //  Updates inputDictionary data
  const handleInputChange = (fieldName: string, value: string) => {
    setInputValues({
      ...inputDictionary, [fieldName]: {...getFieldData(fieldName), value }
    });
    return null;
  };

//  handle skip for now
const onSkipForNow = () => {
  elementClicked({
    elementType: 'Button',
    htmlId: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}_${slugs.subtaskSlug}_Skip_for_now`,
    location: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}`,
    position: slugs.journeySlug,
    text: 'Skip_for_now',
    outboundUrl: '',
    actionOutcome: `task completions`
  });
  onSkip && onSkip();
}

  //  Handle Submitting initial inputs on first view
  const onFormSubmit = () => {
    if(!currentJourney) {
      return
    }

    setSubmitted(true);
    const monthlyDebtError = getMonthlyDebtValidation();

    elementClicked({
      elementType: 'Button',
      htmlId: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}_${slugs.subtaskSlug}_Get_my_results`,
      location: `${slugs.journeySlug}_${slugs.stepSlug}_${slugs.taskSlug}`,
      position: slugs.journeySlug,
      text: 'Get_my_results',
      outboundUrl: '',
      actionOutcome: `calculator results screen`
    });
    
    formSubmitted({
      formName: `${formatTrackingText(currentJourney.title)}_Home_Affordability_Calculator`,
      formType: "PREFERENCE",
      stepName: "Home_Affordability_Calculator",
      stepType: "PREFERENCE",
    })
    
    if (monthlyDebtError) {
      showErrorModal();
      return;
    }

    const isValid = isValidInputDictionary(inputDictionary) && !monthlyDebtError;

    if (isValid) {
      formSubmitted({
        formName: formatTrackingText(`${currentJourney.title}_Home_Affordability_Calculator`),
        formType: "PREFERENCE",
        stepName: formatTrackingText("Home_Affordability_Calculator"),
        stepType: "PREFERENCE"
      });
      onContinueClick();
    }
  }

  //  Handle changes on the slider
  const onSliderUpdate = (dti: number, monthlyPayment: number, totalLoanAmount: number) => {
    handleInputChange('estimated_home_price', totalLoanAmount.toFixed(2));
  };

  // TODO:  Will be getting additional updates for validation updates
  const getErrorCopy = (fieldName: string, fieldValue: string) => {
    return !submitted || (!!fieldValue && submitted)
      ? ''
      : `${fieldName} is required.`
  };
  
  //  Handle Monthly Debt Validation
  const getMonthlyDebtValidation = () => {
    if (!monthly_debt.value || !annual_income.value) return;

    const dti = parseFloat(monthly_debt.value) * 12 / parseFloat(annual_income.value);

    return dti >= monthlyDebtThreshold ? "Your debt-to-income cannot exceed 43%. Please check your monthly debt and annual income.": '';
  };
  
  const errorModal = <ErrorModal 
    onClose={hideErrorModal} 
    message='Your DTI ratio is above our recommended limit (43%). Increase your down payment amount to lower your DTI ratio.' />;
  //  Can be refactored later to update our input validation to have custom validators per input field
 
  const firstPage =
    <SubtaskComponent
      title="How much home can you afford?"
      description="Answer a few questions for a clearer understanding of your homebuying budget."
      continueText="Get my results"
      isSkippable={!isSubtaskComplete}
      skipProps={skipProps}
      onComplete={onFormSubmit}
      onSkip={onSkipForNow}
    >
      <form className="+display-flex +flex-direction-column +justify-content-center +align-items-center">
        {Object.values(inputConfigurations).map((inputConfig, index) => {
          const fieldData = inputDictionary[inputConfig.inputName];
          const input = {
            ...inputConfig,
            field_name: inputConfig.inputName,
            type: inputConfig.inputFormat,
            inputValue: fieldData.value,
            errorCopy: getErrorCopy(inputConfig.inputLabel, fieldData.value)
          };

          return <InputTile
            key={`hac-input-${index}`}
            {...inputConfig}
            inputs={[input]}
            changeHandler={handleInputChange}
          />
        })}
      </form>
      { shouldShowErrorModal ? errorModal : <></> }
    </SubtaskComponent>
  const secondPage = (
    <SubtaskComponent title="We've crunched the numbers" 
      description='Based on the details you provided, we estimate you can afford to spend the following amount on a home' 
      isSkippable={false}
      onComplete={onContinueClick}>
      <div className="+justify-content-space-between">
        <HomeAffordabilitySlider 
          monthlyDebt={parseInt(monthly_debt.value)} 
          annualIncome={parseInt(annual_income.value)}
          downPayment={parseInt(down_payment.value)} 
          annualInsurance={parseInt(annual_insurance_cost.value)}
          annualPropertyTax={parseInt(annual_property_tax.value)}
          interestRate={parseFloat(estimated_interest.value) * 1 / 100}
          onChangeHandler={onSliderUpdate}
          className="+mg-bottom-md +pd-bottom-sm"/>
        <InfoComponent 
          heading="Hint:"
          body="Your debt-to-income ratio, or DTI ratio, is your total monthly debt payments divided by your total gross monthly income."
          className="+mg-top-lg +mg-bottom-md"
          />
      </div>
    </SubtaskComponent>
    );

  const subtasks = [firstPage, secondPage];
  const currentSubtask = subtasks[currentSubtaskPage];

  return currentSubtask;
}
HomeAffordabilityCalculator
export default HomeAffordabilityCalculator;
