import React from 'react';

type Props = {
  width?: string;
  height?: string;
}

const HouseSearch: React.FC<Props> = ({ width = '64px', height = '64px' }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.001 14L11.999 20.002L12.001 6H18.001V14ZM55.999 44V29.816L61.091 34.908L64.001 32L32.001 0L22.001 10.002L21.993 2H8.00098V24L0.000976562 32L2.90898 34.908L8.00098 29.816L7.99898 64H55.999L56.001 52H52.001V60H11.999V25.818L32.001 5.816L52.001 25.816L51.999 44H55.999Z" fill="#151515" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.0202 47.8086L18.8282 56.0006L16.0002 53.1726L24.0182 45.1546C22.3602 43.0946 21.3642 40.4846 21.3642 37.6366C21.3642 31.0086 26.7362 25.6366 33.3642 25.6366C39.9902 25.6366 45.3642 31.0086 45.3642 37.6366C45.3642 44.2626 39.9902 49.6366 33.3642 49.6366C31.0302 49.6366 28.8622 48.9606 27.0202 47.8086ZM41.3642 37.6366C41.3642 33.2246 37.7762 29.6366 33.3642 29.6366C28.9522 29.6366 25.3642 33.2246 25.3642 37.6366C25.3642 42.0466 28.9522 45.6366 33.3642 45.6366C37.7762 45.6366 41.3642 42.0466 41.3642 37.6366Z" fill="#0157FF" />
    </svg>
  )
}

export default HouseSearch;
