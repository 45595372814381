import React from 'react';
import SubtaskComponent from '../../Components/Subtask';
import { IntegrationProps } from '../Types';
import HomeInsuranceWidget from '../../../Widgets/HomeInsuranceWidget';

const HomeInsuranceIntegration: React.FC<IntegrationProps> = (props: IntegrationProps) => {
  const { onComplete, onSkip, skipProps, title, description } = props;

  return (
    <SubtaskComponent
      title={title}
      description={description}
      isSkippable={false}
      onComplete={onComplete}
      onSkip={onSkip}
      skipProps={skipProps}
    >
      <HomeInsuranceWidget className="mobile-default" />
    </SubtaskComponent>
  )
}

export default HomeInsuranceIntegration;
