export enum ComponentType {
  journey = "journey",
  step = "step",
  task = "task",
  subtask = "subtask",
  introCard = "intro-card",
  introQuestion = "intro-question",
  onboarding = "onboarding",
}

export enum QuestionType {
  polar = "yes/no",
  input = "input",
}

export type JourneyBase = {
  storyblok_id: string;
  slug: string;
  title: string;
  description: string;
  componentType: ComponentType;
  required?: boolean;
};

export type Journey = {
  slug: string;
  steps: Step[];
  onboarding: Onboarding[];
  completionPage: NotificationPage;
  introTray: NotificationPage;
  reentryTray: NotificationPage;
  tile: JourneyTile;
} & JourneyBase;

export type Step = {
  tasks: Task[];
  eyebrow_text: string;
  completionPage: NotificationPage;
  illustration_config: IllustrationConfig;
} & JourneyBase;

export type Task = {
  subtasks: Subtask[];
} & JourneyBase;

export type Subtask = {
  actionItems: ActionItem[];
  isSkippable: boolean;
  continueCtaText: string | null;
  decisionGroup: DecisionGroup[];
} & JourneyBase & SkipProps;

export type ActionItem = {
  integration_name: string;
  component: string;
  copy?: { [key: string]: string | [] };
  tile?: {
    cta_copy: string;
    body_copy: string;
    heading_copy: string;
  }[] | undefined;
};

export type Onboarding = {
  readonly image: Image;
  readonly description: string;
  readonly buttonCopy: string;
};

export type Image = {
  alt?: string;
  filename: string;
  copyright?: string;
  name?: string;
};

export enum ActionStatusType {
  JOURNEY = "JOURNEY",
  STEP = "STEP",
  TASK = "TASK",
  SUBTASK = "SUBTASK",
}

export type ActionStatusBase = {
  journey_slug: string;
  storyblok_id: string;
  action_type: ActionStatusType;
  parent_id: string | null;
  started_at?: string | null;
  completed_at?: string | null;
};

type UserDataBase = {
  category: string;
  journey_id: string;
  field_name: string;
  value: string;
  created_at?: string;
  updated_at?: string;
};

export type ActionStatus = ActionStatusBase & {
  uuid: string;
};

export type UserData = UserDataBase & {
  uuid: string;
};

export type ActionStatusRequest = ActionStatusBase & {
  uuid?: string;
};

export type UserDataRequest = UserDataBase & {
  uuid?: string;
};

export type SkipProps = {
  skipCtaText: string;
  skipHeadingText: string;
  skipBodyText: string;
  skipButtonText: string;
};

export interface BaseSubtaskProps {
  onComplete: (startDate: string, fields?: UserDataRequest[]) => void;
  onSkip?: () => void;
  skipProps?: SkipProps;
}

export type JourneySlugs = {
  journeySlug: string;
  stepSlug?: string;
  taskSlug?: string;
  subtaskSlug?: string;
};

export type ActionStatusSummary = {
  complete: number;
  incomplete: number;
  skipped: number;
};

export type NotificationPage = {
  heading: string;
  description: string;
  button_text?: string;
  image?: Image;
  type: string;
};

export type IllustrationConfig = {
  images: { [key: string]: Image };
  illustration_config: {
    background_color: string;
    hub_position_desktop: string;
  };
};

export type DecisionButton = {
  _uid: string;
  text: string;
  component: string;
  next_subtask: string;
};

export type DecisionGroup = {
  _uid: string;
  subtext?: DecisionButton;
  question: string;
  component: string;
  decision_buttons: DecisionButton[];
  stacked: boolean;
};

export type ActionStatusCompletionSummary = {
  taskCompleted?: boolean;
  stepCompleted?: boolean;
  journeyCompleted?: boolean;
};

export type JourneyTileContent = {
  title: string;
  description: string;
  buttonText: string;
};

export type JourneyTile = {
  heading: string;
  category: string;
  image: Image;
  active: JourneyTileContent;
  inactive: JourneyTileContent;
  completed: JourneyTileContent;
};

export type SubtaskRequiredUpdate = {
  storyblok_id: string;
  isRequired: boolean;
};

export enum LocalSessionFlag {
  HideReentry = "HideReentry"
}
