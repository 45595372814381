import { createStore } from 'redux';
import { ProfileInterface, ProfileFieldInterface, ProfileUserStateInterface } from '../components/Profile/ProfileInterface';
import { RateAlertSubscriptionInterface } from '../components/Profile/ProfileRateAlertsInterface';
import { InterestsInterface } from '../components/Interests/InterestsInterface';
import { LoansProductOffers } from '../services/loans';
import { journeyReducer, JourneyState, journeyInitialState } from './journey';

import {
  UPDATE_LOADING,
  UPDATE_ERROR,
  UPDATE_PROFILE,
  UPDATE_PERSONAL_PROFILE,
  UPDATE_FINANCIAL_PROFILE,
  UPDATE_INTERESTS,
  UPDATE_INTERESTS_LIST,
  UPDATE_USER_NAME,
  UPDATE_RATE_ALERTS_DATA,
  UPDATE_LOAN_OFFERS,
  UPDATE_USER_STATE,
  UPDATE_USER_CONNECTIONS,
} from './actions'
import { initialInterests } from "../data/InitialInterests";
import { UserConnection } from '../BedrockProfileType';
import { AuthRoles, hasAllowedAuthRoles } from '../utils/authorization';
import mergeReducers from './mergeReducers';

export interface RootState {
  loading: boolean;
  error: string | null;
  profile: ProfileInterface;
  userState: ProfileUserStateInterface;
  userConnections: UserConnection[];
  userName: string;
  interests: InterestsInterface[];
  interestsList: string[];
  rateAlerts: RateAlertSubscriptionInterface[];
  loanOffers: LoansProductOffers[];
  sweepstakesEntry: boolean;
  betaFeatures: boolean;
  journey: JourneyState;
}

// TODO - extract profile setup to keep initialState cleaner
export const initialState: RootState = {
  loading: true,
  error: null,
  profile: {
    id: '',
    personal: [
      {
        name: 'email',
        label: 'Email',
        value: '',
        type: 'text',
        editable: false,
      },
      {
        name: 'firstName',
        label: 'First name',
        value: '',
        type: 'text'
      },
      {
        name: 'lastName',
        label: 'Last name',
        value: '',
        type: 'text'
      },
      {
        name: 'dateOfBirth',
        label: 'Date of birth',
        value: '',
        type: 'date'
      },
      {
        name: 'phone',
        label: 'Phone',
        value: '',
        type: 'phone'
      },
      {
        name: 'address',
        label: 'Address',
        value: '',
        type: 'text'
      },
      {
        name: 'city',
        label: 'City',
        value: '',
        type: 'text'
      },
      {
        name: 'state',
        label: 'State',
        value: '',
        type: 'state'
      },
      {
        name: 'zipCode',
        label: 'ZIP Code',
        value: '',
        type: 'zip'
      }
    ],
    financial: [
      {
        name: 'employmentStatus',
        label: 'Employment status',
        value: '',
        type: 'employmentStatus'
      },
      {
        name: 'debt',
        label: 'Total debt',
        value: 0,
        type: 'currency'
      },
      {
        name: 'annualIncome',
        label: 'Total annual income',
        value: 0,
        type: 'currency'
      },
      {
        name: 'investableAssets',
        label: 'Total investable assets',
        value: 0,
        type: 'currency'
      }
    ],
  },
  userName: '',
  userState: {
    hasSeenPfm: false,
    registeredRtm: false,
    journeys: [],
  },
  userConnections: [],
  interests: initialInterests,
  interestsList: [],
  rateAlerts: [],
  loanOffers: [],
  sweepstakesEntry: false,
  betaFeatures: hasAllowedAuthRoles([AuthRoles.RoleInternal]), // Temporarily only for RV email accounts
  journey: journeyInitialState,
};

const replaceFieldMap = (newFields: ProfileFieldInterface[]) => (oldField: ProfileFieldInterface) => newFields.reduce(
  (acc: ProfileFieldInterface | null, newField: ProfileFieldInterface) =>
    newField.name === oldField.name
      ? {
        ...oldField,
        ...newField,
      }
      : acc,
  null
) || oldField;

const replaceInterestMap = (newInterests: InterestsInterface[]) => (oldInterest: InterestsInterface) => newInterests.reduce(
  (acc: InterestsInterface | null, newInterest: InterestsInterface) =>
    newInterest.name === oldInterest.name
      ? {
        ...oldInterest,
        ...newInterest,
      }
      : acc,
  null
) || oldInterest;
// eslint-disable-next-line
function defaultReducer(state: RootState, action: any) : RootState {
  switch (action.type) {
    case UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          id: action.profile.id,
          personal: state.profile.personal.map(replaceFieldMap(action.profile.personal)),
          financial: state.profile.financial.map(replaceFieldMap(action.profile.financial)),
        },
      };
    case UPDATE_PERSONAL_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          personal: state.profile.personal.map(replaceFieldMap(action.personalProfile)),
        },
      };
    case UPDATE_FINANCIAL_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          financial: state.profile.financial.map(replaceFieldMap(action.financialProfile)),
        },
      };
    case UPDATE_INTERESTS:
      return {
        ...state,
        interests: state.interests.map(replaceInterestMap(action.interests)),
      };
    case UPDATE_INTERESTS_LIST:
      return {
        ...state,
        interestsList: action.interests,
      };
    case UPDATE_USER_NAME:
      return {
        ...state,
        userName: action.userName,
      };
    case UPDATE_RATE_ALERTS_DATA:
      return {
        ...state,
        rateAlerts: action.rateAlertsData,
      };
    case UPDATE_LOAN_OFFERS:
      return {
        ...state,
        loanOffers: action.loanOffers,
      };
    case UPDATE_USER_STATE:
      return {
        ...state,
        userState: action.userState,
      };
    case UPDATE_USER_CONNECTIONS:
      return {
        ...state,
        userConnections: action.userConnections
      }

    default:
      return state;
  }
}

const rootReducer = mergeReducers(
  defaultReducer,
  journeyReducer,
)

const store = createStore(
  rootReducer,
  initialState,
  // eslint-disable-next-line
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
  // eslint-disable-next-line
  (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
