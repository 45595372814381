import { ProfileInterface, ProfileFieldInterface, ProfileUserStateInterface } from '../components/Profile/ProfileInterface';
import BedrockProfileType, { BedrockProfileRequestType } from '../BedrockProfileType';

// translate from AUX Profile to Bedrock Profile

const profileField = (profile: ProfileFieldInterface[], name: string) =>
  profile.reduce((acc: string | number | Date | null, field: ProfileFieldInterface) => {
    if (field.name === name) {
      let date: string | null;
      switch (field.type) {
        case 'date':
          try {
            date = new Date(field.value as string).toISOString().slice(0, 10);
            return date;
          } catch (e) {
            // on date conversion, clear out the value
            date = null;
          }
          return date;
        case 'phone':
          return (field.value as string).match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) ? field.value : null;
        case 'zip':
          return (field.value as string).match(/^[0-9]{5}(?:-[0-9]{4})?$/) ? field.value : null;
        default:
          return field.value || null;
      }
    }
    return acc;
  }, null);

export const fromPersonalToBedrockProfile = (fromProfile: ProfileFieldInterface[]): BedrockProfileRequestType => {
  return {
    personal: {
      address_city: profileField(fromProfile, 'city') as (string | null),
      address_state: profileField(fromProfile, 'state') as (string | null),
      address_street: profileField(fromProfile, 'address') as (string | null),
      address_zip: profileField(fromProfile, 'zipCode') as (string | null),
      date_of_birth: profileField(fromProfile, 'dateOfBirth') as (string | null),
      email: profileField(fromProfile, 'email') as (string | null),
      first_name: profileField(fromProfile, 'firstName') as (string | null),
      last_name: profileField(fromProfile, 'lastName') as (string | null),
      phone: profileField(fromProfile, 'phone') as (string | null),
    },
  };
};

export const fromFinancialToBedrockProfile = (fromProfile: ProfileFieldInterface[]): BedrockProfileRequestType => {
  return {
    personal:  {
      employment_status: profileField(fromProfile, 'employmentStatus') as (string | null),
      total_debt: profileField(fromProfile, 'debt') as (string | null),
      total_income: profileField(fromProfile, 'annualIncome') as (string | null),
      total_investable_assets: profileField(fromProfile, 'investableAssets') as (string | null),
    },
  };
};

const transformDateValue = (inputValue: Date): string => {
  const date = inputValue.toISOString();
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  return `${month}/${day}/${year}`;
}

// translate from Bedrock Profile to AUX Profile
const bedrockProfileField = (profile: BedrockProfileType, name: string, type?: string): string | number => {
  if (Object.prototype.hasOwnProperty.call(profile.personal, name)) {
    const fieldValue = profile.personal[name];
    switch (type) {
      case 'date':
        return fieldValue ? transformDateValue(new Date(Date.parse(fieldValue))) : '';
      case 'currency':
      case 'number':
        return Number.parseFloat(fieldValue) || 0;
      case 'phone': {
        if (!fieldValue) {
          return '';
        }
        let numbers = fieldValue.match(/\d/g).join('');
        let withHypens;
        // formatting phone to support legacy users that were created before validation existed
        if (numbers.length === 7) {
          withHypens = `${numbers.substr(0, 3)}-${numbers.substr(3, 4)}`
        } else if (numbers.length === 10) {
          withHypens = `${numbers.substr(0, 3)}-${numbers.substr(3, 3)}-${numbers.substr(6, 4)}`;
        } else {
          numbers = numbers.substr(1, 11);
          withHypens = `${numbers.substr(0, 3)}-${numbers.substr(3, 3)}-${numbers.substr(6, 4)}`;
        }     
        
        return withHypens;
      }
      default:
        return fieldValue || '';
    }
  }
  return ''; // default to returning an empty string
};

export const fromBedrockProfile = (fromProfile: BedrockProfileType): ProfileInterface => {
  return {
    id: fromProfile.id,
    personal: [
      {
        name: 'email',
        value: bedrockProfileField(fromProfile, 'email'),
      },
      {
        name: 'firstName',
        value: bedrockProfileField(fromProfile, 'first_name'),
      },
      {
        name: 'lastName',
        value: bedrockProfileField(fromProfile, 'last_name'),
      },
      {
        name: 'dateOfBirth',
        value: bedrockProfileField(fromProfile, 'date_of_birth', 'date'),
      },
      {
        name: 'phone',
        value: bedrockProfileField(fromProfile, 'phone', 'phone'),
      },
      {
        name: 'address',
        value: bedrockProfileField(fromProfile, 'address_street'),
      },
      {
        name: 'city',
        value: bedrockProfileField(fromProfile, 'address_city'),
      },
      {
        name: 'state',
        value: bedrockProfileField(fromProfile, 'address_state'),
      },
      {
        name: 'zipCode',
        value: bedrockProfileField(fromProfile, 'address_zip'),
      }
    ],
    financial: [
      {
        name: 'employmentStatus',
        value: bedrockProfileField(fromProfile, 'employment_status'),
      },
      {
        name: 'debt',
        value: bedrockProfileField(fromProfile, 'total_debt', 'currency'),
      },
      {
        name: 'annualIncome',
        value: bedrockProfileField(fromProfile, 'total_income', 'currency'),
      },
      {
        name: 'investableAssets',
        value: bedrockProfileField(fromProfile, 'total_investable_assets', 'currency'),
      }
    ],
  };
}


export const fromBedrockProfileToUserState = (fromProfile: BedrockProfileType): ProfileUserStateInterface => {
  return {
    hasSeenPfm: fromProfile.user_state?.has_seen_pfm || false,
    registeredRtm: fromProfile.user_state?.registered_rtm || false,
    journeys: fromProfile.user_state?.journeys || [],
  };
}
