import React from 'react';
import Rollbar from 'rollbar';

export const logger = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN ? new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT
  },
  // eslint-disable-next-line
  checkIgnore: (isUncaught, args, payload: { [key: string]: any }): boolean => {
    /**
     * Uncaught errors are bubbled up to the window.onerror method. Since
     * we have no control over third-party script errors, rather than not log them
     * entirely, we set their log level to warn so we can still capture them
     */
    if (isUncaught) {
      payload.level = 'warning';

      // Adds a message to Rollbar context for routing
      payload.context = `Uncaught Error: ${JSON.stringify(args)}`;
    } else {
      payload.context = `Caught Error: ${JSON.stringify(args)}`;
    }

    // returning true ignores the payload entirely, so we return false here
    return false;
  },
  scrubTelemetryInputs: true,
}) : console;


class RollbarErrorBoundary extends React.Component<{children: React.FC[]}> {

  componentDidCatch(
    error: Rollbar.LogArgument,
    errorInfo: Rollbar.LogArgument
  ): void {
    console.log('REACT_APP_ROLLBAR_ACCESS_TOKEN', process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN);
    console.log('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
    if (logger instanceof Rollbar) {
      console.log('SHIPPING ROLLBAR ERROR', error);
      logger.error(error); // @TODO fine-grain error-levels (but keep this "error" level?)
    } else {
      console.error('LOCAL ROLLBAR ERROR', error, errorInfo);
    }
  }

  render(): React.FC[] & React.ReactNode {
    return this.props?.children;
  }
}

export default RollbarErrorBoundary;


