import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { getAccessToken } from '../utils/authorization';

/* Tagular interface //-> */

// eslint-disable-next-line 
type beam = (a: string, b: string, c: any, d?: boolean, e?: boolean) => void;

interface WebContext {
  sessionId: string;
  anonymousId: string;
  instanceId: string;
  userAgent: string;
}

declare global {
  interface Window {
    tagular: beam;
    _Tagular: {
      webContext: WebContext
    };
    cohesion: (arg: string, cb: () => void) => void;
  }
}

let tagular: beam | null = null;
export let tagularWebContext: WebContext | null = null;

// wait for cohesion to load
if (window.cohesion) {
  window.cohesion('ready', function() {
    console.log('cohesion loaded');
    tagular = window.tagular ? window.tagular : null;
    tagularWebContext = (window._Tagular && window._Tagular.webContext) || null;
  });
} else {
  console.error('Cohesion not found');
}

/* <-// Tagular interface */

let PROFILE_EDIT_FORM_ID: string | null = null;
let PROFILE_EDIT_STEP_ID: string | null = null;
let CORRELATION_ID: string | null = null;
let LIST_ID: string | null = null;

let oldToken: string | null = null;

const setUUIDs = (): void => {
  const newToken = getAccessToken();

  if (oldToken === newToken) return;

  PROFILE_EDIT_FORM_ID = uuidv4();
  PROFILE_EDIT_STEP_ID = uuidv4();
  CORRELATION_ID = uuidv4();
  LIST_ID = uuidv4();

  oldToken = newToken;
};

interface AutheticatedContext {
  userId: string | null;
  appTenantId: 'bankrate';
  sessionId: string | null;
  tenantVersion: '0.0.1';
  pageUrl: string;
  pageReferrer: string;
  pageTitle: string;
  anonymousId: string | null;
  instanceId: string | null;
  manualInstanceId: string;
  userAgent: string | null;
}

interface WebElement {
  location: string;
  position: string;
  elementType: string;
  text: string;
  htmlId: string;
}

interface Product {
  category: string;
  location: string;
  name: string;
  position: number;
  productId: string;
}

interface BedrockContext {
  customerId: string | null,
  clientId: string,
  appTenantId: string,
  sessionId: string | null,
  instanceId: string | null
}

interface FieldProperty {
  fieldName: string,
  fieldLabel: string,
  fieldType: string,
  fieldValue: string
}

interface ElementClickedProperties {
  '@type': 'redventures.usertracking.v3.ElementClicked';
  // authenticatedContext: AutheticatedContext;
  webElement: WebElement;
  actionOutcome: string;
  outboundUrl: string;
  correlationId: string | null;
}

interface ProductClickedProperties {
  '@type': 'redventures.ecommerce.v1.ProductClicked';
  product: Product;
  listId: string | null;
  correlationId: string | null;
}

interface ProductViewedProperties {
  '@type': 'redventures.ecommerce.v1.ProductViewed';
  product: Product;
  listId: string | null;
  correlationId: string | null;
}

interface ProfileUpdatedProperties {
  '@type': 'redventures.bedrock.v1.AuthenticatedProfileUpdated';
  bedrockContext: BedrockContext;
  field: FieldProperty[];
}

interface PageViewedProperties {
  '@type': 'redventures.authenticated.v1alpha1.PageViewed';
  authenticatedContext: AutheticatedContext;
}

interface ElementViewedProperties {
  '@type': 'redventures.usertracking.v3.ElementViewed';
  // webContext: WebContext;
  webElement: WebElement;
}

interface FormContext {
  formName: string;
  formType: string;
  formId: string | null;
}

interface StepContext {
  stepName: string;
  stepType: string;
  stepId: string | null;
}

interface CaptureContext {
  capturePlacement: string;
  captureType: string;
  identityRequested: boolean;
  valueProp: string;
}
interface FormViewedProperties {
  '@type': 'redventures.usertracking.v3.FormViewed';
  captureContext: CaptureContext;
  formContext: FormContext;
}
interface FormStartedProperties {
  '@type': 'redventures.authenticated.v1alpha1.FormStarted';
  authenticatedContext: AutheticatedContext;
  formContext: FormContext;
  stepContext: StepContext;
  correlationId: string | null;
}

interface FormSubmittedProperties {
  '@type': 'redventures.authenticated.v1alpha1.FormSubmitted';
  authenticatedContext: AutheticatedContext;
  formContext: FormContext;
  stepContext: StepContext;
  field?: FormSubmittedFieldInterface[];
  correlationId: string | null;
}

interface FormContinuedProperties {
  '@type': 'redventures.authenticated.v1alpha1.FormContinued';
  authenticatedContext: AutheticatedContext;
  formContext: FormContext;
  stepContext: StepContext;
  field?: FormSubmittedFieldInterface[];
  correlationId: string | null;
}

type Properties = ElementClickedProperties | ElementViewedProperties | PageViewedProperties | FormStartedProperties | FormViewedProperties| FormSubmittedProperties | ProductClickedProperties | ProductViewedProperties | ProfileUpdatedProperties | FormContinuedProperties;

const track = (
  type: string,
  properties: Properties
) => {
  //For profile updated events we need to set includeContext to false
  //and set renewSession to true to keep it as the default
  if(tagular && type === "AuthenticatedProfileUpdated"){
    tagular('beam', type, properties, true, false);
  } else if (tagular) {
    tagular('beam', type, properties);
  } else if (process.env.REACT_APP_DEBUG) {
    console.info('TRACK', type, properties);
  }
}

const getAuthenticatedContext = () => {
  const token = getAccessToken();

  return {
    userId: token ? (jwtDecode(token) as CustomerInterface).customer_id : null,
    appTenantId: 'bankrate',
    sessionId: tagularWebContext && tagularWebContext.sessionId,
    tenantVersion: '0.0.1',
    pageUrl: window.location.toString(),
    pageReferrer: document.referrer,
    pageTitle: document.title,
    anonymousId: tagularWebContext && tagularWebContext.anonymousId,
    instanceId: tagularWebContext && tagularWebContext.instanceId,
    manualInstanceId: uuidv4(),
    userAgent: tagularWebContext && tagularWebContext.userAgent,
  } as AutheticatedContext;
}

export interface PageViewedInterface {
  pageUrl: string;
  pageTitle: string;
}

interface CustomerInterface {
  customer_id: string;
}

export const pageViewed = (data: PageViewedInterface): void => {
  track('PageViewed', {
    '@type': 'redventures.authenticated.v1alpha1.PageViewed',
    authenticatedContext: {
      ...getAuthenticatedContext(),
      ...data
    },
  });
}

export interface ElementClickedInterface {
  location: string;
  position: string;
  elementType: string;
  text: string;
  htmlId: string;
  actionOutcome: string;
  outboundUrl: string; // not used for no page/router change
}

export interface ProductInterface {
  category: string;
  location: string;
  productName: string;
  position: number;
  productId: string;
  purchaseId?: string;
}

export interface ProfileUpdatedInterface {
  fieldName: string;
  fieldLabel: string;
  fieldType: string;
  fieldValue: string;
}

export interface ElementViewedInterface {
  location: string;
  position: string;
  elementType: string;
  text: string;
  htmlId: string;
}

const formatTrackingString = (value: string) => {
  return value.replace(/\s/g, "_");
}

export const elementClicked = (data: ElementClickedInterface): void => {
  const {
    location,
    position,
    elementType,
    text,
    htmlId,
    actionOutcome,
    outboundUrl
  } = data;

  track('ElementClicked', {
    '@type': 'redventures.usertracking.v3.ElementClicked',
    webElement: {
      location: formatTrackingString(location),
      position,
      elementType,
      text: formatTrackingString(text),
      htmlId: formatTrackingString(htmlId),
    },
    // authenticatedContext: {
    //   ...getAuthenticatedContext(),
    // },
    actionOutcome,
    outboundUrl,
    correlationId: CORRELATION_ID,
  });
}

export const elementViewed = (data: ElementViewedInterface): void => {
  const {
    location,
    position,
    elementType,
    text,
    htmlId,
  } = data;

  track('ElementViewed', {
    '@type' : 'redventures.usertracking.v3.ElementViewed',
    webElement: {
      location,
      position,
      elementType,
      text,
      htmlId,
    },
  });
}

export interface FormStartedInterface {
  formName: string;
  formType: string;
  stepName: string;
  stepType: string;
}

export const formStarted = (data: FormStartedInterface): void => {
  setUUIDs();

  const {
    formName,
    formType,
    stepName,
    stepType
  } = data;

  track('FormStarted', {
    '@type': 'redventures.authenticated.v1alpha1.FormStarted',
    formContext: {
      formName,
      formType,
      formId: PROFILE_EDIT_FORM_ID,
    },
    stepContext: {
      stepName,
      stepType,
      stepId: PROFILE_EDIT_STEP_ID,
    },
    authenticatedContext: getAuthenticatedContext(),
    correlationId: CORRELATION_ID,
  });
}

export interface FormSubmittedFieldInterface {
  fieldType: string;
  fieldName: string;
  fieldValue: string;
  autofilled: boolean;
}

export interface FormSubmittedInterface {
  formName: string;
  formType: string;
  stepName: string;
  stepType: string;
  field?: FormSubmittedFieldInterface[];
}

export const formSubmitted = (data: FormSubmittedInterface): void => {
  setUUIDs();

  const {
    formName,
    formType,
    stepName,
    stepType,
    field,
  } = data;

  track('FormSubmitted', {
    '@type': 'redventures.authenticated.v1alpha1.FormSubmitted',
    formContext: {
      formName,
      formType,
      formId: PROFILE_EDIT_FORM_ID,
    },
    stepContext: {
      stepName,
      stepType,
      stepId: PROFILE_EDIT_STEP_ID,
    },
    field,
    authenticatedContext: getAuthenticatedContext(),
    correlationId: CORRELATION_ID,
  });
}

export interface FormContinuedFieldInterface {
  fieldType: string;
  fieldName: string;
  fieldValue: string;
  autofilled: boolean;
}

export interface FormContinuedInterface {
  formName: string;
  formType: string;
  stepName: string;
  stepType: string;
  field?: FormSubmittedFieldInterface[];
}

export const formContinued = (data: FormContinuedInterface): void => {
  setUUIDs();

  const {
    formName,
    formType,
    stepName,
    stepType,
    field,
  } = data;

  track('FormContinued', {
    '@type': 'redventures.authenticated.v1alpha1.FormContinued',
    formContext: {
      formName,
      formType,
      formId: PROFILE_EDIT_FORM_ID,
    },
    stepContext: {
      stepName,
      stepType,
      stepId: PROFILE_EDIT_STEP_ID,
    },
    field,
    authenticatedContext: getAuthenticatedContext(),
    correlationId: CORRELATION_ID,
  });
}

export interface FormViewiedInterface {
  capturePlacement: string;
  captureType: string;
  identityRequested: boolean;
  valueProp: string;
  formName: string;
  formType: string;
}
export const formViewed = (data: FormViewiedInterface): void => {
  setUUIDs();

  const {
    formName,
    formType,
    capturePlacement,
    captureType,
    identityRequested,
    valueProp
  } = data;

  track('FormViewed', {
    '@type': 'redventures.usertracking.v3.FormViewed',
    formContext: {
      formName,
      formType,
      formId: PROFILE_EDIT_FORM_ID,
    },
    captureContext: {
      capturePlacement,
      captureType,
      identityRequested,
      valueProp
    }
  });
}

export const productClicked = (data: ProductInterface): void => {
  const {
    category,
    location,
    productName,
    position,
    productId,
    purchaseId,
  } = data;

  track('ProductClicked', {
    '@type': 'redventures.ecommerce.v1.ProductClicked',
    product: {
      category,
      location,
      name: productName,
      position,
      productId,
    },
    correlationId: purchaseId ? purchaseId : CORRELATION_ID,
    listId: LIST_ID,
  });
}

export const productViewed = (data: ProductInterface): void => {
  const {
    category,
    location,
    productName,
    position,
    productId,
  } = data;

  track('ProductViewed', {
    '@type': 'redventures.ecommerce.v1.ProductViewed',
    product: {
      category,
      location,
      name: productName,
      position,
      productId,
    },
    correlationId: CORRELATION_ID,
    listId: LIST_ID,
  });
}

export const profileUpdated = (data: ProfileUpdatedInterface): void => {
  const token = getAccessToken();

  const {
    fieldName,
    fieldLabel,
    fieldType,
    fieldValue
  } = data;

  track('AuthenticatedProfileUpdated', {
    '@type': 'redventures.bedrock.v1.AuthenticatedProfileUpdated',
    bedrockContext: {
      customerId: token ? (jwtDecode(token) as CustomerInterface).customer_id : null,
      appTenantId: "5dcd00b6-86e4-11e8-adc0-fa7ae01bbebc",
      clientId: "bankrate-web",
      sessionId: tagularWebContext && tagularWebContext.sessionId,
      instanceId: tagularWebContext && tagularWebContext.instanceId
    },
    field:[{
      fieldName,
      fieldLabel,
      fieldType,
      fieldValue
    }]
  });
}

export default {
  pageViewed,
  elementClicked,
  elementViewed,
  formStarted,
  formSubmitted
}
