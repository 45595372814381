import React from 'react';
import { CommonBaseProps } from '../Types';
import './TrayContainer.scss';

type Props = {
  children: JSX.Element;
  borderOrientation?: 'Top' | 'Bottom' | 'All';
  borderRadius?: string;
  containerClassName?: string;
  containerStyles?: React.CSSProperties;
} & CommonBaseProps;

const TrayContainer: React.FC<Props> = ({className = '', dataTestId = '', children, borderOrientation, borderRadius = '8px', containerClassName = '', containerStyles = {} }: Props) => {
  const contentStyles: React.CSSProperties = {};
  
  if (borderOrientation) {
    switch (borderOrientation) {
      case 'All':
        contentStyles['borderRadius'] = borderRadius;
        break;
      case 'Bottom':
        contentStyles['borderRadius'] = `0 0 ${borderRadius} ${borderRadius}`;
        break;
      case 'Top':
        contentStyles['borderRadius'] = `${borderRadius} ${borderRadius} 0 0`;
        break;
      default:
    }
  }
  
  return (
    <div 
      style={containerStyles}
      className={`tray-container +z-layer-five +display-flex +justify-content-center ${containerClassName}`}
      data-testid={dataTestId}
    >
      <div className={`tray-container__content +display-flex +justify-content-center +align-items-flex-start ${className}`} style={contentStyles}>
        { children }
      </div>
    </div>
  );
};

export default TrayContainer;
