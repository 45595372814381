import store from './'
import { logger } from '../RollbarErrorBoundary'; // @TODO split this into a service
import { ProfileInterface, ProfileFieldInterface, ProfileUserStateInterface, JourneyUserStateInterface } from '../components/Profile/ProfileInterface';
import { RateAlertInterface, RateAlertSubscriptionInterface } from '../components/Profile/ProfileRateAlertsInterface';
import { InterestsInterface } from '../components/Interests/InterestsInterface';
import { get, patch, post } from '../services/http';
import { fetchLoanOffers, LoansProductOffers } from '../services/loans';
import BedrockProfileType, { UserConnection } from '../BedrockProfileType';
import {
  fromBedrockProfile,
  fromPersonalToBedrockProfile,
  fromFinancialToBedrockProfile,
  fromBedrockProfileToUserState,
} from '../utils/bedrockProfileTranslator';
import { initialInterests } from '../data/InitialInterests';
import { LogArgument } from 'rollbar';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PERSONAL_PROFILE = 'UPDATE_PERSONAL_PROFILE';
export const UPDATE_FINANCIAL_PROFILE = 'UPDATE_FINANCIAL_PROFILE';
export const UPDATE_INTERESTS = 'UPDATE_INTERESTS';
export const UPDATE_INTERESTS_LIST = 'UPDATE_INTERESTS_LIST';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_RATE_ALERTS_DATA = 'UPDATE_RATE_ALERTS_DATA';
export const UPDATE_LOAN_OFFERS = 'UPDATE_LOAN_OFFERS';
export const UPDATE_USER_CONNECTIONS = 'UPDATE_USER_CONNECTIONS';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const UPDATE_INTRO_SCREENS = 'UPDATE_INTRO_SCREENS';

const AUX_PROFILE_API_URL = process.env.REACT_APP_AUX_PROFILE_API_URL as string;
const AUX_ITERABLE_API_URL = process.env.REACT_APP_AUX_ITERABLE_API_URL as string;
const AUX_USER_API_URL = process.env.REACT_APP_AUX_USER_API_URL as string;

const AUX_SWEEPSTAKES_BASE_URL = process.env.REACT_APP_AUX_SWEEPSTAKES_API_BASE_URL as string;

// actions
export function updateError(error: string | null): {[key: string]: string | null} {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

function updateLoading(loading: boolean) {
  return {
    type: UPDATE_LOADING,
    loading,
  };
}

function updateProfile(profile: ProfileInterface) {
  return {
    type: UPDATE_PROFILE,
    profile,
  };
}

function updatePersonalProfile(personalProfile: ProfileFieldInterface[]) {
  return {
    type: UPDATE_PERSONAL_PROFILE,
    personalProfile,
  };
}

function updateFinancialProfile(financialProfile: ProfileFieldInterface[]) {
  return {
    type: UPDATE_FINANCIAL_PROFILE,
    financialProfile,
  };
}

function updateInterests(interests: InterestsInterface[]) {
  return {
    type: UPDATE_INTERESTS,
    interests,
  }
}

function updateInterestsList(interests: string[]) {
  return {
    type: UPDATE_INTERESTS_LIST,
    interests,
  }
}

function updateUserName(userName: string) {
  return {
    type: UPDATE_USER_NAME,
    userName,
  }
}

function updateRateAlertsData(rateAlertsData: RateAlertSubscriptionInterface[]) {
  return {
    type: UPDATE_RATE_ALERTS_DATA,
    rateAlertsData,
  }
}

function updateLoanOffers(loanOffers: LoansProductOffers[]) {
  return {
    type: UPDATE_LOAN_OFFERS,
    loanOffers,
  }
}

function updateUserConnections(userConnections: UserConnection[]) {
  return {
    type: UPDATE_USER_CONNECTIONS,
    userConnections,
  }
}

function updateUserState(userState: ProfileUserStateInterface) {
  return {
    type: UPDATE_USER_STATE,
    userState,
  }
}

function getAnonymousId(): string | null {
  // eslint-disable-next-line
  let tagularWebContext: { [key: string]: any } = {}
  if (window._Tagular && window._Tagular.webContext) {
    tagularWebContext = window._Tagular.webContext;
  }
  return tagularWebContext.anonymousId;
}
// eslint-disable-next-line
function getSessionId(): string | null {
  // eslint-disable-next-line
  let tagularWebContext: { [key: string]: any } = {}
  if (window._Tagular && window._Tagular.webContext) {
    tagularWebContext = window._Tagular.webContext;
  }
  return tagularWebContext.sessionId;
}
// eslint-disable-next-line
function getInstanceId(): string | null {
  // eslint-disable-next-line
  let tagularWebContext: { [key: string]: any } = {}
  if (window._Tagular && window._Tagular.webContext) {
    tagularWebContext = window._Tagular.webContext;
  }
  return tagularWebContext.instanceId;
}

type TrackingHeaders = {
  'X-Anonymous-ID': string,
  'X-Session-ID': string,
  'X-Instance-ID': string
}
function getTrackingHeaders() : TrackingHeaders {
  const anonymousId = getAnonymousId();
  const sessionId = getSessionId();
  const instanceId = getInstanceId();
  return {
    'X-Anonymous-ID': anonymousId || '',
    'X-Session-ID': sessionId || '',
    'X-Instance-ID': instanceId || ''
  }
}

// bound actions

export function updateLoadingState(loading: boolean): void {
  store.dispatch(updateLoading(loading));
}

export async function loadProfile(): Promise<void> {
  /**
   * This conditional grabs the users email address, signifying that we have
   * made a successful request from the profile API and received data back
   */
  if (store.getState().profile.personal[0].value) {
    return;
  }

  try {
    const profile: BedrockProfileType = await get(AUX_PROFILE_API_URL);
    store.dispatch(updateProfile(fromBedrockProfile(profile)));
    store.dispatch(updateUserState(fromBedrockProfileToUserState(profile)));
    const userConnections: UserConnection[] = await get(`${AUX_USER_API_URL}/${profile.id}/connections`);
    store.dispatch(updateUserConnections(userConnections));

    /**
     * Interests related information
     */
    const goals = profile.goal_names;

    if (goals) {
      const selected = initialInterests.map((i) => ({
        ...i,
        selected: goals.includes(i.name),
      }));

      store.dispatch(updateInterestsList(goals));
      store.dispatch(updateInterests(selected));
    }


  } catch (e) {
    store.dispatch(updateError('Error loading profile'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}

export async function savePersonalProfile(personalProfile: ProfileFieldInterface[]): Promise<void> {
  store.dispatch(updateError(null));
  try {
    const headers = getTrackingHeaders();
    const updatedProfile = (await patch(AUX_PROFILE_API_URL, fromPersonalToBedrockProfile(personalProfile), headers)) as BedrockProfileType;
    const userName = updatedProfile.personal.first_name;

    store.dispatch(updatePersonalProfile(fromBedrockProfile(updatedProfile).personal));

    if (userName) {
      store.dispatch(updateUserName(userName));
    }

    window.refreshBaconProfile && window.refreshBaconProfile();
  } catch (e) {
    store.dispatch(updateError('Error saving personal profile'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}

export async function saveFinancialProfile(financialProfile: ProfileFieldInterface[]): Promise<void> {
  store.dispatch(updateError(null));
  try {
    const headers = getTrackingHeaders();
    const updatedProfile = (await patch(AUX_PROFILE_API_URL, fromFinancialToBedrockProfile(financialProfile), headers)) as BedrockProfileType;
    store.dispatch(updateFinancialProfile(fromBedrockProfile(updatedProfile).financial));
    window.refreshBaconProfile && window.refreshBaconProfile();
  } catch (e) {
    store.dispatch(updateError('Error saving financial profile'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}

export async function saveInterests(interests: InterestsInterface[]): Promise<void> {
  const selectedInterests = interests
    .filter((interest: InterestsInterface) => interest.selected)
    .map((interest: InterestsInterface) => interest.name);

  try {
    const headers = getTrackingHeaders();
    const response: BedrockProfileType = (
      await patch(AUX_PROFILE_API_URL, { goal_names: selectedInterests }, headers)
    );

    const goals = response.goal_names;
    if (goals) {
      const selected = initialInterests.map((i) => ({
        ...i,
        selected: goals.includes(i.name),
      }));

      store.dispatch(updateInterestsList(goals));
      store.dispatch(updateInterests(selected));
    }
  }
  catch (e) {
    store.dispatch(updateError('Error saving interests'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}

// eslint-disable-next-line
export async function registerForSweepstakes(personalProfile: ProfileFieldInterface[]): Promise<boolean> {
  const bedrockId = store.getState().profile.id;
  const hasSeenPfm = store.getState().userState.hasSeenPfm;
  const reqBody = {
    unique_id: bedrockId,
    ...fromPersonalToBedrockProfile(personalProfile).personal,
  }
  const updatedUserState = fromBedrockProfileToUserState({
    id: '',
    personal: {},
    user_state: {
      has_seen_pfm: hasSeenPfm,
      registered_rtm: true,
    }
  })

  store.dispatch(updateUserState(updatedUserState))

  try {
    await post(`${AUX_SWEEPSTAKES_BASE_URL}/register`, reqBody);
    return true;
  } catch (e) {
    logger.error(e as LogArgument);
    console.error(e);
    return false;
  }
}

// eslint-disable-next-line
export async function loadRateAlerts() {
  /**
   * If we have already received a value from the rate alert API, return
   */
  if (store.getState().rateAlerts.length) {
    return;
  }

  try {
    const response: RateAlertInterface = await get(AUX_ITERABLE_API_URL);
    const formattedResponse: RateAlertSubscriptionInterface[] = response.messageTypes.map((sub) => ({
      typeId: sub.typeId,
      subscribed: sub.subscribed
    }));

    store.dispatch(updateRateAlertsData(formattedResponse));
  }
  catch (e) {
    store.dispatch(updateError('Error loading rate alerts'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}
// eslint-disable-next-line
export async function saveRateAlerts(isSubscribed: boolean, subscriptionId: number) {
  store.dispatch(updateError(null));

  const initialSubscriptions = store.getState().rateAlerts;
  const data: { [key: string]: number[] } = {};

  if (isSubscribed) {
    data.subscribe = [subscriptionId]
  } else {
    data.unsubscribe = [subscriptionId]
  }

  try {
    await post(AUX_ITERABLE_API_URL, data);
    const updatedSubscriptions = initialSubscriptions.map((initial: RateAlertSubscriptionInterface) => ({
      typeId: initial.typeId,
      subscribed: subscriptionId === initial.typeId ? isSubscribed : initial.subscribed,
    }))

    store.dispatch(updateRateAlertsData(updatedSubscriptions));

  } catch (e) {
    // @TODO add error handling
    store.dispatch(updateError('Error saving rate alerts'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}

// Vertical Integrations
// eslint-disable-next-line
export async function loadLoanOffers() {
  try {
    const loanOffers = await fetchLoanOffers();

    store.dispatch(updateLoanOffers(loanOffers));
  } catch (e) {
    logger.error(e as LogArgument);
    console.error(e);
  }
}

export async function loadUserConnections(bedrockId: string): Promise<void> {
  try {
    const response = await get(`${AUX_USER_API_URL}/${bedrockId}/connections`);
    store.dispatch(updateUserConnections(response));
  } catch (e) {
    store.dispatch(updateError('Error fetching user connections'));
    logger.error(e as LogArgument);
    console.error(e);
  }
}

/**
 * Aggregates current userState to
 * make user_state updates to the database
 */
const getAggregatedUserState = () => {
  const userState = store.getState().userState;

  return {
    ...userState,
  };
}

/**
 * Updates a users Journey User State in their profile by either adding an item to the
 * journey array or finding and updating the existing one.
 */
export async function updateJourneyUserState(journeyUserState: JourneyUserStateInterface): Promise<void> {
  const userState = store.getState().userState;
  const journeys: JourneyUserStateInterface[] = userState.journeys;
  const currentIndex = journeys.findIndex((item: JourneyUserStateInterface) => item.journey_slug === journeyUserState.journey_slug);
  
  if (currentIndex > -1) {
    journeys[currentIndex] = { ...journeys[currentIndex], ...journeyUserState };
  } else {
    journeys.push(journeyUserState);
  }
  
  const aggregatedUserState = getAggregatedUserState();
  const updatedUserState = { ...aggregatedUserState, journeys };
  try {
    await patch(AUX_PROFILE_API_URL, { user_state: updatedUserState });
    store.dispatch(updateUserState({ ...userState, journeys }));
  } catch (e) {
    logger.error(e as LogArgument);
    console.error(e);
  }
}