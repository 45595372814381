import React from 'react';

interface Props {
  style?: { [key: string]: string | number };
  className?: string;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void);
  width?: string;
  height?: string;
}

const CloseSvg: React.FC<Props> = (props: Props) => {
  return (
    <button onClick={props.onClick} className={props.className} style={props.style}>
      <span>
        <svg id="close-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none">
          <path id="close-path" fillRule="evenodd" clipRule="evenodd" d="M12 10.5858L4.22183 2.80762L2.80762 4.22183L10.5858 12L2.80762 19.7782L4.22183 21.1924L12 13.4142L19.7782 21.1924L21.1924 19.7782L13.4142 12L21.1924 4.22183L19.7782 2.80762L12 10.5858Z" fill="#151515" />
        </svg>
      </span>
    </button>
  )
}

export default CloseSvg;
