import React from 'react';
import { CommonBaseProps } from '../../../Common/Types';
import './Dropdown.scss';

export type DropdownOption = {
  label?: string;
  value: string;
}

export type Props = {
  label: string;
  options?: DropdownOption[];
  selected?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
} & CommonBaseProps;

const Dropdown: React.FC<Props> = (props: Props) => {
  const { className = "", label, options = [], onChange = (_e) => { return; }, selected = false } = props;

  return (
    <div className={`+display-flex +mg-all-sm journey-dropdown ${className}`}>
      <label className="+mg-right-xs journey-dropdown__label">{label}:</label>
      <select className="journey-dropdown__select" onChange={onChange}>
        { options.map(o => <option selected={o.value === selected} className={"+pd-all-none"} key={o.value} value={o.value}>{o.label || o.value}</option>) }
      </select>
    </div>
  )
}

export default Dropdown;