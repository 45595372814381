import React from 'react';
interface Props {
  fill?: string;
  height?: string;
  width?: string;
}
const CheckMark: React.FC<Props> = (props: Props) => {
  return (
      <svg width={props.width ? props.width: '18px'} height={props.width ? props.height : '14px'} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1084.000000, -344.000000)" fill={`${props.fill ? props.fill : '#0157FF'}`}>
            <g transform="translate(1081.000000, 340.000000)">
              <path d="M8.23528137,17.4852814 L8.26293958,14.9291558 L13.5572814,14.8802814 L13.7021866,1.48655091 L16.2352814,1.48528137 L16.2342814,17.4572814 L16.2352814,17.4852814 L8.23528137,17.4852814 Z" transform="translate(12.235281, 9.485281) rotate(-315.000000) translate(-12.235281, -9.485281) "></path>
            </g>
          </g>
        </g>
      </svg>
  );
};
export default CheckMark;