import React, { useState, useEffect } from 'react';

/**
 * This hook checks for the container to exist, be empty, and ensure that the flag is false. 
 * It solves an issue that causes the widgets to render multiple times per page
 */
export default (el: Element | null, ref: React.RefObject<HTMLDivElement>): void => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (el && !el.hasChildNodes() && !isLoaded) {
        if (window.requestMyFinanceAds) {
          window.requestMyFinanceAds({
            rootElement: ref.current,
          })
          setIsLoaded(true);
        }
      }
    }, 500)
  }, [el])
};
