import React from 'react';
import './LoadingSpinner.css';

interface Props {
  className?: string;
  width: string;
}

const LoadingSpinner: React.FC<Props> = (props: Props) => {

  return (
    <div data-testid="load-more-loader" className={props.className} style={{margin: 'auto'}}>
      <div style={{ width: props.width }} className="loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
        </svg>
        <svg className="circular-track" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
        </svg>
        <svg className="logo" width="21px" height="34px" viewBox="0 0 21 34" version="1.1">
          <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1339.000000, -1589.000000)" fill="#151515" fillRule="nonzero">
              <path d="M1356.73306,1606.31613 C1356.12319,1605.70326 1355.42772,1605.17739 1354.66723,1604.75405 C1354.93061,1604.497 1355.17499,1604.22209 1355.39854,1603.93136 C1356.65223,1602.26557 1357.25428,1600.21757 1357.09605,1598.15688 C1356.96498,1596.00251 1356.03757,1593.96755 1354.48574,1592.42926 C1352.67903,1590.71917 1350.23869,1589.7967 1347.7224,1589.87266 L1339,1589.87266 L1339,1622.64504 L1349.28112,1622.64504 C1351.96227,1622.74249 1354.56672,1621.76116 1356.48448,1619.93089 C1358.40224,1618.10061 1359.46485,1615.58216 1359.42345,1612.96537 C1359.45662,1610.49033 1358.49023,1608.10192 1356.73306,1606.31613 L1356.73306,1606.31613 Z M1347.7224,1593.69454 C1351.26154,1593.69454 1353.00709,1596.06369 1353.16723,1598.41202 C1353.28965,1600.83358 1351.51829,1602.95463 1349.06226,1603.32735 L1342.91814,1603.32735 L1342.91814,1593.69454 L1347.7224,1593.69454 Z M1349.2651,1618.82316 L1342.91814,1618.82316 L1342.91814,1607.14403 L1348.71528,1607.14403 C1353.74374,1607.14403 1355.52132,1610.26818 1355.52132,1612.96537 C1355.52132,1615.78752 1353.59428,1618.82316 1349.28112,1618.82316 L1349.2651,1618.82316 Z" id="Shape"></path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default LoadingSpinner;
