import React, { ReactText, useState } from 'react';
import EmailIcon from './EmailIcon';
import { elementClicked, ElementClickedInterface } from '../../services/tracking';
import { post } from '../../services/http';

interface Props {
  email: ReactText;
}

const EmailVerification: React.FC<Props> = (props: Props) => {
  const [showMessage, setShowMessage] = useState(false);

  const trackElementClicked = (data: ElementClickedInterface) => {
    elementClicked(data);
  };

  const trackElementResend = () => {
    trackElementClicked({
      location: 'Email_Verification_Page',
      position: 'Verification_Page',
      elementType: 'Button',
      text: 'Resend_Email',
      htmlId: 'Resend_Email',
      actionOutcome: 'Send_Verification_Email',
      outboundUrl: '',
    });
  };

  const trackElementRefresh = () => {
    trackElementClicked({
      location: 'user verification page',
      position: 'bottom',
      elementType: 'Button',
      text: 'Refresh',
      htmlId: 'verified-user-refresh',
      actionOutcome: '',
      outboundUrl: '',
    });
  };

  const onResendClickHandler = async () => {
    // @TODO throw in context based env variables
    const url: string = process.env.REACT_APP_ACCOUNTS_API_BASE_URL + '/email/verification/send';
    post(url, {});
    trackElementResend();
    setShowMessage(true);
  };

  const onRefreshClickHandler = async () => {
    trackElementRefresh();
  };

  return (
    <div data-testid="email-verification" className="box +text-center +pd-vertical-md">
      <div>
        <div>
          <EmailIcon />
        </div>
        <div className="grid +justify-content-center">
          <h3 className="+type-heading-three +mg-bottom-md">Please verify your email</h3>
          <p className="+type-body-two +fg-slate +fg-gray-dark size-5of6 +mg-bottom-md">To protect your security, we want to verify it’s really you.
          Please check the email we just sent to {props.email}  and follow the instructions to finish activating your account. </p>
        </div>
      </div>
      <div >
        <h4 className="+type-heading-four">If you verified your email on a separate device, please <a onClick={onRefreshClickHandler} className="text-link --plain" href="/api/accounts/logout?return-to=/app/sign-in"> refresh </a>
        your screen in order to sign in.</h4>
        <p className="+type-body-two +fg-slate +fg-gray-dark +mg-top-md">If you haven’t received the email in 15 minutes, click below and we’ll resend it. </p>
        <button onClick={onResendClickHandler} style={{ background: 'none', border: 'none' }} className="button --flat +type-heading-two +type-body-two">Resend email</button>
        <div
          className={ showMessage ? '+display-block +mg-top-md' : '+display-none +mg-top-md' }
        >
          A verification email has been successfully sent to your inbox.
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
