import React from 'react';

interface Props {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
}

const GlossaryIcon: React.FC<Props> = (props: Props) => {
  const { className, fill, width, height } = props;
  return (
    <div className={className || ''}>
      <svg width={width ? width : "44px"} height={height ? height : "37px"} viewBox="0 0 44 37" version="1.1">
        <g id="The-new-direction" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Auth_Dashboard_MVP_Desktop" transform="translate(-780.000000, -1613.000000)" fill={fill ? fill : "#FFF"}>
            <path d="M818,1613 L818,1617.999 L824,1618 L823.999,1632 L822,1632 L822,1636 L823.999,1636 L824,1650 L780,1650 L780,1618 L786,1617.999 L786,1613 L802,1617 L818,1613 Z M786,1619.999 L782,1620 L782,1648 L822,1648 L822,1620 L818,1619.999 L818,1644 L802,1647 L786,1644 L786,1619.999 Z M816,1615.56155 L803,1618.811 L803,1643 L801,1643 L801,1618.811 L788,1615.56155 L788,1642.34015 L802,1644.96515 L816,1642.34015 L816,1615.56155 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </svg>
    </div >
  );
};

export default GlossaryIcon;

