import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, SwiperOptions, Navigation } from "swiper";

// import custom css last to override default library styling
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "./SwiperCarousel.css";
import { PaginationOptions } from "swiper/types/components/pagination";
import { NavigationOptions } from "swiper/types/components/navigation";

type Props = {
  children: JSX.Element[];
  swiperOptions: SwiperOptions;
  enforceUniformHeight?: boolean;
  pagination: PaginationOptions | boolean;
  navigation: NavigationOptions | boolean;
  onSlideNextTransitionEnd?: (swiper: SwiperCore) => void;
  onSlidePrevTransitionEnd?: (swiper: SwiperCore) => void;
  onSlideChangeTransitionStart?: (swiper: SwiperCore) => void;
};

SwiperCore.use([Pagination, Navigation]);

const SwiperCarousel: React.FC<Props> = (props: Props) => {
  const { children, swiperOptions, pagination, navigation, onSlideNextTransitionEnd = () => null, onSlidePrevTransitionEnd = () => null, onSlideChangeTransitionStart = () => null, enforceUniformHeight } = props;

  const carouselItems = children
    ? children.map((elem, i) => <SwiperSlide key={i} className={enforceUniformHeight ? '+height-auto' : ''}>{elem}</SwiperSlide>)
    : [];

  return (
    <Swiper
      {...swiperOptions}
      pagination={pagination}
      navigation={navigation}
      onSlideNextTransitionEnd={onSlideNextTransitionEnd}
      onSlidePrevTransitionEnd={onSlidePrevTransitionEnd}
      onSlideChangeTransitionStart={onSlideChangeTransitionStart}
    >
      {carouselItems}
    </Swiper>
  );
};

export default SwiperCarousel;
