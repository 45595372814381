import React from 'react';
import SubtaskComponent from '../../Components/Subtask';
import { IntegrationProps } from '../Types';
import AllConnectChameleon from '../../../Widgets/AllConnectChameleon';

const AllConnectBroadbandWidget: React.FC<IntegrationProps> = (props: IntegrationProps) => {
  const { onComplete, title, description } = props;

  return (
    <SubtaskComponent
      title={title}
      description={description}
      isSkippable={false}
      onComplete={onComplete}
    >
      <AllConnectChameleon />
    </SubtaskComponent>
  )
}

export default AllConnectBroadbandWidget;
