import React from "react";
import './BetaBadge.css';

type Props = {
  betaFeatures: boolean;
}

const BetaBadge: React.FC<Props> = (props: Props) => {
  return (
    props.betaFeatures ?
      <div className="beta-badge-container +pos-fixed +display-flex +flex-direction-column-reverse +align-items-flex-end +fg-white">
        <div className="beta-badge +text-center +bg-red +cursor-pointer +pd-horizontal +pd-vertical-sm +type-heading-four">!</div>
        <div className="beta-features +bg-blue +width-full +pd-all +mg-bottom">
          <h1 className="+type-heading-four +fg-white +mg-bottom">Active Features</h1>
          <h2 className="+type-heading-four +fg-white +mg-bottom">Mode: Internal User</h2>          
          <div>New Onboarding Flow</div>
          <div className="+mg-top +type-body-two">
            These features are available to all users logged in with an @redventures.com email address. Reach
            out to the KUE team if you have any questions.
          </div>
        </div>
      </div>
      :
      <></>
  )
}

export default BetaBadge;
