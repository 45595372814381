import { useState, useEffect } from 'react';

const MOBILE_BREAKPOINT = 600;
const TABLET_BREAKPOINT = 1000;

export type WindowDimensions = {
  height: number,
  width: number,
  isMobile: boolean,
  isTablet: boolean
}

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  const isMobile = innerWidth <= MOBILE_BREAKPOINT;
  const isTablet = innerWidth <= TABLET_BREAKPOINT && innerWidth > MOBILE_BREAKPOINT;
  return { width, height, isMobile, isTablet };
}

const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;