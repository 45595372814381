/* eslint-disable */
import React from "react";
import store from "../../../../store";
import { getUserJourney } from "../../helpers";
import { ActionItem, UserDataRequest } from "../../Types";
import { CreditScoreConfig, InfoTileResponse, InputTileResponse, InputValue, InputValueDictionary, VideoPlayerResponse } from "../../ActionItems/Types";

//   Components
import InputTile from '../../ActionItems/InputTile';
import VideoPlayer from '../../ActionItems/VideoPlayer';
import InfoComponent from '../../../Common/InfoComponent';
import { createHTML } from '../../ActionItems/FreeForm/FreeForm';
import { DropdownOption } from '../Dropdown';
import DropdownControlledContainer, { DropdownControlledContainerOption } from '../DropdownControlledContainer';
import { map } from 'fp-ts/lib/ReadonlyArray';
import { pipe } from 'fp-ts/lib/function';
import CreditRateTable from '../../ActionItems/CreditRateTable';
import DecisionButtonGroup, {
  Props as DecisionButtonGroupProps,
} from "../../ActionItems/DecisionButtonGroup";

export type SubtaskConfiguration = {
  onInputChangeHandler?: (fieldName: string, value: string) => void;
  onItemSelected?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isSubmitted: boolean;
  inputDictionary: InputValueDictionary;
  onSkip?: () => void;
  isSkippable?: boolean;
  title: string;
};

/**
 * Checks if all input values are valid
 * @param inputValues
 * @returns
 */
export function isValidInputList(inputValues: InputValue[]): boolean {
  return inputValues.every((item) => !!item.value);
}

/**
 * Returns an array of InputValues for required || filled fields
 * @param inputValues
 * @returns
 */
export function getFilteredEntries(
  inputValues: InputValueDictionary
): InputValue[] {
  return Object.values(inputValues).filter(
    (entry: InputValue) => entry.required || (!entry.required && entry.value)
  );
}

/**
 * Converts an InputValue list to the field data for the Action Status
 * @param inputValues
 * @returns
 */
export function getUserDataFromInputValues(
  inputValues: InputValue[]
): UserDataRequest[] {
  const { userData = {} } = store.getState().journey;
  const currentJourney = getUserJourney(store.getState());
  const date = new Date().toISOString();

  const fields: UserDataRequest[] = Object.values(inputValues).map(
    (entry: InputValue) => {
      const { value, field_name } = entry;
      const fieldData = userData[field_name] || {
        created_at: date,
        updated_at: date,
      };
      const data = {
        ...fieldData,
        category: "GENERAL",
        journey_id: currentJourney?.uuid,
        field_name: field_name,
        value: value,
        created_at: fieldData.created_at,
        updated_at: fieldData.updated_at,
      };
      return data;
    }
  );

  return fields;
}

/**
 * Checks if the required entries inide of the dictionary are valid
 * @param inputDictionary
 * @returns
 */
export function isValidInputDictionary(
  inputDictionary: InputValueDictionary
): boolean {
  const filteredEntries = getFilteredEntries(inputDictionary);
  const isValid = isValidInputList(filteredEntries);
  return isValid;
}

/**
 * Component Action Item Mapper which takes an action item and renders the component by type
 * @param actionItem
 * @param index
 * @returns
 */
export function getDynamicComponent(
  actionItem: Pick<ActionItem, "component" | "copy">,
  subtaskConfiguration?: SubtaskConfiguration,
  key?: string
): JSX.Element {
  const { component } = actionItem;
  const {
    isSubmitted = false,
    inputDictionary = {},
    onInputChangeHandler = (_fieldName: string, _value: string) => null,
    onItemSelected = () => null,
    onSkip,
    isSkippable,
    title = ''
  } = subtaskConfiguration || {};
  const genericProps = { key: key || "" };

  switch (component) {
    // TODO: Add More Components Mappings Here
    case 'input-tile': {
      const { headline, subcopy, inputs, skippable, skippable_cta_text } = actionItem as InputTileResponse;
      const getErrorCopy = (value: string, error: string) => (!value && isSubmitted && true) ? error : '';

      const inputItems = inputs.map((input) => {
        const inputValue = inputDictionary[input.field_name]?.value;
        const errorCopy = getErrorCopy(inputValue, `${input.label} is required`);
        return { ...input, inputValue, errorCopy };
      });

      return <InputTile
        headline={headline}
        subcopy={subcopy}
        inputs={inputItems}
        {...genericProps} 
        skippable={skippable && isSkippable}
        skipText={skippable_cta_text}
        onSkip={onSkip}
        changeHandler={ onInputChangeHandler }
      />;
    }
    case "video-integration": {
      const { video_url, caption } = actionItem as VideoPlayerResponse;
      return (
        <VideoPlayer
          videoCaption={caption}
          videoUrl={video_url}
          subtaskTitle={title}
          {...genericProps}
        />
      );
    }
    case "info-tile": {
      const { heading, body } = actionItem as InfoTileResponse;
      return (
        <InfoComponent
          heading={heading}
          body={body}
          {...genericProps}
          className="+mg-top-lg +mg-bottom-md"
        />
      );
    }
    case "free-form-copy": {
      const markup = createHTML(actionItem.copy?.content);
      return markup;
    }
    case "dropdown-controlled-container": {
      const { label, options, field_name } = actionItem as unknown as {
        label: string;
        options: (DropdownOption & { component: string })[];
        field_name: string;
      };
      const inputValue = inputDictionary[field_name]?.value;
      const containerOptions: readonly DropdownControlledContainerOption[] =
        pipe(
          options,
          map((o) => {
            return { option: o, component: getDynamicComponent(o) };
          })
        );
      return (
        <DropdownControlledContainer
          label={label}
          options={containerOptions}
          defaultValue={inputValue}
          {...genericProps}
        />
      );
    }
    case "credit-score-configuration": {
      const creditScoreConfig = actionItem as unknown as CreditScoreConfig;
      return <CreditRateTable {...creditScoreConfig} />;
    }
    case "decision-button-group": {
      const decisionButtonGroupConfig =
        actionItem as unknown as DecisionButtonGroupProps;
      return <DecisionButtonGroup {...decisionButtonGroupConfig} />;
    }
    // @TODO add default case (WYSIWYG?)
    default:
      return <></>;
  }
}
